import {
	formatChartData,
	formatCorrelationData,
	formatDateDashedYMD as formatDate,
	formatEFData,
	formatEfficientWeights,
	formatOptimisationPlots,
	formatOptimisationWeights,
	formatPortfolioGraphData,
	formatRiskData,
} from "./Formatter"

export async function getAssetsPrice(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/assets_price/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const assetsPrice = await response.json()

		return { price: formatChartData(assetsPrice, userCurrency) }
	} catch (err) {
		console.error("error while fetching assets price", err)
		return { price: [] }
	}
}

export async function getAssetsPerformance(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/assets_perf/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const assetsPerformance = await response.json()

		return { performance: formatChartData(assetsPerformance, userCurrency) }
	} catch (err) {
		console.error("error while fetching assets performance", err)
		return { performance: [] }
	}
}

export async function getAssetsDrawdown(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/assets_dd/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const assetsDrawdown = await response.json()

		return { drawdown: formatChartData(assetsDrawdown, userCurrency) }
	} catch (err) {
		console.error("error while fetching assets drawdown", err)
		return { drawdown: [] }
	}
}

export async function getAssetsVolatility(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/assets_volatility/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const assetsVolatility = await response.json()

		return { volatility: formatChartData(assetsVolatility, userCurrency) }
	} catch (err) {
		console.error("error while fetching assets volatility", err)
		return { volatility: [] }
	}
}

export async function getCorrelationData(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/assets_correlation/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const assetsCorrelation = await response.json()

		return {
			correlation: formatCorrelationData(assetsCorrelation, userCurrency),
		}
	} catch (err) {
		console.error("error while fetching assets correlation", err)
		return { correlation: [] }
	}
}

export async function getRiskData(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/risk/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const assetsRiskData = await response.json()

		return { riskTable: formatRiskData(assetsRiskData, userCurrency) }
	} catch (err) {
		console.error("error while fetching risk table", err)
		return { riskTable: {} }
	}
}

export async function getOptimisation(
	portfolioId,
	startDate,
	endDate,
	userCurrency
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/opti/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					start: formatDate(startDate),
					end: formatDate(endDate),
				}),
			}
		)

		const { optimization_weigths } = await response.json()

		return {
			optimisationWeights: formatOptimisationWeights(
				optimization_weigths,
				userCurrency
			),
		}
	} catch (err) {
		console.error("error while fetching optimisation", err)
		return {
			optimisationWeights: [],
		}
	}
}

export const calculateAllData =
	(portfolioId, startDate, endDate, userCurrency) => async (dispatch) => {
		try {
			dispatch({ type: "FETCH_DATA", portfolioId })

			const dataResponse = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/table_plots/${portfolioId}/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						start: formatDate(startDate),
						end: formatDate(endDate),
					}),
				}
			)

			const data = await dataResponse.json()

			const portfolioPoint = {
				return: data.current_portfolio.return,
				volatility: data.current_portfolio.volatility,
				sharpe_ratio: data.current_portfolio.sharpe,
			}

			const ow = [
				...data.optimization_weights,
				{
					name: "PORTFOLIO",
					opti_weight_var: data.optimization_risque,
					opti_weight_sharpe: data.optimization_IR,
				},
			]

			const newData = {
				correlation: formatCorrelationData(
					data.correlation_table,
					userCurrency
				),
				drawdown: formatChartData(data.drawdown_plot, userCurrency),
				drawdownPercentage: formatChartData(
					data.drawdown_percentage_plot,
					userCurrency
				),
				performance: formatChartData(data.performance_plot, userCurrency),
				performancePercentage: formatChartData(
					data.performance_percentage_plot,
					userCurrency
				),
				price: formatChartData(data.value_plot, userCurrency),
				riskTable: formatRiskData(data.risk_table, userCurrency),
				volatility: formatChartData(data.volatility_plot, userCurrency),
				volatilityPercentage: formatChartData(
					data.volatility_percentage_plot,
					userCurrency
				),
				optimisationPlot: {
					portfolioPoint,
					plot: formatOptimisationPlots(data.optimization_plot),
					efficientFrontier: formatEFData(data.efficient_frontier),
					efficientWeights: data.efficient_weights,
					// efficientWeights: formatEfficientWeights(data.efficient_weights),
				},
				optimisationWeights: formatOptimisationWeights(ow, userCurrency),
			}

			dispatch({ type: "CALCULATE", portfolioId, newData })
		} catch (err) {
			console.error("error loading all data", err)
			dispatch({ type: "ERROR", portfolioId })
		}
	}

export const calculateOptimisationData =
	(portfolioId, startDate, endDate, userCurrency) => async (dispatch) => {
		try {
			dispatch({ type: "FETCH_OPTI", portfolioId })
			const newData = await getOptimisation(
				portfolioId,
				startDate,
				endDate,
				userCurrency
			)

			dispatch({ type: "CALCULATE", portfolioId, newData })
		} catch (err) {
			console.error("error loading optimisation data", err)
			dispatch({ type: "ERROR", portfolioId })
		}
	}

export const getPortfolioGraphData =
	(userId, startDate, endDate, portfolioId) => async (dispatch) => {
		try {
			dispatch({ type: "FETCH_DATA", portfolioId })

			const dataResponse = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/dashboard/table_plots/${userId}/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						start: formatDate(startDate),
						end: formatDate(endDate),
					}),
				}
			)

			const { risk_table, ...graphData } = await dataResponse.json()

			const newData = {
				...formatPortfolioGraphData(graphData),
				riskTable: risk_table,
			}

			dispatch({ type: "CALCULATE", portfolioId: 0, newData })
		} catch (err) {
			console.error("error while loading portfolio graph data", err)
			dispatch({ type: "CALCULATE", portfolioId: 0, newData: {} })
		}
	}
