export function formatNumber(n, dec = 2) {
	if (n === undefined) return "0"
	var [whole, decimal] = n.toString().split(".")
	whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, "'")

	let lengthWithoutNeg = whole[0] === "-" ? whole.length - 1 : whole.length

	var res =
		lengthWithoutNeg > dec || decimal === undefined
			? whole
			: [whole, decimal.slice(0, dec)].join(".")
	return res
}

const colors = [
	"#A333C8",
	"#6435C9",
	"#D81271",
	"#F45227",
	"#FBB03B",
	"#D9E021",
	"#39AA53",
	"#04A3EA",
	"#666666",
	"#888888",
	"#CCCCCC",
]

export function colorForKey(key) {
	let tempIndex = key % colors.length
	return colors[tempIndex]
}

export function formatDateDotDMY(date) {
	var d = new Date(date)
	var month = "" + (d.getMonth() + 1)
	var day = "" + d.getDate()
	var year = d.getFullYear()

	if (month.length < 2) month = "0" + month
	if (day.length < 2) day = "0" + day
	return [day, month, year].join(".")
}

export function formatDateDashedYMD(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear()

	if (month.length < 2) month = "0" + month
	if (day.length < 2) day = "0" + day

	return [year, month, day].join("-")
}

export function formatYMDDashedDate(date) {
	var d = date.split("-")
	var month = d[1]
	var year = d[0]
	var day = d[2]

	if (month.length < 2) month = "0" + month
	if (day.length < 2) day = "0" + day
	return [day, month, year].join(".")
}

export function formatDashedDate(date) {
	var d = date.split("-")
	var month = d[1]
	var day = d[0]
	var year = d[2]

	if (month.length < 2) month = "0" + month
	if (day.length < 2) day = "0" + day
	return [day, month, year].join(".")
}

export function formatSlashedDate(date) {
	let d = date.split("/")
	if (d.length !== 3) {
		d = date.split("-")
	}

	var month = d[1]
	var day = d[0]
	var year = d[2]
	if (month === undefined || year === undefined || day === undefined) {
		return date
	}
	if (month.length < 2) month = "0" + month
	if (day.length < 2) day = "0" + day
	return [day, month, year].join(".")
}

export function isTodayClosest(dates) {
	const closestDate = findClosestDate(dates, new Date())
	const todayDate = new Date()
	const closestDMY = [
		closestDate.getFullYear(),
		closestDate.getMonth(),
		closestDate.getDate(),
	]
	const todayDYM = [
		todayDate.getFullYear(),
		todayDate.getMonth(),
		todayDate.getDate(),
	]
	return (
		closestDMY[0] === todayDYM[0] &&
		closestDMY[1] === todayDYM[1] &&
		closestDMY[2] === todayDYM[2]
	)
}

export function findClosestDate(allDates, date) {
	if (allDates === undefined || allDates.length <= 0) return date
	const dates = allDates.map((d) => new Date(d))
	const res = dates.sort(
		(d1, d2) => Math.abs(date - d1) - Math.abs(date - d2)
	)[0]

	return res
}

export function disableDates(allDates, date) {
	if (allDates === undefined || allDates.length <= 0) return true
	let fdate = formatDateDashedYMD(date)
	return allDates.find((d) => d === fdate) === undefined
}

export function formatChartData(data, userCurrency) {
	const names = Object.keys(data)

	const dates = data.date
	const res = dates.map((date, i) =>
		Object.fromEntries(
			names.map((name) => {
				if (name.slice(3, 6) === userCurrency) {
					return [`Cash ${name.slice(0, 3)}`, data[name][i]]
				}
				return [name === "portfolio" ? "PORTFOLIO" : name, data[name][i]]
			})
		)
	)
	return res
}

export function formatEFData(data) {
	const res = data.ret.map((r, i) => {
		return {
			return_EF: r,
			sharpe_ratio: data.sharpe[i],
			volatility: data.vol[i],
		}
	})
	return res
}

export function formatRiskData(data, userCurrency) {
	return Object.fromEntries(
		Object.keys(data.currency)
			.map((asset) => [
				asset.slice(3, 6) === userCurrency
					? `Cash ${asset.slice(0, 3)}`
					: asset,
				{
					currency: data["currency"][asset],
					weight: asset === "PORTFOLIO" ? 100 : data["weight"][asset],
					total_pnl: data["total_pnl"][asset],
					last_value: data["last_value"][asset],
					price_pnl: data["price_pnl"][asset],
					div_pnl: data["div_pnl"][asset],
					fx_pnl: data["fx_pnl"][asset],
					nb_pnl: data["nb_pnl"][asset],
					delta: data["delta"][asset],
					daily_risk: data["daily_risk"][asset],
					risk_contrib:
						asset === "PORTFOLIO" ? null : data["risk_contrib"][asset],
					ir: data["ir"][asset],
					wdd: data["wdd"][asset],
					total_return: data["total_return"][asset],
					daily_nb: data["daily_nb"][asset],
				},
			])
			.sort((a1, a2) => (a1[0] >= a2[0] ? 1 : -1))
	)
}

export function formatCorrelationData(data, userCurrency = "") {
	return Object.fromEntries(
		Object.keys(data).map((assetName) => [
			assetName.slice(3, 6) === userCurrency
				? `Cash ${assetName.slice(0, 3)}`
				: assetName,
			Object.fromEntries(
				Object.keys(data[assetName]).map((an) => [
					an.slice(3, 6) === userCurrency ? `Cash ${an.slice(0, 3)}` : an,
					data[assetName][an],
				])
			),
		])
	)
}

export function formatOptimisationWeights(data, userCurrency) {
	return data.map(({ name, ...weights }) => {
		return {
			name:
				name.slice(3, 6) === userCurrency
					? `Cash ${name.slice(0, 3)}`
					: name,
			...weights,
		}
	})
}

const getMaxSharpe = (data) => {
	return data.reduce(function (a, b) {
		return Math.max(a, b)
	}, Number.MIN_VALUE)
}

export function formatOptimisationPlots(data) {
	let maxSharpe = getMaxSharpe(data.sharpe_ratio)
	let minSharpe = data.sharpe_ratio.reduce(function (a, b) {
		return Math.min(a, b)
	}, Number.MAX_VALUE)

	let res = data.volatility.map((value, i) => {
		return {
			volatility: value,
			sharpe_ratio: data.sharpe_ratio[i],
			return: data.return[i],
			color: `hsla(${
				((data.sharpe_ratio[i] - minSharpe) / (maxSharpe - minSharpe)) * 360
			}, 100%, 50%, 60%)`,
		}
	})

	return res.sort((a, b) => a.volatility - b.volatility)
}

export function formatEfficientWeights(data) {
	const res = data

	console.log("WOOW", res)

	return res
}

export function formatPortfolioGraphData(data) {
	let dates = data.dates

	let names = Object.keys(data.value_plot)

	const price = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.value_plot[name][i]]
			}),
			["date", date],
		])
	)

	const performance = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.performance_plot[name][i]]
			}),
			["date", date],
		])
	)

	const performancePercentage = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.performance_percentage_plot[name][i]]
			}),
			["date", date],
		])
	)

	const volatility = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.volatility_plot[name][i]]
			}),
			["date", date],
		])
	)

	const volatilityPercentage = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.volatility_percentage_plot[name][i]]
			}),
			["date", date],
		])
	)

	const drawdown = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.drawdown_plot[name][i]]
			}),
			["date", date],
		])
	)

	const drawdownPercentage = dates.map((date, i) =>
		Object.fromEntries([
			...names.map((name) => {
				return [name, data.drawdown_percentage_plot[name][i]]
			}),
			["date", date],
		])
	)

	let res = {
		price: price,
		performance: performance,
		performancePercentage,
		volatility: volatility,
		volatilityPercentage,
		drawdown: drawdown,
		drawdownPercentage,
		correlation: formatCorrelationData(data.correlation),
	}
	return res
}

export function formatPortfolioRiskData(data) {
	return Object.fromEntries(
		Object.keys(data.last_value)
			.map((name) => [
				name,
				{
					last_value: data["last_value"][name],
					total_pnl: data["total_pnl"][name],
					total_return: data["total_return"][name],
					price_pnl: data["price_pnl"][name],
					div_pnl: data["div_pnl"][name],
					fx_pnl: data["fx_pnl"][name],
					nb_pnl: data["nb_pnl"][name],
					delta: data["delta"][name],
					daily_risk: data["daily_risk"][name],
					risk_contrib: data["risk_contrib"][name],
					ir: data["ir"][name],
					wdd: data["wdd"][name],
				},
			])
			.sort((a1, a2) => (a1[0] >= a2[0] ? 1 : -1))
	)
}
