import React, { useContext, useEffect, useState } from "react"
import "./../../style/css/DashboardHeader.css"
import folder_black_36dp from "./../../resources/folder_black_36dp.svg"
import folder_grey_36dp from "./../../resources/folder_grey_36dp.svg"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"
import { UserSettingsContext } from "../Context/UserSettingsContext"
import CreatePortfolioPopUp from "./CreatePortfolioPopUp"
import {
	AssetsDataDispatchContext,
	HeaderDataContext,
	HeaderDataDispatchContext,
	VisibilityDispatchContext,
} from "../Context/PortfolioDataContext"

import {
	createPortfolio,
	deletePortfolio,
	changePortfolioName,
} from "./../utils/PortfolioFetchers"
import { useTutoStore } from "../Tutorial/TutoStore"

const DashboardHeader = function ({ currentPortfolioId, setPortfolioId }) {
	const { userId, userCurrency } = useContext(UserSettingsContext)

	const dispatchAssetsData = useContext(AssetsDataDispatchContext)
	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchVisibility = useContext(VisibilityDispatchContext)

	const headers = useContext(HeaderDataContext)

	const [tutoState, dispatchTuto] = useTutoStore() ?? [false, () => {}]

	const [popUp, setPopUp] = useState(false)

	const handleDeletePortfolio = (portfolioId) => {
		if (tutoState) {
			dispatchAssetsData({
				type: "DELETE_PORTFOLIO",
				portfolio_id: portfolioId,
			})
			dispatchHeaderData({
				type: "DELETE_PORTFOLIO",
				portfolio_id: portfolioId,
			})
			dispatchVisibility({
				type: "DELETE_PORTFOLIO",
				portfolio_id: portfolioId,
			})
			return
		}

		deletePortfolio(
			portfolioId,
			userId,
			dispatchAssetsData,
			dispatchHeaderData,
			dispatchVisibility,
			userCurrency
		)
	}

	const handleChangePortfolioName = (portfolioId, name) => {
		dispatchVisibility({
			type: "CHANGE_NAME",
			portfolio_id: portfolioId,
			name,
		})
		if (tutoState) {
			dispatchHeaderData({
				type: "CHANGE_NAME",
				portfolio_id: portfolioId,
				name,
			})
			return
		}
		dispatchHeaderData(changePortfolioName(portfolioId, name))
	}

	const handleCreatePortfolio = (title, description) => {
		if (tutoState) {
			const portfolio_id = Math.ceil(Math.random() * 10000)
			dispatchAssetsData({
				type: "ADD_PORTFOLIO",
				portfolio_id,
			})
			dispatchHeaderData({
				type: "ADD_PORTFOLIO",
				portfolio_id,
				name: title,
				description,
			})
			dispatchVisibility({
				type: "ADD_PORTFOLIO",
				portfolio_id,
				name: title,
			})
			return
		}

		createPortfolio(
			userId,
			title,
			description,
			setPortfolioId,
			dispatchAssetsData,
			dispatchHeaderData,
			dispatchVisibility,
			userCurrency
		)
	}

	return (
		<>
			<header className="dashboard-header">
				<div className="portfolio-list">
					<div
						onClick={() => setPortfolioId(0)}
						className={
							currentPortfolioId === 0
								? "main-portfolio current"
								: "main-portfolio"
						}>
						{currentPortfolioId === 0 ? (
							<img src={folder_black_36dp} alt="Folder Icon"></img>
						) : (
							<img src={folder_grey_36dp} alt="Folder Icon"></img>
						)}
						<p>portfolio dashboard</p>
					</div>

					<div className="portfolio-list-container">
						{headers.length > 1 &&
							headers
								.filter((h) => h.portfolio_id !== 0)
								.sort((v1, v2) => v1.portfolio_id - v2.portfolio_id)
								.map((header) => (
									<div
										className={
											header.portfolio_id === currentPortfolioId
												? "portfolio-item current"
												: "portfolio-item"
										}
										key={`header-tab-${header.portfolio_id}`}>
										<TabInput
											key={`header-tab-input-${header.portfolio_id}`}
											header={header}
											currentPortfolioId={currentPortfolioId}
											setPortfolioId={setPortfolioId}
											handleDeletePortfolio={handleDeletePortfolio}
											handleChangePortfolioName={
												handleChangePortfolioName
											}
										/>
									</div>
								))}
					</div>
				</div>

				<button id="create-portfolio-butt" onClick={() => setPopUp(true)}>
					Create Portfolio
				</button>
			</header>
			{popUp && (
				<CreatePortfolioPopUp
					createPortfolio={
						(title, description) =>
							handleCreatePortfolio(title, description)
						// createPortfolio(
						// 	userId,
						// 	title,
						// 	description,
						// 	setPortfolioId,
						// 	dispatchAssetsData,
						// 	dispatchHeaderData,
						// 	dispatchVisibility,
						// 	userCurrency
						// )
					}
					closePortfolio={() => setPopUp(false)}
				/>
			)}
		</>
	)
}

const TabInput = ({
	header,
	currentPortfolioId,
	setPortfolioId,
	handleDeletePortfolio,
	handleChangePortfolioName,
}) => {
	const [editMode, setEditMode] = useState(false)
	const [editName, setName] = useState(header.name)

	const submitChangeName = () => {
		if (editName && editName.length > 0) {
			handleChangePortfolioName(header.portfolio_id, editName)
			setEditMode(false)
		}
	}

	return (
		<div className="tab-input-container">
			<div
				onClick={() => setPortfolioId(header.portfolio_id)}
				onDoubleClick={() => setEditMode(true)}>
				{editMode ? (
					<input
						value={editName}
						onChange={(e) => setName(e.target.value)}
					/>
				) : (
					<p>{header.name}</p>
				)}
			</div>
			{editMode && <CheckIcon onClick={submitChangeName} />}
			{editMode ? (
				<CloseIcon
					onClick={() => {
						setName(header.name)
						setEditMode(false)
					}}
				/>
			) : (
				<CloseIcon
					onClick={() => {
						var answer = window.confirm(
							`Are you sure your want to delete this portfolio "${header.name}"?`
						)
						if (answer) {
							if (header.portfolio_id === currentPortfolioId) {
								setPortfolioId(0)
							}
							handleDeletePortfolio(header.portfolio_id)
						}
					}}
				/>
			)}
		</div>
	)
}

export default DashboardHeader
