import React, { useContext, useEffect, useState } from "react"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import "./../../../style/css/AssetEntry.css"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import {
	AssetsDataDispatchContext,
	HeaderDataDispatchContext,
	VisibilityDispatchContext,
} from "../../Context/PortfolioDataContext"
import {
	deleteEditSize,
	putEditSize,
	postEditSize,
} from "../../utils/EditSizeFetcher"
import { deleteAsset, deleteTrade } from "../../utils/PortfolioFetchers"
import {
	formatNumber,
	formatYMDDashedDate as formatDashedDate,
} from "./../../utils/Formatter"
import { UserSettingsContext } from "../../Context/UserSettingsContext"

function AssetListViewEntry({
	portfolioId,
	asset,
	editSize,
	dispatchEditSize,
	visibility,
}) {
	const [expandEntry, setExpand] = useState(false)

	const [sizeEntry, setSize] = useState("")

	const isEdit = editSize.size !== null

	const { userId, userCurrency } = useContext(UserSettingsContext)

	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchAssetsData = useContext(AssetsDataDispatchContext)
	const dispatchVisibility = useContext(VisibilityDispatchContext)

	useEffect(() => {
		if (editSize.size !== null) {
			setSize(editSize.size)
		}
	}, [editSize])

	return (
		<div className="asset-entry-container">
			<div className="asset-entry">
				<div className="asset-entry-icon-name-container">
					<div className="asset-entry-icons">
						{visibility.isVisible ? (
							<VisibilityIcon
								className="asset-entry-visibility-icon"
								onClick={() =>
									dispatchVisibility({
										type: "TOGGLE_ASSET_VIS",
										portfolio_id: portfolioId,
										asset_id: asset.asset_id,
										isVisible: false,
									})
								}
							/>
						) : (
							<VisibilityOffIcon
								className="asset-entry-visibility-icon"
								onClick={() =>
									dispatchVisibility({
										type: "TOGGLE_ASSET_VIS",
										portfolio_id: portfolioId,
										asset_id: asset.asset_id,
										isVisible: true,
									})
								}
							/>
						)}
						<div className="asset-entry-drag-icon"></div>
						<div className="asset-entry-LR">
							<span
								className={visibility.leftAxis ? "LR-selected" : ""}
								onClick={() =>
									dispatchVisibility({
										type: "SET_ASSET_LEFT_AXIS",
										portfolio_id: portfolioId,
										asset_id: asset.asset_id,
										leftAxis: true,
									})
								}>
								L
							</span>
							<span
								className={!visibility.leftAxis ? "LR-selected" : ""}
								onClick={() =>
									dispatchVisibility({
										type: "SET_ASSET_LEFT_AXIS",
										portfolio_id: portfolioId,
										asset_id: asset.asset_id,
										leftAxis: false,
									})
								}>
								R
							</span>
						</div>
						<div
							className="letter-id"
							style={{ backgroundColor: visibility.color }}></div>
						{expandEntry ? (
							<ExpandMoreIcon
								className="asset-entry-expand-icon"
								onClick={() => setExpand(false)}
							/>
						) : (
							<NavigateNextIcon
								className="asset-entry-expand-icon"
								onClick={() => setExpand(true)}
							/>
						)}
					</div>
					<p className="asset-entry-name">
						{asset.name.slice(0, 4) === "Cash"
							? asset.name
							: asset.name.toLowerCase()}
					</p>
				</div>
				<div className="asset-entry-size-container">
					<p
						className={
							isEdit ? "asset-entry-size edited" : "asset-entry-size"
						}>
						{isEdit
							? formatNumber(editSize.size)
							: formatNumber(asset.today_size)}
					</p>

					<input
						name="size"
						type="text"
						placeholder="edit size"
						required={true}
						value={sizeEntry}
						onChange={(e) => setSize(e.target.value)}
					/>
					<div
						onClick={() => {
							if (sizeEntry.toString().length > 0) {
								dispatchEditSize(
									isEdit
										? putEditSize(
												portfolioId,
												asset.asset_id,
												sizeEntry,
												userCurrency,
												dispatchHeaderData,
												dispatchAssetsData
										  )
										: postEditSize(
												portfolioId,
												asset.asset_id,
												sizeEntry,
												userCurrency,
												dispatchHeaderData,
												dispatchAssetsData
										  )
								)
							}
						}}>
						<CheckOutlinedIcon
							className={
								isEdit ? "edit-size-icon edited" : "edit-size-icon"
							}
						/>
					</div>
					<div
						onClick={() => {
							if (sizeEntry.toString().length > 0) {
								dispatchEditSize(
									deleteEditSize(
										portfolioId,
										asset.asset_id,
										userCurrency,
										dispatchHeaderData,
										dispatchAssetsData
									)
								)
								setSize("")
							}
						}}>
						<CloseOutlinedIcon className="edit-size-icon close" />
					</div>
				</div>
				<p className="asset-entry-notional">
					{formatNumber(asset.today_notional)}
				</p>
				<p className="asset-entry-trade-price">
					{formatNumber(asset.average_trade_price)}
				</p>
				<p className="asset-entry-trade-date">
					{formatDashedDate(asset.trade_history[0].trade_date)}
				</p>
				<p className="asset-entry-weight">
					{formatNumber(asset.weight * 100, 1) + "%"}
				</p>
				<div
					className="asset-entry-delete-asset"
					onClick={() => {
						if (isEdit) {
							dispatchEditSize(
								deleteEditSize(portfolioId, asset.asset_id)
							)
						}
						dispatchVisibility({
							type: "DELETE_ASSET",
							portfolio_id: portfolioId,
							asset_id: asset.asset_id,
						})
						dispatchAssetsData(
							deleteAsset(
								portfolioId,
								asset.asset_id,
								dispatchAssetsData,
								dispatchHeaderData,
								userId,
								userCurrency
							)
						)
					}}>
					<CloseOutlinedIcon className="delete-asset-icon" />
				</div>
			</div>
			{expandEntry &&
				TradeHistoryEntries({
					portfolioId,
					asset,
					dispatchAssetsData,
					dispatchHeaderData,
					userId,
					userCurrency,
				})}
		</div>
	)
}

const TradeHistoryEntries = ({
	portfolioId,
	asset,
	dispatchAssetsData,
	dispatchHeaderData,
	userId,
	userCurrency,
}) => {
	return (
		<div className="trade-history">
			<span className="trade-history-description">trade history</span>
			<div className="trade-history-entries-container">
				{asset.trade_history.map((t, i) => (
					<div className="trade-history-entries" key={i}>
						<span className="size-history">{formatNumber(t.size)}</span>
						<span className="notional-history">
							{formatNumber(t.notional)}
						</span>
						<span className="price-history">
							{formatNumber(t.trade_price)}
						</span>
						<span className="trade-date-history">
							{formatDashedDate(t.trade_date)}
						</span>

						<CloseOutlinedIcon
							onClick={() =>
								dispatchAssetsData(
									deleteTrade(
										portfolioId,
										asset.asset_id,
										t.trade_date,
										dispatchAssetsData,
										dispatchHeaderData,
										userId,
										userCurrency
									)
								)
							}
							className="delete-trade-icon"
						/>
					</div>
				))}
			</div>
		</div>
	)
}

export default AssetListViewEntry
