import React, { useState, useContext } from "react"

import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import NavigateBefore from "@mui/icons-material/NavigateBefore"
import Button from "@mui/material/Button"
import Switch from "@mui/material/Switch"

import "./../../style/css/SettingsV2.css"

import {
	UserSettingsContext,
	UserSettingsDispatchContext,
} from "./../Context/UserSettingsContext"
import StaticDataContext from "../Context/StaticDataContext"
import BlackWhitePanel from "../ReusableComponents/BackWhitePanel"
import {
	AssetsDataDispatchContext,
	HeaderDataDispatchContext,
} from "../Context/PortfolioDataContext"
import { fetchSettings } from "../utils/PortfolioFetchers"

const Settings = ({ closeSettings }) => {
	const [generalSettings, setGeneralSettings] = useState(true)

	const leftChild = (
		<div className="settings-left">
			<h3 className="settings-title">settings</h3>
			<h4
				onClick={() => setGeneralSettings(true)}
				className={
					!generalSettings
						? "settings-selection"
						: "settings-selection selected"
				}>
				general
			</h4>
			<h4
				onClick={() => setGeneralSettings(false)}
				className={
					generalSettings
						? "settings-selection"
						: "settings-selection selected"
				}>
				advanced
			</h4>
		</div>
	)

	const rightChild = generalSettings ? (
		<SettingsGeneral />
	) : (
		<SettingsAdvanced closeSettings={closeSettings} />
	)

	return (
		<BlackWhitePanel
			leftChild={leftChild}
			rightChild={rightChild}
			closePanel={closeSettings}
		/>
	)
}

const SettingsGeneral = () => {
	const settings = useContext(UserSettingsContext)
	const { currencies } = useContext(StaticDataContext)
	const dispatch = useContext(UserSettingsDispatchContext)
	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchAssetsData = useContext(AssetsDataDispatchContext)

	const [baseCurrency, setBaseCurrency] = useState(
		currencies.find((c) => c.id === settings.currency)
	)
	const [selectBC, setSelectBC] = useState(false)

	const handleCurrencyChange = ({ currency, id }) => {
		setBaseCurrency(currencies.find((c) => c.currency === currency))
		setSelectBC(false)
		const newSettings = { ...settings, currency: id, userId: undefined }

		dispatch({ type: "CURRENCY", id: id, userCurrency: currency })
		fetchSettings(
			settings.userId,
			currency,
			newSettings,
			dispatchAssetsData,
			dispatchHeaderData
		)
	}

	const generalSetting = (
		<div className="settings-right">
			<p className="settings-general-title">general</p>
			<div className="settings-base-currency-container">
				<h5>base currency</h5>
				<div className="settings-base-currency">
					<p>
						{baseCurrency.currency}{" "}
						{baseCurrency === "CHF" && <span>{"(DEFAULT)"}</span>}
					</p>

					<NavigateNextIcon
						className="settings-icon"
						onClick={() => setSelectBC(true)}
					/>
				</div>
			</div>
			<div className="settings-display-container">
				<h5>display</h5>
				<div className="settings-display">
					<span>assettrack platform</span>
					<div className="settings-display-switch">
						<span>dark</span>
						<Switch defaultChecked color="default" />
						<span>light</span>
					</div>
				</div>
			</div>
		</div>
	)

	const baseCurrencySetting = (
		<div className="settings-right">
			<div className="settings-base-currency-container">
				<div className="settings-base-currency-header-container">
					<NavigateBefore
						onClick={() => setSelectBC(false)}
						className="settings-icon"
					/>
					<h5>base currency</h5>
				</div>

				<div className="settings-base-currency-selection">
					{currencies.map((c) => (
						<p
							key={c.id}
							className={
								baseCurrency !== c
									? "setting-base-currency-option"
									: "setting-base-currency-option selected"
							}
							onClick={() => handleCurrencyChange(c)}>
							{c.currency}
							{"     "}
							{c.currency === "CHF" && <span>(default)</span>}
						</p>
					))}
				</div>
			</div>
		</div>
	)

	return selectBC ? baseCurrencySetting : generalSetting
}

const SettingsAdvanced = ({ closeSettings }) => {
	const settings = useContext(UserSettingsContext)
	const dispatch = useContext(UserSettingsDispatchContext)
	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchAssetsData = useContext(AssetsDataDispatchContext)

	const [flat, setFlat] = useState(settings.flat)
	const [histo, setHisto] = useState(settings.histo)
	const [riskPeriod, setRiskPeriod] = useState(settings.risk_period)
	const [numberOpti, setNumberOpti] = useState(settings.number_opti)
	const [dividendTax, setDividend] = useState(
		Number(settings.dividend_tax * 100)
	)

	const submitChanges = () => {
		if (
			/^(\-)?[0-9]+$/.test(riskPeriod) &&
			/^(\-)?[0-9]+$/.test(numberOpti)
		) {
			if (numberOpti > 5000) {
				var cont = window.confirm(
					"WARNING: setting the number of optimizations to a number higher than 4000 will make the application very slow and may even crash. Do you still wish to continue ?"
				)
				if (!cont) return
			}

			const newSettings = {
				...settings,
				userId: undefined,
				flat: flat,
				histo: histo,
				risk_period: riskPeriod,
				number_opti: numberOpti,
				dividend_tax: dividendTax / 100,
			}

			dispatch({
				type: "ADVANCED",
				flat: flat,
				histo: histo,
				riskPeriod: riskPeriod,
				numberOpti: numberOpti,
				dividend_tax: dividendTax / 100,
			})

			fetchSettings(
				settings.userId,
				settings.userCurrency,
				newSettings,
				dispatchAssetsData,
				dispatchHeaderData
			)

			closeSettings()
		}
	}

	return (
		<div className="settings-right">
			<p className="settings-general-title">advanced</p>
			<div className="settings-advanced-container">
				<div className="settings-rolling-risk-container">
					<h5>Window type for rolling risk calculations</h5>
					<div className="rolling-risk-switch">
						<span>exponential</span>
						<Switch
							checked={flat}
							onChange={(e) => {
								setFlat(e.target.checked)
							}}
							className="rolling-switch"
						/>
						<span>flat</span>
					</div>
				</div>
				<div className="settings-histo-container">
					<h5>Histo</h5>
					<div className="histo-switch">
						<span>false</span>
						<Switch
							checked={histo}
							onChange={(e) => {
								setHisto(e.target.checked)
							}}
							className="histo"
						/>
						<span>true</span>
					</div>
				</div>
				<div className="settings-risk-calculation-container">
					<h5>Time span for risk calculations</h5>
					<input
						type="number"
						value={riskPeriod === undefined ? 30 : riskPeriod}
						onChange={(e) => setRiskPeriod(e.target.value)}></input>
				</div>
				<div className="settings-portfolio-optimisation">
					<h5>Portfolio Optimisation : Number of combinations</h5>
					<input
						type="number"
						value={numberOpti === undefined ? 4000 : numberOpti}
						onChange={(e) => setNumberOpti(e.target.value)}></input>
				</div>
				<div className="settings-dividend-tax">
					<h5>Dividend Withholding Tax</h5>
					<div className="input-container">
						<input
							type="number"
							value={dividendTax}
							onChange={(e) => {
								const newDivTax = e.target.value
								if (newDivTax <= 100 && newDivTax >= 0) {
									setDividend(newDivTax)
								}
							}}
						/>
					</div>
				</div>
				<div className="settings-submit-button-container">
					<Button
						className="settings-submit-button"
						onClick={() => submitChanges()}>
						Apply
					</Button>
				</div>
			</div>
		</div>
	)
}

export default Settings
