import React, { useState } from "react"
import "./../../style/css/DragDropDiv.css"

function DragDropDiv({ controls, children }) {
	const [showChild, setChild] = useState(true)

	return (
		<div className="dragDropDiv">
			<div id="side_buttons">
				<button
					id="minimize_icon"
					className="icon"
					onClick={() => setChild(false)}></button>
				<button
					id="maximize_icon"
					className="icon"
					onClick={() => setChild(true)}></button>
				<button id="drag_icon" className="icon"></button>
			</div>
			<div id="controls">{controls}</div>
			<div id="bottom">{showChild && children}</div>
		</div>
	)
}

export default DragDropDiv
