import React, { useContext, useState, useEffect } from "react"
import DragDropDiv from "./../../ReusableComponents/DragDropDiv"
import PortfolioListViewEntry from "./PortfolioListViewEntry"
import "./../../../style/css/PortfolioViewer.css"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {
	HeaderDataContext,
	VisibilityContext,
	VisibilityDispatchContext,
} from "../../Context/PortfolioDataContext"

const PortfolioListView = ({ portfolioId, setPortfolioId }) => {
	const header = useContext(HeaderDataContext)
	const dispatchVisibility = useContext(VisibilityDispatchContext)
	const allVisibility = useContext(VisibilityContext)

	const visibility = allVisibility.find((v) => v.portfolio_id === portfolioId)

	const [allPortfoliosVisible, setAllPortfoliosVisible] = useState(true)

	if (!visibility) return null

	return visibility ? (
		<DragDropDiv controls={<PortfolioViewerHeader />}>
			<div className="toggle-all-portfolios-container">
				{allPortfoliosVisible ? (
					<VisibilityIcon
						className="portfolio-entry-visibility-icon"
						onClick={() => {
							setAllPortfoliosVisible(false)
							dispatchVisibility({
								type: "TOGGLE_ALL_ASSETS",
								portfolio_id: portfolioId,
								isVisible: false,
							})
						}}
					/>
				) : (
					<VisibilityOffIcon
						className="portfolio-entry-visibility-icon"
						onClick={() => {
							setAllPortfoliosVisible(true)
							dispatchVisibility({
								type: "TOGGLE_ALL_ASSETS",
								portfolio_id: portfolioId,
								isVisible: true,
							})
						}}
					/>
				)}
			</div>
			{visibility &&
				visibility.visibility &&
				header
					.sort((h1, h2) => (h1.name > h2.name ? 1 : -1))
					.map((h) => {
						if (
							h.portfolio_id !== 0 &&
							visibility.visibility.find(
								(v) => v.id === h.portfolio_id
							) !== undefined
						) {
							return (
								<PortfolioListViewEntry
									key={`portfolio-list-view-entry-${h.portfolio_id}`}
									header={h}
									visibility={visibility.visibility.find(
										(v) => v.id === h.portfolio_id
									)}
									portfolioId={portfolioId}
									setPortfolioId={setPortfolioId}
								/>
							)
						}
						return null
					})}
		</DragDropDiv>
	) : null
}

const PortfolioViewerHeader = () => {
	return (
		<div className="portfolio-viewer-header">
			<span className="portfolio-viewer-header-value">value</span>
			<span className="portfolio-viewer-header-daily-pnl">daily pnl</span>
			<span className="portfolio-viewer-header-monthly-pnl">
				monthly pnl
			</span>
			<div className="portfolio-viewer-header-ytd-pnl-container">
				<span className="portfolio-viewer-header-ytd-pnl">ytd pnl</span>
				<span className="portfolio-viewer-header-ytd-pnl-percent">%</span>
			</div>
			<span className="portfolio-viewer-header-daily-risk">daily risk</span>
			<span className="portfolio-viewer-header-risk">risk</span>
			<span className="portfolio-viewer-header-date-created">created</span>
		</div>
	)
}

export default PortfolioListView
