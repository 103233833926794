import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import TextField from "@mui/material/TextField"
import { useContext, useState } from "react"
import BlackWhitePanel from "../ReusableComponents/BackWhitePanel"
import "./../../style/css/Share.css"
import "./../../style/css/ScaleShareDatePicker.css"
import {
	findClosestDate,
	formatDateDashedYMD,
	disableDates,
} from "../utils/Formatter"
import StaticDataContext from "../Context/StaticDataContext"

const Share = ({ closeShare, portfolioId }) => {
	return (
		<BlackWhitePanel
			closePanel={closeShare}
			leftChild={<LeftChild />}
			rightChild={<RightChild portfolioId={portfolioId} />}
		/>
	)
}

const LeftChild = () => {
	return (
		<div className="share-left-container">
			<h1 className="share-title">share portfolio</h1>
			<div className="share-description-container">
				<p>
					Share portfolio with another user at a default reshare of 10'000
					with current date.
				</p>
			</div>
		</div>
	)
}

const RightChild = ({ portfolioId }) => {
	const { dates } = useContext(StaticDataContext)
	const lastAvailableDate = findClosestDate(dates, new Date())

	const [shareUserName, setShareUserName] = useState("")
	const [shareFactor, setShareFactor] = useState(10000)
	const [shareDate, setShareDate] = useState(lastAvailableDate)

	const [errorMessage, setErrorMessage] = useState(false)

	const [returnMessage, setReturnMessage] = useState("")

	const fetchShare = () => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(shareUserName)) {
			setErrorMessage(false)
			fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/share/${portfolioId}/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						username: shareUserName,
						scale_date: formatDateDashedYMD(shareDate),
						target_value: shareFactor,
					}),
				}
			)
				.then((response) => response.json())
				.then((message) => {
					console.log(message)
					setReturnMessage(message[0])
					setShareUserName("")
					setShareFactor(10000)
					setShareDate(lastAvailableDate)
				})
				.catch((err) => {
					console.log(err)
					setReturnMessage(
						`There was a problem sending this portfolio to ${shareUserName}, please try again or contact us at info@assetrack.ch`
					)
					setShareUserName("")
					setShareFactor(10000)
					setShareDate(lastAvailableDate)
				})
		} else {
			setErrorMessage(true)
		}
	}

	return (
		<div className="share-right-container">
			<div className="share-input-container username">
				<h3 className="share-input-title">Username</h3>
				<input
					className="share-input-username"
					name="share-username"
					type="email"
					placeholder="type in a username"
					required={true}
					value={shareUserName}
					onChange={(e) => setShareUserName(e.target.value)}
				/>
				{errorMessage && (
					<p class="share-error-message">
						The username you gave does not have a valid format, it should
						be an email address.
					</p>
				)}
			</div>
			<div className="share-input-container">
				<h3 className="share-input-title">Scale</h3>
				<input
					className="share-input-number"
					name="share-number"
					type="number"
					placeholder=""
					required={true}
					value={shareFactor}
					onChange={(e) => setShareFactor(e.target.value)}
				/>
			</div>
			<div className="share-input-container">
				<h3 className="share-input-title">Date</h3>
				<div className="date-picker-container-scale-share">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							views={["day"]}
							shouldDisableDate={(d) => disableDates(dates, d)}
							value={shareDate}
							minDate={new Date(dates[0])}
							maxDate={new Date()}
							onChange={(d) => setShareDate(d)}
							inputFormat="dd/MM/yyyy"
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className="share-buttons">
				<div className="submit-share-button" onClick={() => fetchShare()}>
					share
				</div>
			</div>
			{returnMessage.length > 0 && (
				<p className="share-response">{returnMessage}</p>
			)}
		</div>
	)
}

export default Share
