import "./../style/css/MainPage.css"
import React, { useState } from "react"
import AssetrackNav from "./NavBar/Nav"
import MainContent from "./MainContent/MainContent"
import TutoMainPage from "./Tutorial/MainPage"

import { useDataReducer } from "./Reducers/ThunkReducer"

import {
	HeaderDataContext,
	HeaderDataDispatchContext,
	AssetsDataContext,
	AssetsDataDispatchContext,
	VisibilityContext,
	VisibilityDispatchContext,
} from "./Context/PortfolioDataContext"
import Loading from "./ReusableComponents/Loading"

const MainPage = ({ logOut }) => {
	const [
		headerData,
		dispatchHeaderData,
		assetsData,
		dispatchAssetsData,
		visibility,
		dispatchVisibility,
	] = useDataReducer()

	// const [isTuto, setTuto] = useState(false)

	// if (isTuto) {
	// 	return <TutoMainPage closeTuto={() => setTuto(false)} />
	// }

	return (
		<>
			<HeaderDataContext.Provider value={headerData}>
				<HeaderDataDispatchContext.Provider value={dispatchHeaderData}>
					<AssetsDataContext.Provider value={assetsData}>
						<AssetsDataDispatchContext.Provider
							value={dispatchAssetsData}>
							<VisibilityContext.Provider value={visibility}>
								<VisibilityDispatchContext.Provider
									value={dispatchVisibility}>
									<AssetrackNav
										logOut={logOut}
										toggleTutorial={() =>
											// setTuto(true)
											alert("Not implemented")
										}></AssetrackNav>
									{headerData.length === 0 ? (
										<div className="loading-container-mainpage">
											<Loading />
											<p className="loading-text">
												Loading Portfolios...
											</p>
										</div>
									) : (
										<>
											<MainContent />
										</>
									)}
								</VisibilityDispatchContext.Provider>
							</VisibilityContext.Provider>
						</AssetsDataDispatchContext.Provider>
					</AssetsDataContext.Provider>
				</HeaderDataDispatchContext.Provider>
			</HeaderDataContext.Provider>
		</>
	)
}

export default MainPage
