import Close from "@mui/icons-material/Close"
import "./../../style/css/BlackWhitePanel.css"

const BlackWhitePanel = ({ leftChild, rightChild, closePanel }) => {
	return (
		<div className="panel-container">
			<div className="panel-left">{leftChild}</div>

			<div className="panel-right">
				<div className="close-icon-container">
					<Close className="panel-icon-close" onClick={closePanel} />
				</div>
				{rightChild}
			</div>
		</div>
	)
}

export default BlackWhitePanel
