import { useState } from "react"
import "./../../style/css/LoginForm.css"

const LoginForm = ({ login }) => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [remember, setRemember] = useState(false)
	const [warning, setWarning] = useState(false)

	const submit = (e) => {
		e.preventDefault()
		fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/login/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: email,
					password: password,
				}),
			}
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.authentication) {
					document.cookie = "userID=" + data.user_id
					login(data.user_id)
				} else {
					setEmail("")
					setPassword("")
					setWarning(true)
					setTimeout(() => {
						setWarning(false)
					}, 5000)
				}
			})
	}

	return (
		<div className="loginForm">
			{warning && <p className="warning">Wrong email and/or password.</p>}
			<form onSubmit={submit}>
				<label>
					Username
					<br />
					<input
						type="email"
						name="username"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="name@domain.com"
						required="required"
					/>
				</label>
				<br />
				<label>
					Password
					<br />
					<input
						type="password"
						name="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required="required"
					/>
				</label>
				<br />
				<a href="">Forgot password</a>
				<label id="input-checkbox">
					<input
						type="checkbox"
						name="remember"
						value={remember}
						onChange={(e) => setRemember(e.target.checked)}
					/>
					Remember me
				</label>
				<br />
				<input type="submit" value="Log in" onSubmit={submit} />
			</form>
		</div>
	)
}

export default LoginForm
