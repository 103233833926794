export async function loadStaticData() {
	try {
		const [currenciesResponse, assetsResponse, datesResponse, envResponse] =
			await Promise.all([
				fetch(
					`${
						process.env.NODE_ENV === "development"
							? "https://assettrack.ch"
							: ""
					}/api/currencies/`
				),
				fetch(
					`${
						process.env.NODE_ENV === "development"
							? "https://assettrack.ch"
							: ""
					}/api/assets/`
				),
				fetch(
					`${
						process.env.NODE_ENV === "development"
							? "https://assettrack.ch"
							: ""
					}/api/dates/`
				),
				fetch(
					`${
						process.env.NODE_ENV === "development"
							? "https://assettrack.ch"
							: ""
					}/api/env/`
				),
			])

		const currencies = await currenciesResponse.json()
		const assetsUnfiltered = await assetsResponse.json()
		const assets = assetsUnfiltered
			.map((a) => {
				if (a.type === "Currency") {
					return {
						...a,
						type: "Cash",
						name: `Cash ${a.name.slice(0, 3)}`,
						userCurrency: a.name.slice(3, 6),
					}
				}
				return {
					...a,
					name: a.name.toUpperCase(),
				}
			})
			.sort((a1, a2) => (a1.name > a2.name ? 1 : -1))
		const dates = await datesResponse.json()
		const envRisk = await envResponse.json()
		return [currencies, assets, dates, envRisk.env_risk]
	} catch (err) {
		console.log("error fetching data", err)
		return [{}, {}, {}, 0]
	}
}

export async function loadAllHeaderData(userId) {
	try {
		const headerDataResponse = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/dashboard/header/${userId}/`
		)
		const headerData = await headerDataResponse.json()
		const globalPortfolioHeader = {
			portfolio_id: 0,
			name: "All portfolios",
			value: headerData.global_value,
			daily_pnl: headerData.global_daily_pnl,
			mtd_pnl: headerData.global_mtd_pnl,
			ytd_pnl: headerData.global_ytd_pnl,
			ytd_return: headerData.global_ytd_return,
			daily_risk: headerData.global_daily_risk,
			historical_risk: headerData.global_historical_risk,
			asset_properties: [],
		}

		if (!headerData.portfolios_headers) {
			return [globalPortfolioHeader]
		}
		return [globalPortfolioHeader, ...headerData.portfolios_headers]
	} catch (err) {
		console.log("error fetching all header data", err)
		const emptyGlobalPortfolioHeader = {
			portfolio_id: 0,
			name: "All portfolios",
			description: "",
			value: 0,
			daily_pnl: 0,
			mtd_pnl: 0,
			ytd_pnl: 0,
			ytd_return: 0,
			daily_risk: 0,
			historical_risk: 0,
		}
		return [emptyGlobalPortfolioHeader]
	}
}

export async function loadAssetsData(portfolioIds, userCurrency) {
	try {
		const assets = await Promise.all(
			portfolioIds.map((id) =>
				fetch(
					`${
						process.env.NODE_ENV === "development"
							? "https://assettrack.ch"
							: ""
					}/api/header/${id}/`
				)
					.then((response) => response.json())
					.then((asset) => {
						return { ...asset, portfolio_id: id }
					})
			)
		)

		const headers = assets.map(
			({ assets_properties, portfolio_id, ...header }) => {
				return { portfolio_id, ...header }
			}
		)

		const assets_properties = assets.map(
			({ assets_properties, portfolio_id, ...header }) => {
				return {
					portfolio_id,
					assets_properties: assets_properties
						? assets_properties.map((a) => {
								if (a.name.slice(3, 6) === userCurrency) {
									return {
										...a,
										name: `Cash ${a.name.slice(0, 3)}`,
									}
								}
								return a
						  })
						: [],
				}
			}
		)

		return { assets: assets_properties, headers: headers }
	} catch (err) {
		console.log(`error fetching assets for portfolios ${portfolioIds}`, err)
		return { assets: [], headers: [] }
	}
}

export async function loadAllData(userId, userCurrency) {
	try {
		const headers = await loadAllHeaderData(userId)

		let portfolioIds = headers
			.filter((ph) => ph.portfolio_id !== 0)
			.map((ph) => ph.portfolio_id)

		const assets = await loadAssetsData(portfolioIds, userCurrency)
		return { assets: assets.assets, headers }
	} catch (err) {
		console.log("error fetching all data", err)
		return { assets: [], headers: [] }
	}
}
