import { loadAssetsData } from "./DataFetcher"

export const postEditSize =
	(
		portfolioId,
		assetId,
		sizeEntry,
		userCurrency,
		dispatchHeaderData,
		dispatchAssetsData
	) =>
	async (dispatch) => {
		try {
			dispatch({ type: "EDIT", asset_id: assetId, size: Number(sizeEntry) })
			const response = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/edit_size/${portfolioId}/${assetId}/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ size: sizeEntry }),
				}
			)
			const { assets, headers } = await loadAssetsData(
				[portfolioId],
				userCurrency
			)

			dispatchAssetsData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolioId,
				portfolio_assets: assets[0],
			})

			dispatchHeaderData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolioId,
				headerData: headers[0],
			})
		} catch (err) {
			console.error("error putting size", err)
		}
	}

export const putEditSize =
	(
		portfolioId,
		assetId,
		sizeEntry,
		userCurrency,
		dispatchHeaderData,
		dispatchAssetsData
	) =>
	async (dispatch) => {
		try {
			dispatch({ type: "EDIT", asset_id: assetId, size: Number(sizeEntry) })
			const response = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/edit_size/${portfolioId}/${assetId}/`,
				{
					method: "PUT",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ size: sizeEntry }),
				}
			)

			const { assets, headers } = await loadAssetsData(
				[portfolioId],
				userCurrency
			)

			dispatchAssetsData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolioId,
				portfolio_assets: assets[0],
			})

			dispatchHeaderData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolioId,
				headerData: headers[0],
			})
		} catch (err) {
			console.error("error putting size", err)
		}
	}

export const deleteEditSize =
	(
		portfolioId,
		assetId,
		userCurrency,
		dispatchHeaderData,
		dispatchAssetsData
	) =>
	async (dispatch) => {
		try {
			dispatch({ type: "DELETE", asset_id: assetId })

			const response = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/edit_size/${portfolioId}/${assetId}/`,
				{
					method: "DELETE",
					headers: { "Content-Type": "application/json" },
				}
			)

			const { assets, headers } = await loadAssetsData(
				[portfolioId],
				userCurrency
			)

			dispatchAssetsData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolioId,
				portfolio_assets: assets[0],
			})

			dispatchHeaderData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolioId,
				headerData: headers[0],
			})
		} catch (err) {
			console.error("error deleting size", err)
		}
	}

export async function getAllEditSize(portfolioId, assetIds) {
	try {
		const sizes = await Promise.all(
			assetIds.map((id) =>
				fetch(
					`${
						process.env.NODE_ENV === "development"
							? "https://assettrack.ch"
							: ""
					}/api/edit_size/${portfolioId}/${id}/`
				)
					.then((response) => response.json())
					.then((editSize) => {
						return { asset_id: id, size: editSize.size || null }
					})
			)
		)
		return sizes
	} catch (err) {
		console.log(
			`error fetching edit sizes for portfolios ${portfolioId}`,
			err
		)
		return []
	}
}
