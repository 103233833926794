import React, { useState, useContext } from "react"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
import CloseIcon from "@mui/icons-material/Close"
import {
	formatNumber,
	formatDateDotDMY as formatDate,
	formatDashedDate,
	isTodayClosest,
} from "./../../utils/Formatter"
import {
	AssetsDataDispatchContext,
	HeaderDataDispatchContext,
	VisibilityDispatchContext,
} from "../../Context/PortfolioDataContext"
import { UserSettingsContext } from "../../Context/UserSettingsContext"
import StaticDataContext from "../../Context/StaticDataContext"
import { deletePortfolio } from "../../utils/PortfolioFetchers"

const PortfolioListViewEntry = ({
	header,
	visibility,
	portfolioId,
	setPortfolioId,
}) => {
	const [expandEntry, setExpand] = useState(false)

	const dispatchAssetsData = useContext(AssetsDataDispatchContext)
	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchVisibility = useContext(VisibilityDispatchContext)
	const { userId, userCurrency } = useContext(UserSettingsContext)
	const { dates } = useContext(StaticDataContext)

	return (
		<div className="portfolio-viewer-entry">
			<div className="portfolio">
				<div className="portfolio-viewer-icons">
					{visibility.isVisible ? (
						<VisibilityIcon
							className="visibility-icon"
							onClick={() =>
								dispatchVisibility({
									type: "TOGGLE_ASSET_VIS",
									portfolio_id: portfolioId,
									asset_id: header.portfolio_id,
									isVisible: false,
								})
							}
						/>
					) : (
						<VisibilityOffIcon
							className="visibility-icon"
							onClick={() =>
								dispatchVisibility({
									type: "TOGGLE_ASSET_VIS",
									portfolio_id: portfolioId,
									asset_id: header.portfolio_id,
									isVisible: true,
								})
							}
						/>
					)}
					<div className="drag-icon"></div>
					<div className="portfolio-viewer-entry-LR">
						<span
							className={visibility.leftAxis ? "LR-selected" : ""}
							onClick={() =>
								dispatchVisibility({
									type: "SET_ASSET_LEFT_AXIS",
									portfolio_id: portfolioId,
									asset_id: header.portfolio_id,
									leftAxis: true,
								})
							}>
							L
						</span>
						<span
							className={!visibility.leftAxis ? "LR-selected" : ""}
							onClick={() =>
								dispatchVisibility({
									type: "SET_ASSET_LEFT_AXIS",
									portfolio_id: portfolioId,
									asset_id: header.portfolio_id,
									leftAxis: false,
								})
							}>
							R
						</span>
					</div>
					<div
						className="letterId"
						style={{ backgroundColor: visibility.color }}></div>
					{expandEntry ? (
						<ExpandMoreIcon
							className="portfolio-expand-icon"
							onClick={() => setExpand(false)}
						/>
					) : (
						<NavigateNextIcon
							className="portfolio-expand-icon"
							onClick={() => setExpand(true)}
						/>
					)}
				</div>
				<p id="name">{header.name.toLowerCase()}</p>
				<p id="value">
					{header.value !== undefined ? formatNumber(header.value) : ""}
				</p>
				<p id="daily-pnl">
					{header.daily_pnl !== undefined
						? isTodayClosest(dates)
							? formatNumber(header.daily_pnl)
							: 0
						: ""}
				</p>
				<p id="monthly-pnl">
					{header.mtd_pnl !== undefined
						? formatNumber(header.mtd_pnl)
						: ""}
				</p>
				<div className="ytd-pnl-container">
					<p id="ytd-pnl">
						{header.ytd_pnl !== undefined
							? formatNumber(header.ytd_pnl)
							: ""}
					</p>
					<p id="ytd-percent">
						{header.ytd_return !== undefined
							? `${formatNumber(header.ytd_return * 100, 1)} %`
							: null}
					</p>
				</div>
				<p id="daily-risk">
					{header.daily_risk !== undefined
						? formatNumber(header.daily_risk, 0)
						: null}
				</p>
				<p
					className="risk"
					style={{
						color:
							header.historical_risk <= 1 / 2
								? "#007331"
								: header.historical_risk <= 3 / 4
								? "#f8803b"
								: "#c30000",
					}}>
					{header.historical_risk === undefined
						? ""
						: header.historical_risk <= 1 / 2
						? "low"
						: header.historical_risk <= 3 / 4
						? "medium"
						: "high"}
				</p>
				<p id="date-created">
					{header.date_created ? formatDate(header.date_created) : ""}
				</p>
				<div className="open-close-portfolio">
					<CloseIcon
						className="portfolio-delete-button"
						onClick={() => {
							var answer = window.confirm(
								`Are you sure your want to delete this portfolio ${header.portfolio_id} with title "${header.name}"?`
							)
							if (answer) {
								deletePortfolio(
									header.portfolio_id,
									userId,
									dispatchAssetsData,
									dispatchHeaderData,
									dispatchVisibility,
									userCurrency
								)
								if (header.portfolio_id === portfolioId)
									setPortfolioId(0)
							}
						}}
					/>
					<p
						className="portfolio-open-button"
						onClick={() => setPortfolioId(header.portfolio_id)}>
						open
					</p>
				</div>
			</div>
			{expandEntry && <PortfolioAssets portfolio={header} />}
		</div>
	)
}

const PortfolioAssets = ({ portfolio }) => {
	return (
		<div className="portfolio-assets-container">
			<div>
				<span>assets</span>
				<span>purchase price</span>
				<span>size</span>
				<span>notional</span>
				<span>ytd pnl</span>
				<span></span>
				<span></span>
				<span>trade date</span>
			</div>
			{portfolio.asset_properties !== undefined &&
				portfolio.asset_properties.map((a) => (
					<div key={a.asset_id}>
						<span className="portfolio-asset-name">{a.name}</span>
						<span className="portfolio-asset-trade-price">
							{formatNumber(a.average_trade_price)}
						</span>
						<span className="portfolio-asset-size">
							{formatNumber(a.today_size)}
						</span>
						<span className="portfolio-asset-notional">
							{formatNumber(a.today_notional)}
						</span>
						<span className="portfolio-asset-ytd-pnl">
							{formatNumber(a.ytd_pnl)}
						</span>
						<span></span>
						<span></span>
						<span className="portfolio-asset-date">
							{formatDashedDate(a.last_trade_date)}
						</span>
					</div>
				))}
		</div>
	)
}

export default PortfolioListViewEntry
