import React, { useState, useContext } from "react"

import "./../../style/css/ProfileV2.css"

import Button from "@mui/material/Button"

import BlackWhitePanel from "../ReusableComponents/BackWhitePanel"
import {
	UserSettingsContext,
	UserSettingsDispatchContext,
} from "./../Context/UserSettingsContext"

const profileOptions = {
	PROFILE: "profile",
	ACCSEC: "account & security",
	NOTIFICATION: "notifications",
}

const ProfileWindow = ({ currentProfile }) => {
	switch (currentProfile) {
		case profileOptions.PROFILE:
			return <ProfileProfile />
		default:
			return (
				<div className="profile-right">
					<h1>NOT IMPLEMENTED</h1>
				</div>
			)
	}
}

const Profile = ({ closeProfile, logOut }) => {
	const [currentProfile, setCurrentProfile] = useState(profileOptions.PROFILE)

	const leftChild = (
		<div className="profile-left">
			<h3 className="profile-title">settings</h3>
			<h4
				onClick={() => setCurrentProfile(profileOptions.PROFILE)}
				className={
					currentProfile !== profileOptions.PROFILE
						? "profile-selection"
						: "profile-selection selected"
				}>
				{profileOptions.PROFILE}
			</h4>
			<h4
				onClick={() => setCurrentProfile(profileOptions.ACCSEC)}
				className={
					currentProfile !== profileOptions.ACCSEC
						? "profile-selection"
						: "profile-selection selected"
				}>
				{profileOptions.ACCSEC}
			</h4>
			<h4
				onClick={() => setCurrentProfile(profileOptions.NOTIFICATION)}
				className={
					currentProfile !== profileOptions.NOTIFICATION
						? "profile-selection"
						: "profile-selection selected"
				}>
				{profileOptions.NOTIFICATION}
			</h4>

			<div className="profile-log-out-button" onClick={logOut}>
				log out
			</div>
		</div>
	)

	return (
		<BlackWhitePanel
			leftChild={leftChild}
			rightChild={<ProfileWindow currentProfile={currentProfile} />}
			closePanel={closeProfile}
		/>
	)
}

const ProfileProfile = () => {
	const settings = useContext(UserSettingsContext)
	const dispatch = useContext(UserSettingsDispatchContext)

	const [firstname, setFirstname] = useState(settings.firstname)
	const [lastname, setLastname] = useState(settings.lastname)

	const saveChanges = () => {
		if (firstname.length > 0 && lastname.length > 0) {
			const newSettings = {
				...settings,
				firstname: firstname,
				lastname: lastname,
			}
			console.log(newSettings)
			fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/settings/${settings.userId}/`,
				{
					method: "PUT",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(newSettings),
				}
			).then(() => dispatch({ type: "PROFILE", firstname, lastname }))
		}
	}

	return (
		<div className="profile-right">
			<p className="profile-general-title">profile</p>
			<div className="profile-edit-container">
				<p className="profile-initials">{`
					${firstname.charAt(0)} 
					${lastname.charAt(0)}`}</p>
				<h5
					className="profile-edit-initials"
					onClick={() => alert("Not implemented")}>
					edit
				</h5>
			</div>
			<div className="profile-name-edit-container">
				<div className="profile-name-edit">
					<h4>first name</h4>
					<input
						type="text"
						value={firstname}
						onChange={(e) => setFirstname(e.target.value)}></input>
				</div>
				<div className="profile-name-edit">
					<h4>last name</h4>
					<input
						type="text"
						value={lastname}
						onChange={(e) => setLastname(e.target.value)}></input>
				</div>
				<div className="profile-name-edit">
					<h4>display name</h4>
					<p className="profile-display-name">{`${firstname}  ${lastname}`}</p>
				</div>
			</div>
			{!(
				firstname === settings.firstname && lastname === settings.lastname
			) && (
				<div className="profile-save-changes">
					<Button
						onClick={saveChanges}
						className="profile-save-changes-button">
						save changes
					</Button>
				</div>
			)}
		</div>
	)
}

export default Profile
