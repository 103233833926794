import "./../style/css/App.css"
import React, { useEffect, useState, useReducer, useContext } from "react"
import MainPage from "./MainPage"
import LoginPage from "./Login/LoginPage"
import { loadStaticData } from "./utils/DataFetcher"
import {
	UserSettingsContext,
	UserSettingsDispatchContext,
} from "./Context/UserSettingsContext"
import StaticDataContext from "./Context/StaticDataContext"
import SettingsReducer from "./Reducers/SettingsReducer"
import Loading from "./ReusableComponents/Loading"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { AssetsDataContext } from "./Context/PortfolioDataContext"

const initialSettings = {
	userId: null,
	firstname: "",
	lastname: "",
	currency: null,
	flat: false,
	risk_period: null,
	number_opti: null,
	histo: false,
}

const theme = createTheme({
	components: {
		// Name of the component
		MuiPopover: {
			styleOverrides: {
				// Name of the slot
				paper: {
					// Some CSS
					backgroundColor: "#5E5E5EEE",
					color: "white",
					borderRadius: 0,
					maxWidth: "30vw",
				},
			},
		},
	},
})

const App = () => {
	const STATES = ["LOGIN", "MAIN"]
	const [state, setState] = useState(STATES[0])
	const [userSettings, dispatchUserSettings] = useReducer(
		SettingsReducer,
		initialSettings
	)

	const [availableData, setAvailableData] = useState()

	useEffect(() => {
		loadStaticData()
			.then(([c, a, d, er]) => {
				setAvailableData({
					currencies: c,
					assets: a,
					dates: d[0].date_list,
					envRisk: er,
				})
			})
			.catch((e) => console.log("error fetching data", e))
	}, [])

	function logIn(userId) {
		fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/settings/${userId}/`
		)
			.then((response) => response.json())
			.then((data) => {
				const userCurrency =
					availableData.currencies.find((c) => c.id === data.currency)
						.currency || "CHF"
				dispatchUserSettings({ ...data, userCurrency, userId: userId })
				setState(STATES[1])
			})
			.catch((err) => {
				console.log("error fetching settings", err)
			})
	}

	function logOut() {
		dispatchUserSettings({ type: "RESET" })
		setState(STATES[0])
	}

	return (
		<div className="App">
			{/* <Loading /> */}
			<ThemeProvider theme={theme}>
				<StaticDataContext.Provider value={availableData}>
					{state === STATES[0] ? (
						<LoginPage login={logIn} />
					) : (
						<UserSettingsContext.Provider value={userSettings}>
							<UserSettingsDispatchContext.Provider
								value={dispatchUserSettings}>
								<MainPage logOut={logOut} />
							</UserSettingsDispatchContext.Provider>
						</UserSettingsContext.Provider>
					)}
				</StaticDataContext.Provider>
			</ThemeProvider>
		</div>
	)
}

export default App
