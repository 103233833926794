export const AssetRiskTableData = {
	info: [
		{
			name: "ccy",
			title: "currency",
			definition:
				"The currency of the asset. For the portfolio this is the base currency chosen.",
		},
		{
			name: "weight",
			title: "weight (%)",
			definition: "The % weight of the asset in the portfolio",
			attribute: "weight",
		},
		{
			name: "last value",
			title: "last value",
			definition:
				"The value of the asset on the last day of the period. Last Value is in the base currency chosen by the user. It does not include dividends payed.",
			attribute: "last_value",
		},
		[
			{
				name: "pnl total",
				title: "pnl total",
				definition:
					"The total Profit or Loss. This is the sum from the asset's price change (PNL PRICE), dividends payed (PNL DIV), foreign currency impact (PNL FX, if the asset currency is different to the base currency) and the new business (PNL TRADE).",
				attribute: "total_pnl",
			},
			{
				name: "%",
				title: "%",
				definition:
					"The performance (Time Weighted Return) of the asset (or portfolio) in %",
				attribute: "total_return",
			},
		],
		{
			name: "pnl price",
			title: "pnl price",
			definition:
				"Profit or Loss made from the asset PRICE changing over the time period selected",
			attribute: "price_pnl",
		},
		{
			name: "pnl div",
			title: "pnl div",
			definition:
				"Profits made from the DIVIDENDS or coupons payed by the asset over the time period selected",
			attribute: "div_pnl",
		},
		{
			name: "pnl fx",
			title: "pnl fx",
			definition:
				"Profit or Loss made from the change in the FOREIGN CURRENCY EXCHANGE RATE (FX). This will be 0 if your base currency is the same as the asset currency.",
			attribute: "fx_pnl",
		},
		{
			name: "pnl trade",
			title: "pnl trade",
			definition:
				"PNL TRADE is the profit or loss made on the transaction between the transaction price and the price at the end of the trading day. If many trades it will be the sum of Profit and Loss on all the trading days.",
			attribute: "daily_nb",
		},
		{
			name: "nb pnl",
			title: "nb pnl",
			definition:
				"Total impact of the new business on the portfolio. It is the difference between the actual performance of the portfolio vs the theoretical portfolio without any transactions since the beginning of the selected time period. ",
			attribute: "nb_pnl",
		},
	],
	optimizeValues: [
		{
			name: "delta 1%",
			title: "delta 1%",
			definition: "The change in value for a +1% move in Price of the Asset",
			attribute: "delta",
		},
		{
			name: "daily risk",
			title: "daily risk",
			definition:
				"The risk is measured by the standard deviation of the daily changes in value. The Daily Risk is calculated over the number of days selected.",
			attribute: "daily_risk",
		},
		{
			name: "risk contrib.",
			title: "risk contribution",
			definition:
				"The Contribution of the risk this asset to the overall portfolio risk. This is calculated by taking the difference (in %) of the risk of the portfolio vs a portfolio without the asset.",
			attribute: "risk_contrib",
		},
		{
			name: "ir",
			title: "ir",
			definition:
				"Information Ratio is the return of the asset divided by the risk of the asset.The Information Ratio is the central measurement for active management. The higher the number the better. A number above 1 is seen as good and above 1.5 as very good.",
			attribute: "ir",
		},
		{
			name: "wdd",
			title: "wdd",
			definition:
				"Worst Drawdown is the biggest loss from the last highest point of the asset",
			attribute: "wdd",
		},
	],
	optimizer: [
		{
			name: "weight (%) min risk",
			title: "weight (%) min risk",
			definition:
				" The percentage weight of each asset that minimises the risk of the portfolio over the selected time horizon. The top number is the result from this optimisation.",
			attribute: "opti_weight_var",
		},
		{
			name: "weight (%) max ir",
			title: "weight (%) max ir",
			definition:
				"The percentage weight of each asset that minimises the risk of the portfolio over the selected time horizon. The top number is the result from this optimisation.",
			attribute: "opti_weight_sharpe",
		},
	],
}

export const PortfolioRiskTableData = {
	info: [
		{
			name: "last value",
			title: "last value",
			definition:
				"The value of the asset on the last day of the period. Last Value is in the base currency chosen by the user. It does not include dividends payed.",
			attribute: "last_value",
		},
		{
			name: "pnl total",
			title: "pnl total",
			definition:
				"The total Profit or Loss. This is the sum from the asset's price change (PNL PRICE), dividends payed (PNL DIV), foreign currency impact (PNL FX, if the asset currency is different to the base currency) and the new business (PNL TRADE).",
			attribute: ["total_pnl", "total_return"],
		},
		{
			name: "pnl price",
			title: "pnl price",
			definition:
				"Profit or Loss made from the change in PRICE of the portfolio's assets over the period selected",
			attribute: "price_pnl",
		},
		{
			name: "pnl div",
			title: "pnl div",
			definition:
				"Profits made from the DIVIDENDS or COUPON payed by the assets in the portfolio over the period selected",
			attribute: "div_pnl",
		},
		{
			name: "pnl fx",
			title: "pnl fx",
			definition:
				"Profit or Loss made from the change in the FOREIGN CURRENCY EXCHANGE RATE. This will be 0 if your base currency is the same as the asset currency.",
			attribute: "fx_pnl",
		},
		{
			name: "pnl trade",
			title: "pnl trade",
			definition:
				"PNL TRADE is the profit or loss made on the transactions between the transaction price and the price at the end of the trading day. If many trades it will be the sum of Profit and Loss over all the trading days.",
			attribute: "daily_nb",
		},
		{
			name: "nb pnl",
			title: "nb pnl",
			definition:
				"Total impact of the new business on the portfolio. It is the difference between the actual performance of the portfolio vs the theoretical portfolio without any transactions since the beginning of the period. ",
			attribute: "nb_pnl",
		},
	],
	optimizeValues: [
		{
			name: "delta 1%",
			title: "delta 1%",
			definition:
				"The change in value for a +1% move in the price of the assets inside the portfolio",
			attribute: "delta",
		},
		{
			name: "daily risk",
			title: "daily risk",
			definition:
				"Daily risk is measured by the standard deviation of the daily value changes of the portfolio. The Daily Risk is calculated over the span of the historical window.",
			attribute: "daily_risk",
		},
		{
			name: "ir",
			title: "ir",
			definition:
				"Information Ratio is the return of the portfolio divided by the risk of the portfolio.The higher the number the better. A number above 1 is seen as good and above 1.5 as very good.",
			attribute: "ir",
		},
		{
			name: "wdd",
			title: "wdd",
			definition:
				"Worst Drawdown is the biggest loss from the last highest point of the asset",
			attribute: "wdd",
		},
	],
}
