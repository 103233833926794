import React, { useCallback } from "react"
import "./../../style/css/PortfolioOverview2.css"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import InfoIcon from "@mui/icons-material/Info"
import GetAppIcon from "@mui/icons-material/GetApp"
import PublishIcon from "@mui/icons-material/Publish"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import DragDropDiv from "./../ReusableComponents/DragDropDiv"
import Dial from "./Dial"
import { formatNumber } from "./../utils/Formatter"
import { useState, useContext } from "react"
import StaticDataContext from "../Context/StaticDataContext"
import { UserSettingsContext } from "../Context/UserSettingsContext"
import {
	HeaderDataContext,
	VisibilityContext,
	VisibilityDispatchContext,
} from "../Context/PortfolioDataContext"
import ScaleIcon from "../../resources/ScaleIcon"
import Scale from "./Scale"
import Share from "./Share"
import ShareWhite from "../../resources/ShareWhite"

function PortfolioOverview({ portfolioId, setPortfolioId }) {
	const { currencies, envRisk } = useContext(StaticDataContext)
	const settings = useContext(UserSettingsContext)
	const allHeaders = useContext(HeaderDataContext)
	const header = allHeaders.find((h) => h.portfolio_id === portfolioId)

	const dispatchVisibility = useContext(VisibilityDispatchContext)
	const allVisibility = useContext(VisibilityContext)

	const visibility = allVisibility.find((v) => v.portfolio_id === portfolioId)

	const getCurrencySymbol = useCallback(() => {
		let curr = currencies.find((c) => c.id === settings.currency)

		if (curr === undefined) return "$"

		switch (curr.currency) {
			case "CHF":
				return "CHF "
			case "EUR":
				return "€ "
			case "CAD":
				return "C$ "
			case "GBP":
				return "£ "
			case "JPY":
				return "¥ "
			default:
				return "$ "
		}
	}, [settings])

	const currencySymbol = getCurrencySymbol()

	return visibility && header ? (
		<DragDropDiv
			controls={
				<ToolBar
					isOnGraph={visibility.dashboardVisibility.isVisible}
					portfolioLeftAxis={visibility.dashboardVisibility.leftAxis}
					toggleOnGraph={(isVisible) =>
						dispatchVisibility({
							type: "TOGGLE_PORTFOLIO_VIS",
							portfolio_id: portfolioId,
							isVisible,
						})
					}
					nameDesc={header}
					setPortfolioLeftAxis={(isLeft) =>
						dispatchVisibility({
							type: "SET_PORTFOLIO_LEFT_AXIS",
							portfolio_id: portfolioId,
							leftAxis: isLeft,
						})
					}
					portfolioId={portfolioId}
					setPortfolioId={setPortfolioId}
				/>
			}>
			<div className="portfolio-overview-container">
				<div className="portfolio-investment">
					<p className="portfolio-number-title">INVESTMENT</p>
					<p className="portfolio-number-description">Portfolio Value</p>
					<p className="portfolio-number-value">
						{`${currencySymbol} ${formatNumber(header.value)}`}
					</p>
				</div>
				<div className="portfolio-return">
					<p className="portfolio-number-title">RETURN</p>
					<p className="portfolio-number-description">
						Year To Date Growth
					</p>
					<p className="portfolio-number-value">
						{`${
							header.ytd_return !== undefined
								? formatNumber(header.ytd_return * 100, 1)
								: 0
						}%`}
					</p>
				</div>
				<div className="portfolio-pnl">
					<div className="pnl-values-container">
						<p className="pnl-title">DAILY PNL</p>
						<p className="pnl-value">
							{`${currencySymbol} ${formatNumber(header.daily_pnl, 0)}`}
						</p>
					</div>
					<div className="pnl-seperator"></div>
					<div className="pnl-values-container">
						<p className="pnl-title">MONTHLY PNL</p>
						<p className="pnl-value">
							{`${currencySymbol} ${formatNumber(header.mtd_pnl, 0)}`}
						</p>
					</div>
					<div className="pnl-seperator"></div>
					<div className="pnl-values-container">
						<p className="pnl-title">YEARLY PNL</p>
						<p className="pnl-value">
							{`${currencySymbol} ${formatNumber(header.ytd_pnl, 0)}`}
						</p>
					</div>
				</div>
				<div className="portfolio-dial-container">
					<Dial
						key={`${portfolioId} ${header.historical_risk}`}
						percentage={header.historical_risk}
						bottomText="portfolio risk"
						topText="medium"
						leftText="min"
						rightText="max"
					/>
					<Dial
						key={`${envRisk}`}
						percentage={envRisk}
						bottomText="environment risk"
						topText="cloudy"
						leftText="clear"
						rightText="storm"
					/>
				</div>
			</div>
		</DragDropDiv>
	) : null
}

export default PortfolioOverview

const ToolBar = ({
	isOnGraph,
	portfolioLeftAxis,
	toggleOnGraph,
	nameDesc,
	setPortfolioLeftAxis,
	portfolioId,
	setPortfolioId,
}) => {
	const [showScale, setScale] = useState(false)
	const [showShare, setShare] = useState(false)

	const [anchorEl, setAnchorEl] = useState(null)

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<div className="toolbar-portfolio-container">
				<div className="toolbar-portfolio">
					<div className="portfolio-title-icons">
						{isOnGraph ? (
							<VisibilityIcon
								className="visibility-icon"
								onClick={() => toggleOnGraph(false)}
								fontSize="large"
							/>
						) : (
							<VisibilityOffIcon
								className="visibility-icon"
								onClick={() => toggleOnGraph(true)}
								fontSize="large"
							/>
						)}
						<div id="LR">
							<span
								className={portfolioLeftAxis ? "LR-selected" : ""}
								onClick={() => setPortfolioLeftAxis(true)}>
								L
							</span>
							<span
								className={!portfolioLeftAxis ? "LR-selected" : ""}
								onClick={() => setPortfolioLeftAxis(false)}>
								R
							</span>
						</div>
						<div className="portfolio-overview-colorblock"></div>
						<p className="title">{nameDesc.name}</p>
					</div>
				</div>
				<div className="toolbar-icons">
					<InfoIcon
						className="toolbar-icon"
						fontSize="large"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
					<PortfolioPopoverDescription
						nameDesc={nameDesc}
						anchorEl={anchorEl}
						handlePopoverClose={handlePopoverClose}
					/>
					<GetAppIcon className="toolbar-icon" fontSize="large" />
					<div className="scale-container" onClick={() => setScale(true)}>
						<ScaleIcon className="scale-icon" />
					</div>
					<PublishIcon className="toolbar-icon" fontSize="large" />
					<div className="share-container" onClick={() => setShare(true)}>
						<ShareWhite className="toolbar-icon" />
						<span>SHARE</span>
					</div>
				</div>
			</div>
			{showScale && portfolioId !== 0 && (
				<Scale
					closeScale={() => setScale(false)}
					portfolioId={portfolioId}
					setPortfolioId={setPortfolioId}
				/>
			)}
			{showShare && portfolioId !== 0 && (
				<Share
					closeShare={() => setShare(false)}
					portfolioId={portfolioId}
				/>
			)}
		</>
	)
}

const PortfolioPopoverDescription = ({
	nameDesc: { name, description },
	anchorEl,
	handlePopoverClose,
}) => {
	return (
		<Popover
			sx={{
				pointerEvents: "none",
			}}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			onClose={handlePopoverClose}
			disableRestoreFocus>
			<Box p={2}>
				<Typography
					sx={{
						textTransform: "uppercase",
						fontWeight: "700",
						fontSize: "1rem",
						color: "white",
					}}>
					{name}
				</Typography>
				<Typography
					sx={{
						textTransform: "none",
						fontWeight: "600",
						fontSize: "0.7rem",
						color: "white",
					}}>
					{description}
				</Typography>
			</Box>
		</Popover>
	)
}
