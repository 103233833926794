const EditSizeReducer = (sizes, action) => {
	switch (action.type) {
		case "RESET":
			return action.sizes
		case "EDIT":
			return sizes.map((s) => {
				if (s.asset_id === action.asset_id) {
					return { ...s, size: action.size }
				}
				return s
			})
		case "DELETE":
			return sizes.map((s) => {
				if (s.asset_id === action.asset_id) {
					return { ...s, size: null }
				}
				return s
			})
		case "ADD":
			if (sizes.filter((s) => s.asset_id === action.asset_id).length === 0) {
				return [...sizes, { asset_id: action.asset_id, size: null }]
			}
			return sizes
		default:
			return sizes
	}
}

export default EditSizeReducer
