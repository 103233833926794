import React, { useState } from "react"
import "./../../style/css/LoginPage.css"
import LoginForm from "./LoginForm"
import SignUpForm from "./SignUpForm"
import assettrack_logo_02_white from "./../../resources/assettrack_logo_02_white.svg"

const LoginPage = ({ login }) => {
	const [isLogin, setIsLogin] = useState(true)

	return (
		<div className="LoginPage">
			<div id="loginLogo">
				<img id="logo" src={assettrack_logo_02_white} alt="logo" />
				<p>Invest with Confidence</p>
			</div>
			<div id="loginForm">
				{isLogin ? (
					<div className="innerContainer">
						<p className="loginTitle">Login</p>
						<p>
							Need an Assettrack account?{" "}
							<a
								className="create-account-link"
								onClick={() => setIsLogin(false)}>
								Create an account
							</a>
						</p>
						<LoginForm login={login} />
						<p>
							Need help logging in? Contact us at{" "}
							<a href="mailto:info@assettrack.ch">
								info@{document.location.hostname}
							</a>
						</p>
					</div>
				) : (
					<div className="innerContainer">
						<p className="loginTitle">Create account</p>
						<SignUpForm signup={login} />
					</div>
				)}
			</div>
		</div>
	)
}

export default LoginPage
