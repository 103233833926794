const ScaleIcon = () => {
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 236.22 197.05"
			fill="white">
			<defs></defs>
			<path
				className="cls-1"
				d="M117.32,59.64V64c-6,.49-9.65,2.22-12.62,5.21s-4.32,6.81-4.32,11.48a15.56,15.56,0,0,0,2.34,8.79,14.68,14.68,0,0,0,5.71,5.27,38.67,38.67,0,0,0,8.89,3.36V117a8.84,8.84,0,0,1-4.11-2.24,9.39,9.39,0,0,1-2.33-3.24c-.54-1.18-1.11-2.88-1.85-5.11a4.32,4.32,0,0,0-1.71-2.4,5.36,5.36,0,0,0-3.08-.85,5.07,5.07,0,0,0-3.8,1.57,4.89,4.89,0,0,0-1.52,3.52,14,14,0,0,0,1.14,5.39,17.82,17.82,0,0,0,3.52,5.29,18.38,18.38,0,0,0,5.68,4.26,19.57,19.57,0,0,0,8.06,2.24v5.13a5,5,0,0,0,.85,2.77,2.62,2.62,0,0,0,2.23.9c1,0,.95-.37,1.26-1.1s-.34-1.9-.34-3.5v-4.29c5-.32,8.08-1.34,10.91-3A17.57,17.57,0,0,0,141.45,107a16.89,16.89,0,0,0-1.25-6.81,15.22,15.22,0,0,0-3.84-5.36,20.74,20.74,0,0,0-6.18-3.29A69.33,69.33,0,0,0,121.32,89V72.06c4,1.11,6.43,3.36,7.32,6.76a5.51,5.51,0,0,0,5.6,4.31,5.35,5.35,0,0,0,3.81-1.49,4.92,4.92,0,0,0,1.6-3.52,9.67,9.67,0,0,0-1-4,17,17,0,0,0-2.8-4.27,17.72,17.72,0,0,0-6.27-4.07A30.87,30.87,0,0,0,121.32,64v-4.4c0-2.22-.29-3.34-2-3.34S117.32,57.36,117.32,59.64Zm0,27.85a10.94,10.94,0,0,1-5.19-3c-1.19-1.1-1.66-2.72-1.66-4.86s.44-3.94,1.72-5a15.62,15.62,0,0,1,5.13-2.69Zm4,12.09c3,.89,5.81,2,7.26,3.27a7.26,7.26,0,0,1,2.61,5.48c0,2.2-1.39,4.12-2.88,5.78s-4,2.75-7,3.3Z"
			/>
			<path
				className="cls-1"
				d="M211.71,30.71,196.14,15.14a3.92,3.92,0,0,0-5.53,0,4.44,4.44,0,0,0,0,5.95l8.89,9.31s-83.52-.35-83.78-.32c-5.39.75-15.67,2-26.56,7.59a65.57,65.57,0,0,0,.06,115.73H42.63l8.9-9.09a4,4,0,0,0,0-5.62,3.91,3.91,0,0,0-5.53,0L30.44,154.19a3.88,3.88,0,0,0,0,5.51L46,175.26a3.89,3.89,0,0,0,2.76,1.14,3.93,3.93,0,0,0,2.77-1.14,4.2,4.2,0,0,0,0-5.75l-8.9-9.11h84.44c9.58-1,17.58-3,23.8-7h-.25a65.72,65.72,0,0,0-.1-116h49l-8.89,8.88a3.9,3.9,0,1,0,5.52,5.52l15.57-15.57a3.91,3.91,0,0,0,0-5.52ZM177.56,95.22a57.64,57.64,0,1,1-57.64-57.64A57.7,57.7,0,0,1,177.56,95.22Z"
			/>
		</svg>
	)
}

export default ScaleIcon

//<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.22 197.05"><defs><style>.cls-1{fill:#474747;}</style></defs><path class="cls-1" d="M117.32,59.64V64c-6,.49-9.65,2.22-12.62,5.21s-4.32,6.81-4.32,11.48a15.56,15.56,0,0,0,2.34,8.79,14.68,14.68,0,0,0,5.71,5.27,38.67,38.67,0,0,0,8.89,3.36V117a8.84,8.84,0,0,1-4.11-2.24,9.39,9.39,0,0,1-2.33-3.24c-.54-1.18-1.11-2.88-1.85-5.11a4.32,4.32,0,0,0-1.71-2.4,5.36,5.36,0,0,0-3.08-.85,5.07,5.07,0,0,0-3.8,1.57,4.89,4.89,0,0,0-1.52,3.52,14,14,0,0,0,1.14,5.39,17.82,17.82,0,0,0,3.52,5.29,18.38,18.38,0,0,0,5.68,4.26,19.57,19.57,0,0,0,8.06,2.24v5.13a5,5,0,0,0,.85,2.77,2.62,2.62,0,0,0,2.23.9c1,0,.95-.37,1.26-1.1s-.34-1.9-.34-3.5v-4.29c5-.32,8.08-1.34,10.91-3A17.57,17.57,0,0,0,141.45,107a16.89,16.89,0,0,0-1.25-6.81,15.22,15.22,0,0,0-3.84-5.36,20.74,20.74,0,0,0-6.18-3.29A69.33,69.33,0,0,0,121.32,89V72.06c4,1.11,6.43,3.36,7.32,6.76a5.51,5.51,0,0,0,5.6,4.31,5.35,5.35,0,0,0,3.81-1.49,4.92,4.92,0,0,0,1.6-3.52,9.67,9.67,0,0,0-1-4,17,17,0,0,0-2.8-4.27,17.72,17.72,0,0,0-6.27-4.07A30.87,30.87,0,0,0,121.32,64v-4.4c0-2.22-.29-3.34-2-3.34S117.32,57.36,117.32,59.64Zm0,27.85a10.94,10.94,0,0,1-5.19-3c-1.19-1.1-1.66-2.72-1.66-4.86s.44-3.94,1.72-5a15.62,15.62,0,0,1,5.13-2.69Zm4,12.09c3,.89,5.81,2,7.26,3.27a7.26,7.26,0,0,1,2.61,5.48c0,2.2-1.39,4.12-2.88,5.78s-4,2.75-7,3.3Z"/><path class="cls-1" d="M211.71,30.71,196.14,15.14a3.92,3.92,0,0,0-5.53,0,4.44,4.44,0,0,0,0,5.95l8.89,9.31s-83.52-.35-83.78-.32c-5.39.75-15.67,2-26.56,7.59a65.57,65.57,0,0,0,.06,115.73H42.63l8.9-9.09a4,4,0,0,0,0-5.62,3.91,3.91,0,0,0-5.53,0L30.44,154.19a3.88,3.88,0,0,0,0,5.51L46,175.26a3.89,3.89,0,0,0,2.76,1.14,3.93,3.93,0,0,0,2.77-1.14,4.2,4.2,0,0,0,0-5.75l-8.9-9.11h84.44c9.58-1,17.58-3,23.8-7h-.25a65.72,65.72,0,0,0-.1-116h49l-8.89,8.88a3.9,3.9,0,1,0,5.52,5.52l15.57-15.57a3.91,3.91,0,0,0,0-5.52ZM177.56,95.22a57.64,57.64,0,1,1-57.64-57.64A57.7,57.7,0,0,1,177.56,95.22Z"/></svg>
