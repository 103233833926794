import { useState } from "react"
import SearchIcon from "@mui/icons-material/Search"

import "./../../style/css/GlossaryV2.css"
import BlackWhitePanel from "../ReusableComponents/BackWhitePanel"

const allDefinitions = {
	"Daily PNL": "The Profit or Loss made on the day.",
	"Daily Risk":
		"Standard deviation, over the pre-defined window, of the daily Profit and Loss (PNL) of the position. It assumes a normal distribution of all the daily PnL over the pre-defined window. The pre-defined window can vary in length (number of days) and type (flat window vs exponential). To change this, go to advanced settings.",
	Delta: "Change in the Value of the positon for a 1% change in the asset price.",
	Drawdown: "The drop in value since the last peak.",
	Histo: "Setting to define if calculations are based on real historical performance (Histo = True) or on the backtested final portfolio (Histo = False).",
	IR: "The daily Information Ratio is the average daily return divided by the daily risk for the selected time period. To annualise the IR, we multiply the “daily IR” by the square root of 252. The IR displayed is the annual Information Ratio. This value is a key measurement to active management as it brings together return and risk in a single number. Ideally we want a high IR for our portfolios i.e lots of return for minimal risk. Any value above 1.5 for a portfolio is seen as very good.",
	"MTD PNL": "The Profit or Loss made since the beginning of the month.",
	"PnL Div":
		"Profit and Loss (PnL) coming from the dividend/coupon of the asset over the selected time period.",
	"PNL FX":
		"Profit and Loss (PnL) coming from the change in the FX over the selected time period. It is calculated by seeing the difference in PnL between keeping the fx constant and the real value generated.",
	"PNL NB":
		"Profit and Loss (PnL) coming from the difference between the purchase price and the closing price on the trading day. If there are multiple transactions, it shows the sum of all the above differences. If positive it is an indication to the investor that she is entering a position better than the closing price. ",
	"Pnl Price":
		"Profit and Loss (PnL) coming purely from the change in price of the asset. It does not account for dividend / coupon or changes in FX.",
	"Pnl Total":
		"Profit and Loss (PnL) taking all elements (price, dividend/coupon, fx and new business) into account. ",
	"Risk Contribution":
		"% change in daily risk of the portfolio if the asset is removed from the portfolio. A positive number means a reduction in risk if the asset is sold. It is interesting to note the difference between “% Risk Contribution” and “Weight %” of an asset. For example an asset could have a large value and therefore a large % weight but very little movement and therefore a low risk contribution.",
	"Trading NB":
		"Profit and Loss (PnL) coming from the change in the folio (eg assets bought / sold) over the defined historical window. It is calculated by looking at the difference between the real portfolio return and the unchanged portfolio return. ",
	WDD: "Worst DrawDown is the largest peak to trough.",
	"Weight Min. Risk":
		"Weight of each asset in the portfolio to have the minimum daily risk over the defined historical period. In practice this is calculated by taking many portfolio combinations and identifying the combination that realised the minimum risk over the selected time period. ",
	"Weight Max. IR":
		"Weight of each asset in the portfolio to have maximum Information Ratio over the defined historical period. In practice this is calculated by taking many portfolio combinations and identifying the combination that realised the maximum IR over the selected time period.",
	"Window Type":
		"The rolling window to calculate the daily risk can be set to “Flat”, where all the point shave the same weight or set to “Exponential”, where the weight decreases exponentially over time.",
	"YTD PNL": "The Profit or Loss made since the beginning of the year.",
}

const Glossary = ({ closeGlossary }) => {
	const [searchInput, setSearchInput] = useState("")
	const [definitions, setDefinitions] = useState(allDefinitions)
	const [firstLetter, setFirstLetter] = useState(
		new Set(
			Object.keys(allDefinitions).map((k) => k.toString().toUpperCase()[0])
		)
	)

	const handleSearch = (e) => {
		let filteredKeys = Object.keys(allDefinitions).filter((d) =>
			d.toUpperCase().includes(e.target.value.toUpperCase())
		)
		setDefinitions(
			Object.fromEntries(filteredKeys.map((k) => [k, allDefinitions[k]]))
		)
		setFirstLetter(
			new Set(filteredKeys.map((k) => k.toString().toUpperCase()[0]))
		)
		setSearchInput(e.target.value)
	}

	const leftChild = (
		<div className="glossary-left">
			<div className="search-input-container">
				<input
					className="search-glossary-input"
					placeholder="SEARCH GLOSSARY"
					type="text"
					value={searchInput}
					onChange={handleSearch}></input>
				<SearchIcon className="search-icon" />
			</div>
			<div className="search-border-bottom"></div>
		</div>
	)

	const rightChild = (
		<div className="glossary-right">
			<div className="definitions">
				<h1 className="glossary-title">Glossary</h1>
				{Array.from(firstLetter)
					.sort((a, b) => a.localeCompare(b))
					.map((l) => (
						<div className="definitions-one-letter-container" key={l}>
							<h2 className="definitions-letter-title">{l}</h2>
							{Object.keys(definitions)
								.filter((k) => k[0].toUpperCase() === l)
								.map((k) => (
									<div className="definition-container" key={k}>
										<h5 className="definition-name">{k}</h5>
										<p className="definition">{allDefinitions[k]}</p>
									</div>
								))}
						</div>
					))}
			</div>
		</div>
	)

	return (
		<BlackWhitePanel
			leftChild={leftChild}
			rightChild={rightChild}
			closePanel={closeGlossary}
		/>
	)
}

export default Glossary
