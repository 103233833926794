import { useReducer, useContext, useEffect } from "react"
import { loadAllData } from "../utils/DataFetcher"
import VisibilityReducer from "./VisibilityReducer"
import HeaderDataReducer from "./HeaderDataReducer"
import AssetsDataReducer from "./AssetsDataReducer"
import { UserSettingsContext } from "../Context/UserSettingsContext"
import EditSizeReducer from "./EditSizeReducer"
import { getAllEditSize } from "../utils/EditSizeFetcher"
import GraphDataReducer from "./GraphDataReducer"

export const useDataReducer = () => {
	const { userId, userCurrency } = useContext(UserSettingsContext)

	const [headerData, dispatchHeaderData] = useReducerWithThunk(
		HeaderDataReducer,
		[]
	)
	const [assetsData, dispatchAssetsData] = useReducerWithThunk(
		AssetsDataReducer,
		[]
	)
	const [visibility, dispatchVisibility] = useReducerWithThunk(
		VisibilityReducer,
		[]
	)

	useEffect(() => {
		loadAllData(userId, userCurrency).then(({ assets, headers }) => {
			dispatchHeaderData({ type: "RESET", allHeaderData: headers })
			dispatchAssetsData({ type: "RESET", allAssetsData: assets })
			dispatchVisibility({ type: "RESET", headers: headers, userCurrency })
		})
	}, [])

	return [
		headerData,
		dispatchHeaderData,
		assetsData,
		dispatchAssetsData,
		visibility,
		dispatchVisibility,
	]
}

export const useEditSizeReducer = (portfolioId, assets) => {
	const [editSize, dispatch] = useReducerWithThunk(EditSizeReducer, [])

	useEffect(() => {
		getAllEditSize(
			portfolioId,
			assets.assets_properties.map((a) => a.asset_id)
		).then((sizes) => {
			dispatch({ type: "RESET", sizes })
		})
	}, [])

	return [editSize, dispatch]
}

export const useGraphDataReducer = (portfolioIds) => {
	const [graphData, dispatch] = useReducerWithThunk(GraphDataReducer, [])

	useEffect(() => {
		dispatch({ type: "RESET", portfolioIds })
	}, [])

	return [graphData, dispatch]
}

const useReducerWithThunk = (reducer, initialState) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	let customDispatch = (action) => {
		if (typeof action === "function") {
			action(customDispatch)
		} else {
			dispatch(action)
		}
	}
	return [state, customDispatch]
}
