import { createContext } from "react"

export const HeaderDataContext = createContext(null)
export const HeaderDataDispatchContext = createContext(null)

export const AssetsDataContext = createContext(null)
export const AssetsDataDispatchContext = createContext(null)

export const VisibilityContext = createContext(null)
export const VisibilityDispatchContext = createContext(null)
