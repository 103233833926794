import React from "react"
import PortfolioListView from "./PortfolioListView/PortfolioListView"
import AssetListView from "./AssetListView/AssetListView"

const ListView = ({ portfolioId, setPortfolioId }) => {
	return portfolioId === 0 ? (
		<PortfolioListView
			portfolioId={portfolioId}
			setPortfolioId={setPortfolioId}
		/>
	) : (
		<AssetListView portfolioId={portfolioId} />
	)
}

export default ListView
