import React from "react"
import { formatNumber } from "../../utils/Formatter"

const defaultData = {
	last_value: 0,
	weight: 0,
	total_pnl: 0,
	total_return: 0,
	price_pnl: 0,
	div_pnl: 0,
	fx_pnl: 0,
	daily_nb: 0,
	nb_pnl: 0,
	delta: 0,
	daily_risk: 0,
	ir: 0,
	wdd: 0,
}

const RiskTableEntry = ({ name, data = defaultData, portfolioColor }) => {
	return (
		<div className="risk-table-entry">
			<h5
				className="risk-table-portfolio-name"
				style={{ color: portfolioColor }}>
				{name}
			</h5>
			<div className="risk-entry-info">
				<span>{formatNumber(data.last_value)}</span>
				<div className="pnl-total-container">
					<span className="pnl-total-value">
						{formatNumber(data.total_pnl)}
					</span>
					<span className="pnl-total-percent">
						{data.total_return
							? `${formatNumber(Number(data.total_return) * 100, 1)}%`
							: ""}
					</span>
				</div>

				<span>{formatNumber(data.price_pnl)}</span>
				<span>{formatNumber(data.div_pnl)}</span>
				<span>{formatNumber(data.fx_pnl)}</span>
				<span>{formatNumber(data.daily_nb)}</span>
				<span>{formatNumber(data.nb_pnl)}</span>
			</div>
			<div className="risk-entry-optimize-values">
				<span>{formatNumber(data.delta)}</span>
				<span>{formatNumber(data.daily_risk)}</span>
				<span>{formatNumber(data.ir)}</span>
				<span>{formatNumber(data.wdd)}</span>
			</div>
		</div>
	)
}

export default RiskTableEntry
