const SettingsReducer = (settings, action) => {
	switch (action.type) {
		case "CURRENCY": {
			return {
				...settings,
				currency: action.id,
				userCurrency: action.userCurrency,
			}
		}
		case "ADVANCED": {
			return Object.assign(settings, {
				flat: action.flat,
				number_opti: action.numberOpti,
				risk_period: action.riskPeriod,
				histo: action.histo,
				dividend_tax: action.dividend_tax,
			})
		}
		case "PROFILE": {
			return {
				...settings,
				firstname: action.firstname,
				lastname: action.lastname,
			}
		}
		case "RESET": {
			return {
				userId: null,
				firstname: "",
				lastname: "",
				currency: null,
				userCurrency: "",
				flat: false,
				risk_period: null,
				number_opti: null,
				histo: false,
				dividend_tax: 0,
			}
		}
		default: {
			return {
				userId: action.userId,
				firstname: action.firstname,
				lastname: action.lastname,
				currency: action.currency,
				userCurrency: action.userCurrency,
				flat: action.flat,
				risk_period: action.risk_period,
				number_opti: action.number_opti,
				histo: action.histo,
				dividend_tax: action.dividend_tax,
			}
		}
	}
}

export default SettingsReducer
