import { useState } from "react"
import DashboardHeader from "./DashboardHeader"
import PortfolioOverview from "./PortfolioOverview"
import ListView from "./ListView"
import ChartView from "./ChartView"

const MainContent = () => {
	const [currentPortfolioId, setCurrentPortfolioId] = useState(0)

	return (
		<main>
			<DashboardHeader
				currentPortfolioId={currentPortfolioId}
				setPortfolioId={setCurrentPortfolioId}
			/>
			<PortfolioOverview
				portfolioId={currentPortfolioId}
				setPortfolioId={setCurrentPortfolioId}
			/>
			<ListView
				portfolioId={currentPortfolioId}
				setPortfolioId={setCurrentPortfolioId}
			/>
			<ChartView portfolioId={currentPortfolioId} />
		</main>
	)
}

export default MainContent
