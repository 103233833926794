import "./../../style/css/SignUpForm.css"
import { useContext, useState } from "react"
import StaticDataContext from "./../Context/StaticDataContext"

const SignUpForm = ({ signup }) => {
	const [firstname, setFirstname] = useState("")
	const [lastname, setLastname] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [confirmedPassword, setConfirmedPassword] = useState("")
	const [currency, setCurrency] = useState("CHF")
	const [warningShow, setWarningShow] = useState(false)
	const [warningValue, setWarningValue] = useState("")

	const { currencies } = useContext(StaticDataContext)

	const submit = (e) => {
		e.preventDefault()

		if (password !== confirmedPassword) {
			setPassword("")
			setConfirmedPassword("")
			setWarningShow(true)
			setWarningValue("Password doesn't match")
			setTimeout(() => {
				setWarningShow(false)
			}, 5000)
		} else {
			fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/register/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						email: email,
						firstname: firstname,
						lastname: lastname,
						currency: currency,
						password: password,
						password2: confirmedPassword,
					}),
				}
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.password) {
						setPassword("")
						setConfirmedPassword("")
						setWarningShow(true)
						setWarningValue(data.password)
						setTimeout(() => {
							setWarningShow(false)
						}, 5000)
					} else {
						signup(data.id)
					}
				})
		}
	}

	return (
		<div className="signupForm">
			{warningShow && <p className="warning">{warningValue}</p>}
			<form onSubmit={submit}>
				<label>
					First Name
					<br />
					<input
						type="text"
						name="firstname"
						value={firstname}
						onChange={(e) => setFirstname(e.target.value)}
						required="required"
					/>
				</label>
				<label>
					Last Name
					<br />
					<input
						type="text"
						name="lastname"
						value={lastname}
						onChange={(e) => setLastname(e.target.value)}
						required="required"
					/>
				</label>
				<label>
					Email
					<br />
					<input
						type="email"
						name="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required="required"
					/>
				</label>
				<label>
					Currency
					<select
						name="currency"
						value={currency}
						onChange={(e) => setCurrency(e.target.value)}>
						{currencies.map((c) => (
							<option key={c.id} value={c.currency}>
								{c.currency}
							</option>
						))}
					</select>
				</label>
				<label>
					Password
					<br />
					<input
						type="password"
						name="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required="required"
					/>
				</label>
				<label>
					Confirm Password
					<br />
					<input
						type="password"
						name="confirmedPassword"
						value={confirmedPassword}
						onChange={(e) => setConfirmedPassword(e.target.value)}
						required="required"
					/>
				</label>
				<input type="submit" value="Create Account" onSubmit={submit} />
			</form>
		</div>
	)
}

export default SignUpForm
