import AssetRiskTableEntry from "./../MainContent/AssetChartView/AssetRiskTableEntry"
import PortfolioRiskTableEntry from "./../MainContent/PortfolioChartView/PortfolioRiskTableEntry"
import "./../../style/css/RiskTable.css"
import "./../../style/css/PortfolioRiskTable.css"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import { AssetRiskTableData, PortfolioRiskTableData } from "./../Data/Risktable"

import { useContext, useEffect, useState } from "react"
import { VisibilityDispatchContext } from "./../Context/PortfolioDataContext"

const RiskTable = ({ riskData, visibility, portfolioId }) => {
	const dispatchVisibility = useContext(VisibilityDispatchContext)

	const isAssetRiskTable = portfolioId !== 0

	const riskTitleData = isAssetRiskTable
		? AssetRiskTableData
		: PortfolioRiskTableData

	const [sortingState, setSorting] = useState({
		attribute: "weight",
		order: 0,
	})

	useEffect(() => {
		return () =>
			dispatchVisibility({
				type: "SORT_INIT",
			})
	}, [])

	if (
		riskData === undefined ||
		Object.keys(riskData).length === 0 ||
		Object.keys(visibility).length === 0
	) {
		return null
	}

	const vis = isAssetRiskTable
		? visibility
		: [
				{
					...visibility.dashboardVisibility,
				},
				...visibility.visibility,
		  ]

	let keys
	let portfolios
	let formattedData = riskData

	if (!isAssetRiskTable) {
		keys = Object.keys(riskData)
		portfolios = Object.keys(riskData[keys[0]])
		formattedData = Object.fromEntries(
			portfolios.map((p) => [
				p,
				Object.fromEntries(keys.map((k) => [k, riskData[k][p]])),
			])
		)
	}

	const handleSort = (attribute) => {
		const newOrder =
			sortingState.order === 0 ? 1 : sortingState.order === 1 ? -1 : 0
		setSorting({ attribute, order: newOrder })

		if (isAssetRiskTable) {
			dispatchVisibility({
				type: "SORT_ASSET_TABLE",
				attribute,
				order: newOrder,
				riskTable: riskData.riskTable,
				optimisationWeights: riskData.optimisationWeights,
				portfolioId,
			})
		} else {
			dispatchVisibility({
				type: "SORT_PORTFOLIO_TABLE",
				attribute,
				order: newOrder,
				formattedData,
			})
		}
	}

	if (
		(isAssetRiskTable &&
			(riskData.riskTable === undefined ||
				Object.keys(riskData.riskTable).length === 0)) ||
		(!isAssetRiskTable && (!vis || !formattedData)) ||
		!vis
	) {
		return null
	}

	return (
		<div
			className={
				isAssetRiskTable ? "risk-table" : "portfolio-risk-data-container"
			}>
			<div className="risk-table-entry title">
				<div className="risk-entry-name title"></div>
				<div className="risk-entry-info title">
					{riskTitleData.info.map((data) => {
						if (data.length) {
							return (
								<div className="pnl-total-container">
									<EntryTitle
										{...data[0]}
										sortingState={sortingState}
										handleSort={handleSort}
									/>
									<EntryTitle
										{...data[1]}
										sortingState={sortingState}
										handleSort={handleSort}
									/>
								</div>
							)
						}
						return (
							<EntryTitle
								{...data}
								key={data.name}
								sortingState={sortingState}
								handleSort={handleSort}
							/>
						)
					})}
				</div>
				<div className="risk-entry-optimize-values title">
					{riskTitleData.optimizeValues.map((data) => (
						<EntryTitle
							{...data}
							sortingState={sortingState}
							handleSort={handleSort}
						/>
					))}
				</div>
				{isAssetRiskTable && (
					<div className="risk-entry-optimizer title">
						{riskTitleData.optimizer.map((data) => (
							<EntryTitle
								{...data}
								sortingState={sortingState}
								handleSort={handleSort}
							/>
						))}
					</div>
				)}
			</div>
			{isAssetRiskTable ? (
				<>
					{riskData.riskTable["PORTFOLIO"] !== undefined && (
						<AssetRiskTableEntry
							name={"PORTFOLIO"}
							data={riskData.riskTable["PORTFOLIO"]}
							optimizeWeights={riskData.optimisationWeights}
							visibility={vis.dashboardVisibility}
							loadingOpti={riskData.loadingOpti}
							optimise={() => {}}></AssetRiskTableEntry>
					)}
					{vis !== undefined &&
						vis.visibility.map((v) => (
							<AssetRiskTableEntry
								key={v.name}
								name={v.name}
								data={riskData.riskTable[v.name]}
								optimizeWeights={riskData.optimisationWeights}
								visibility={v}
								loadingOpti={
									riskData.loadingOpti
								}></AssetRiskTableEntry>
						))}
				</>
			) : (
				<>
					{vis
						.filter((v) => v.isVisible)
						.map((v) => (
							<PortfolioRiskTableEntry
								key={v.name}
								name={
									v.name === "GLOBAL_PORTFOLIO"
										? "GLOBAL PORTFOLIO"
										: v.name
								}
								data={formattedData[v.name]}
								portfolioColor={v.color}></PortfolioRiskTableEntry>
						))}
				</>
			)}
		</div>
	)
}

const SortingButton = ({ attribute, sortingState, handleSort }) => {
	if (!attribute) return null
	return (
		<div
			className="sorting-button-container"
			onClick={() => handleSort(attribute)}>
			<ExpandLessIcon
				className={`sorting-button ${
					sortingState.attribute === attribute &&
					sortingState.order === 1 &&
					"selected"
				} ${attribute === "total_pnl" ? "pnl-total" : ""}`}
			/>
			<ExpandMoreIcon
				className={`sorting-button ${
					sortingState.attribute === attribute &&
					sortingState.order === -1 &&
					"selected"
				} ${attribute === "total_pnl" ? "pnl-total" : ""}`}
			/>
		</div>
	)
}

const EntryTitle = ({
	name,
	title,
	definition,
	attribute,
	sortingState,
	handleSort,
}) => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	return (
		<div className="risk-entry-title-container">
			<h5 onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
				{name}
			</h5>
			{attribute && (
				<SortingButton
					attribute={attribute}
					sortingState={sortingState}
					handleSort={handleSort}
				/>
			)}
			<Popover
				sx={{
					pointerEvents: "none",
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus>
				<Box p={2}>
					<Typography
						sx={{
							textTransform: "uppercase",
							fontWeight: "700",
							fontSize: "1rem",
							color: "white",
						}}>
						{title}
					</Typography>
					<Typography
						sx={{
							textTransform: "capitalize",
							fontWeight: "600",
							fontSize: "0.7rem",
							color: "white",
							textTransform: "none",
						}}>
						{definition}
					</Typography>
				</Box>
			</Popover>
		</div>
	)
}

export default RiskTable
