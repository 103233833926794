import { createContext, useContext, useReducer } from "react"

const Store = createContext()
Store.displayName = "Tutorial Store"

export const useTutoStore = () => useContext(Store)

export const TutoStoreProvider = ({ children }) => {
	const [globalState, dispatch] = useReducer((state, action) => {
		return !state
	}, true)

	return (
		<Store.Provider value={[globalState, dispatch]}>
			{children}
		</Store.Provider>
	)
}
