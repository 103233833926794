const AssetsDataReducer = (assetsData, action) => {
	switch (action.type) {
		case "RESET":
			// console.log(
			// 	"ASSETS: ",
			// 	action.allAssetsData,
			// 	"DATA: ",
			// 	sortAssets(action.allAssetsData)
			// )
			return sortAssets(action.allAssetsData)
		case "ADD_PORTFOLIO":
			return sortAssets([
				...assetsData,
				{
					portfolio_id: action.portfolio_id,
					assets_properties: [],
				},
			])
		case "DELETE_PORTFOLIO":
			return sortAssets(
				assetsData.filter((a) => a.portfolio_id !== action.portfolio_id)
			)
		case "ADD_ASSET":
			return addAsset(assetsData, action)
		case "DELETE_ASSET":
			return deleteAsset(assetsData, action)
		case "DELETE_TRADE":
			return deleteTrade(assetsData, action)
		case "UPDATE_PORTFOLIO":
			return updatePortfolio(assetsData, action)

		default:
			return assetsData
	}
}

const sortAssets = (assets) => {
	return assets
		.map(({ portfolio_id, assets_properties }) => {
			return {
				portfolio_id,
				assets_properties: assets_properties
					? assets_properties
							.sort((ap1, ap2) => (ap1.name > ap2.name ? 1 : -1))
							.map((asset) => {
								return {
									...asset,
									trade_history: asset.trade_history
										? asset.trade_history.sort((th1, th2) =>
												th1.trade_date > th2.trade_date ? -1 : 1
										  )
										: [],
								}
							})
					: [],
			}
		})
		.sort((a1, a2) => a1.portfolio_id - a2.portfolio_id)
}

const addAsset = (
	assets,
	{ portfolio_id, name, asset_id, size, trade_price, trade_date }
) => {
	return sortAssets(
		assets.map((asset) => {
			if (asset.portfolio_id === portfolio_id) {
				if (!asset.assets_properties.find((a) => a.asset_id === asset_id)) {
					return {
						...asset,
						assets_properties: [
							...asset.assets_properties,
							{
								asset_id,
								name,
								today_size: size,
								today_notional: size * trade_price,
								average_trade_price: trade_price,
								last_trade_date: trade_date,
								weight: null,
								trade_history: [
									{
										trade_price,
										trade_date,
										size,
										notional: size * trade_price,
									},
								],
							},
						],
					}
				}
			}
			return asset
		})
	)
}

const deleteAsset = (assets, { portfolio_id, asset_id }) => {
	return sortAssets(
		assets.map((a) => {
			if (a.portfolio_id === portfolio_id) {
				return {
					...a,
					assets_properties: a.assets_properties.filter(
						(ap) => ap.asset_id !== asset_id
					),
				}
			}
			return a
		})
	)
}

const deleteTrade = (assets, { portfolio_id, asset_id, trade_date }) => {
	return sortAssets(
		assets.map((a) => {
			if (a.portfolio_id === portfolio_id) {
				return {
					...a,
					assets_properties: a.assets_properties
						.map((ap) => {
							if (ap.asset_id === asset_id) {
								let tradeHistory = ap.trade_history.filter(
									(td) => td.trade_date !== trade_date
								)
								return {
									...ap,
									trade_history: tradeHistory,
								}
							}
							return ap
						})
						.filter((ap) => ap.trade_history.length > 0),
				}
			}
			return a
		})
	)
}

const updatePortfolio = (assets, { portfolio_id, portfolio_assets }) => {
	return sortAssets(
		assets.map((a) => {
			if (a.portfolio_id === portfolio_id) {
				return portfolio_assets
			}
			return a
		})
	)
}

export default AssetsDataReducer
