import React, { useContext, useState } from "react"
import "./../../../style/css/AddAsset.css"
import "./../../../style/css/ControlBarDatePicker.css"

import list_white_36dp from "./../../../resources/list_white_36dp.svg"
import {
	disableDates,
	findClosestDate,
	formatDateDashedYMD as formatDate,
} from "../../utils/Formatter"
import AssetSelector from "./AssetSelector"
import SearchIcon from "@mui/icons-material/Search"

import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import TextField from "@mui/material/TextField"

import {
	AssetsDataDispatchContext,
	HeaderDataDispatchContext,
	VisibilityDispatchContext,
} from "../../Context/PortfolioDataContext"

import { addAsset } from "./../../utils/PortfolioFetchers"
import { UserSettingsContext } from "../../Context/UserSettingsContext"
import StaticDataContext from "../../Context/StaticDataContext"
import { useTutoStore } from "../../Tutorial/TutoStore"

function AddAsset({ portfolioId, dispatchEditSize }) {
	const { userId, userCurrency } = useContext(UserSettingsContext)
	const { dates } = useContext(StaticDataContext)

	const [name, setName] = useState()
	const [id, setId] = useState()
	const [size, setSize] = useState("1")
	const [tradePrice, setPrice] = useState("")
	const [tradeDate, setDate] = useState(findClosestDate(dates, new Date()))

	const [assetSelectorPopup, setAssetSelectorPopup] = useState(false)

	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchAssetsData = useContext(AssetsDataDispatchContext)
	const dispatchVisibility = useContext(VisibilityDispatchContext)

	const [tutoState, dispatchTuto] = useTutoStore() ?? [false, () => {}]

	const fetchDefaultPrice = (newDate, newId) => {
		let d = new Date(newDate)
		if (!isNaN(newId) && !isNaN(d)) {
			let closestDate = findClosestDate(dates, d)
			fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/default_price/${newId}/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						trade_date: formatDate(closestDate),
					}),
				}
			)
				.then((response) => response.json())
				.then(({ default_price }) => {
					if (default_price) setPrice(default_price.toString())
				})
				.catch(console.error)
		}
	}

	function checkInputAndSubmit() {
		if (
			size.length > 0 &&
			/^[0-9]+(\.)?[0-9]*$/.test(size) &&
			/^[0-9]+(\.)?[0-9]*$/.test(tradePrice) &&
			tradePrice.length &&
			name !== undefined
		) {
			if (tutoState) {
				dispatchAssetsData({
					type: "ADD_ASSET",
					portfolio_id: portfolioId,
					name,
					asset_id: id,
					size,
					trade_price: tradePrice,
					trade_date: formatDate(tradeDate),
				})
			} else {
				dispatchAssetsData(
					addAsset(
						portfolioId,
						name,
						id,
						size,
						tradePrice,
						formatDate(tradeDate),
						dispatchHeaderData,
						userId,
						userCurrency
					)
				)
			}
			dispatchVisibility({
				type: "ADD_ASSET",
				portfolio_id: portfolioId,
				asset_id: id,
				asset_name: name,
			})
			dispatchEditSize({ type: "ADD", asset_id: id })
			setName()
			setId()
			setSize("1")
			setPrice("")
			setDate(findClosestDate(dates, new Date()))
		}
	}

	return (
		<div className="add-asset-container" id="add-asset">
			{assetSelectorPopup && (
				<AssetSelector
					setName={setName}
					setId={(newId) => {
						setId(newId)
						fetchDefaultPrice(tradeDate, newId)
					}}
					setAssetSelectorPopup={setAssetSelectorPopup}
					currentAsset={name}></AssetSelector>
			)}
			<div
				onClick={() => setAssetSelectorPopup(true)}
				className="add-asset-select-assets">
				<img src={list_white_36dp} alt="" />
				<div className="add-asset-search-container">
					<p>{name === undefined ? "ASSETS" : name}</p>
					<SearchIcon className="add-asset-search-icon" />
				</div>
			</div>
			<div className="add-asset-size-container">
				<span>{size ? "size" : ""}</span>
				<input
					name="size"
					className="add-asset-size"
					type="text"
					placeholder="SIZE"
					required={true}
					value={size}
					onChange={(e) => setSize(e.target.value)}
				/>
			</div>
			<span className="add-asset-notional">notional</span>
			<div className="add-asset-trade-price-container">
				<span>{tradePrice ? "trade price" : ""}</span>
				<input
					className="add-asset-trade-price"
					name="trade_price"
					type="text"
					placeholder="TRADE PRICE"
					required={true}
					value={tradePrice}
					onChange={(e) => setPrice(e.target.value)}
				/>
			</div>
			<div className="date-picker-container-control-bar add-asset">
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						views={["day"]}
						shouldDisableDate={(d) => disableDates(dates, d)}
						value={tradeDate}
						onChange={(e) => {
							setDate(e)
							fetchDefaultPrice(e, id)
						}}
						label="TRADE DATE"
						minDate={new Date(dates[0])}
						maxDate={new Date()}
						inputFormat="dd/MM/yyyy"
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
			</div>
			<span className="add-asset-weight">weight</span>
			<div
				className="add-asset-button"
				onClick={() => checkInputAndSubmit()}>
				Add Asset
			</div>
		</div>
	)
}

export default AddAsset
