import { React, useState } from "react"
import "./../../style/css/CreatePortfolioPopUp.css"
import CloseIcon from "@mui/icons-material/Close"

function CreatePortfolioPopUp({ createPortfolio, closePortfolio }) {
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")

	const checkInputsAndCreate = (e) => {
		e.preventDefault()
		if (title.length && description.length) {
			createPortfolio(title, description)
			setTitle("")
			setDescription("")
			closePortfolio()
		}
		return
	}

	return (
		<div id="create_portfolio_popup">
			<CloseIcon className="close_popup" onClick={() => closePortfolio()} />
			<form onSubmit={checkInputsAndCreate}>
				<p>CREATE PORTFOLIO</p>
				<label>
					Portfolio Name <br />
					<input
						name="portfolioName"
						type="text"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						required="required"
					/>
				</label>
				<label>
					Portfolio Description <br />
					<textarea
						name="portfolioDescription"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						required="required"></textarea>
				</label>
				<button
					id="create_portofolio_button"
					type="submit"
					value="create_portofolio">
					CREATE
				</button>
			</form>
		</div>
	)
}

export default CreatePortfolioPopUp
