import { useContext, useEffect, useState } from "react"
import {
	ScatterChart,
	XAxis,
	YAxis,
	ZAxis,
	Scatter,
	Tooltip,
	Cell,
	Line,
	ResponsiveContainer,
	ComposedChart,
	AreaChart,
	Area,
} from "recharts"
import { formatNumber } from "../../utils/Formatter"
import "./../../../style/css/Charts.css"
import Switch from "@mui/material/Switch"
import { UserSettingsContext } from "../../Context/UserSettingsContext"

const CURRENT_PORTFOLIO_COLOR = "rgb(11, 136, 0)"
const MIN_VOL_COLOR = "rgb(0, 75, 136)"
const MAX_IR_COLOR = "rgb(136, 0, 57)"
const SAME_RETURN_MIN_VOL_COLOR = "rgb(199, 146, 0)"
const SAME_VOL_MAX_RETURN_COLOR = "rgb(218, 87, 0)"

const getMinVolatility = (data = []) => {
	let minVol = data.find(
		(d) =>
			d.volatility ===
			data
				.map((d) => d.volatility)
				.reduce(function (a, b) {
					return Math.min(a, b)
				}, Number.MAX_VALUE)
	)
	return {
		return: minVol.return_EF,
		volatility: minVol.volatility,
		sharpe_ratio: minVol.sharpe_ratio,
	}
}

const getMinMaxReturn = (data = []) => {
	let minRet = data
		.map((d) => d.return_EF)
		.reduce(function (a, b) {
			return Math.min(a, b)
		}, Number.MAX_VALUE)

	let maxRet = data
		.map((d) => d.return_EF)
		.reduce(function (a, b) {
			return Math.max(a, b)
		}, Number.MIN_VALUE)

	let dist = Math.abs((maxRet - minRet) * 0.1)

	return [minRet - dist, maxRet + dist]
}

const getMinMaxVol = (dataEF = [], dataR = []) => {
	let maxVolEF = dataEF
		.map((d) => d.volatility)
		.reduce(function (a, b) {
			return Math.max(a, b)
		}, Number.MIN_VALUE)

	let maxVolR = dataR
		.map((d) => d.volatility)
		.reduce(function (a, b) {
			return Math.max(a, b)
		}, Number.MIN_VALUE)

	return [Math.min(maxVolEF, maxVolR), Math.max(maxVolEF, maxVolR) * 1]
}

const getMaxIr = (data = []) => {
	const maxIR = data.reduce((prev, next) =>
		prev.sharpe_ratio > next.sharpe_ratio ? prev : next
	)
	if (!maxIR) {
		console.log("MAX IR NOT FOUND", maxIR, data)
		return {
			return: 0,
			volatility: 0,
			sharpe_ratio: 0,
		}
	}
	return {
		return: maxIR.return_EF,
		volatility: maxIR.volatility,
		sharpe_ratio: maxIR.sharpe_ratio,
	}
}

const getSameReturnMinVol = (data = [], currentPortfolio) => {
	const closestReturn = data.reduce((prev, next) =>
		Math.abs(currentPortfolio.return - prev.return_EF) <
		Math.abs(currentPortfolio.return - next.return_EF)
			? prev
			: next
	)

	if (
		closestReturn &&
		closestReturn.volatility < currentPortfolio.volatility
	) {
		return {
			return: closestReturn.return_EF,
			volatility: closestReturn.volatility,
			sharpe_ratio: closestReturn.sharpe_ratio,
		}
	}
	return currentPortfolio
}

const getSameVolMaxReturn = (data = [], currentPortfolio) => {
	const closestVol = data
		.filter((p) => p.return_EF >= currentPortfolio.return)
		.reduce((prev, next) =>
			Math.abs(currentPortfolio.volatility - prev.volatility) <
			Math.abs(currentPortfolio.volatility - next.volatility)
				? prev
				: next
		)
	if (closestVol) {
		return {
			return: closestVol.return_EF,
			volatility: closestVol.volatility,
			sharpe_ratio: closestVol.sharpe_ratio,
		}
	}
	return null
}

function formatEfficientWeights(data, efficientFrontier, userCurrency) {
	if (!data || !Object.keys(data)) return data

	const volatility = efficientFrontier.map((ef) => ef.volatility)

	const minVolIndex = volatility.indexOf(Math.min(...volatility))

	const reducedVolatility = volatility.slice(minVolIndex)

	const names = Object.keys(data)

	const res = reducedVolatility.map((v, i) => {
		const values = names.map((n) => data[n][i + minVolIndex])

		const ranges = values.reduce(
			(prev, curr) => prev.concat(Math.min(prev[prev.length - 1] + curr, 1)),
			[0]
		)

		const nameValue = names.map((n, j) => [
			n.slice(3, 6) === userCurrency ? `Cash ${n.slice(0, 3)}` : n,
			[ranges[j], ranges[j + 1]],
		])

		return Object.fromEntries([["vol", v], ...nameValue])
	})

	return {
		EW: res,
		names: names.map((n) =>
			n.slice(3, 6) === userCurrency ? `Cash ${n.slice(0, 3)}` : n
		),
	}
}

const Optimizer = ({ optimizePlot, visibility }) => {
	const [showRandom, setRandom] = useState(false)
	const [graphPlot, setPlot] = useState([])

	const { userCurrency } = useContext(UserSettingsContext)

	const { efficientFrontier, plot, portfolioPoint, efficientWeights } =
		optimizePlot && optimizePlot.plot ? optimizePlot.plot : {}

	const toggleRandom = (show) => {
		if (show) {
			setPlot([...efficientFrontier, ...sortedPlot, ...interestPoints])
		} else {
			setPlot([...efficientFrontier, ...interestPoints])
		}
	}

	useEffect(() => {
		if (efficientFrontier && plot && portfolioPoint) {
			toggleRandom(showRandom)
		}
	}, [optimizePlot])

	if (!optimizePlot || !optimizePlot.plot) {
		return null
	}

	if (!efficientFrontier || !plot || !portfolioPoint || !efficientWeights) {
		return null
	}

	if (optimizePlot.loadingOpti) {
		return <p className="loading-graph-data">Loading optimizer data...</p>
	}

	const minVol = getMinVolatility(efficientFrontier)
	const maxIR = getMaxIr(efficientFrontier)

	const sameReturnMinVol = getSameReturnMinVol(
		efficientFrontier,
		portfolioPoint
	)

	// const sameVolMaxReturn = getSameVolMaxReturn(
	// 	efficientFrontier,
	// 	portfolioPoint
	// )

	const interestPoints = [
		minVol,
		maxIR,
		portfolioPoint,
		sameReturnMinVol,
		// sameVolMaxReturn,
	]

	const sortedPlot = plot ? plot : []

	const [minY, maxY] = getMinMaxReturn(efficientFrontier)
	const [minX, maxX] = getMinMaxVol(efficientFrontier, plot)

	const { EW, names } = formatEfficientWeights(
		efficientWeights,
		efficientFrontier,
		userCurrency
	)

	const colorsForName = Object.fromEntries(
		visibility.visibility.map((v) => [v.name, v.color])
	)

	return (
		<>
			<div className="toggle-container">
				<span className="switch-label">Show random portfolios</span>
				<Switch
					checked={showRandom}
					onChange={(e) => {
						toggleRandom(e.target.checked)
						setRandom(e.target.checked)
					}}
					color="default"
					size="medium"
				/>
			</div>
			<div className="legend-container">
				<div className="point-legend">
					<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
						<circle
							cx="5"
							cy="5"
							r="3"
							fill={CURRENT_PORTFOLIO_COLOR}
							stroke={CURRENT_PORTFOLIO_COLOR}
						/>
					</svg>
					<span>Current Portfolio</span>
				</div>
				<div className="point-legend">
					<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
						<circle
							cx="5"
							cy="5"
							r="3"
							fill={MIN_VOL_COLOR}
							stroke={MIN_VOL_COLOR}
						/>
					</svg>
					<span>Min volatility Portfolio</span>
				</div>
				<div className="point-legend">
					<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
						<circle
							cx="5"
							cy="5"
							r="3"
							fill={MAX_IR_COLOR}
							stroke={MAX_IR_COLOR}
						/>
					</svg>
					<span>Max IR Portfolio</span>
				</div>
				<div className="point-legend">
					<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
						<circle
							cx="5"
							cy="5"
							r="3"
							fill={SAME_RETURN_MIN_VOL_COLOR}
							stroke={SAME_RETURN_MIN_VOL_COLOR}
						/>
					</svg>
					<span>Same return with min risk</span>
				</div>
				{/* <div className="point-legend">
					<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
						<circle
							cx="5"
							cy="5"
							r="3"
							fill={SAME_VOL_MAX_RETURN_COLOR}
							stroke={SAME_VOL_MAX_RETURN_COLOR}
						/>
					</svg>
					<span>Same risk with maximum return</span>
				</div> */}
				<div className="point-legend">
					<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
						<line x1="0" y1="5" x2="10" y2="5" stroke="black" />
					</svg>
					<span>Efficient Frontier</span>
				</div>
			</div>
			<ComposedChart
				className="watermark optimizer"
				width={1100}
				height={400}
				margin={{ top: 20, right: 150, bottom: 20, left: 50 }}
				data={graphPlot}>
				<XAxis
					type="number"
					dataKey="volatility"
					domain={[minX, maxX]}
					label={{
						value: "Annualised risk",
						position: "bottom",
					}}
					name="volatility"
					tickFormatter={(v) => `${formatNumber(v * 100, 1)}%`}
				/>
				<YAxis
					type="number"
					dataKey="return"
					label={{
						value: "Annualised return",
						position: "left",
						angle: -90,
						offset: 30,
					}}
					domain={[minY, maxY]}
					name="return"
					padding={{ left: 50, right: 20 }}
					tickFormatter={(v) => `${formatNumber(v * 100, 1)}%`}
				/>

				<ZAxis dataKey="sharpe_ratio" name="sharpe ratio" />

				<Scatter
					type="number"
					name="Optimization"
					dataKey="return"
					isAnimationActive={false}>
					{graphPlot.map((entry, index) => {
						if (entry === portfolioPoint) {
							return (
								<Cell
									key={`cell-${index}`}
									fill={CURRENT_PORTFOLIO_COLOR}
									stroke={CURRENT_PORTFOLIO_COLOR}
									strokeWidth={10}
								/>
							)
						}

						if (
							entry.volatility === minVol.volatility &&
							entry.return === minVol.return
						) {
							return (
								<Cell
									key={`cell-${index}`}
									fill={MIN_VOL_COLOR}
									stroke={MIN_VOL_COLOR}
									strokeWidth={10}
									className="minVol"
								/>
							)
						}

						if (
							entry.volatility === maxIR.volatility &&
							entry.return === maxIR.return
						) {
							return (
								<Cell
									key={`cell-${index}`}
									fill={MAX_IR_COLOR}
									stroke={MAX_IR_COLOR}
									strokeWidth={10}
								/>
							)
						}

						if (
							sameReturnMinVol &&
							entry.volatility === sameReturnMinVol.volatility &&
							entry.return === sameReturnMinVol.return
						) {
							return (
								<Cell
									key={`cell-${index}`}
									fill={SAME_RETURN_MIN_VOL_COLOR}
									stroke={SAME_RETURN_MIN_VOL_COLOR}
									strokeWidth={10}
								/>
							)
						}

						// if (
						// 	sameVolMaxReturn &&
						// 	entry.volatility === sameVolMaxReturn.volatility &&
						// 	entry.return === sameVolMaxReturn.return
						// ) {
						// 	return (
						// 		<Cell
						// 			key={`cell-${index}`}
						// 			fill={SAME_VOL_MAX_RETURN_COLOR}
						// 			stroke={SAME_VOL_MAX_RETURN_COLOR}
						// 			strokeWidth={10}
						// 		/>
						// 	)
						// }

						return (
							<Cell
								key={`cell-${index}`}
								fill={entry.color}
								stroke={entry.color}
								strokeWidth={1}
							/>
						)
					})}
				</Scatter>
				<Line
					type="basis"
					dataKey="return_EF"
					stroke="black"
					dot={
						graphPlot.length < efficientFrontier.length + 6
							? { strokeWidth: 1 }
							: false
					}
					activeDot={graphPlot.length < efficientFrontier.length + 6}
					strokeWidth={3}
					isAnimationActive={false}
				/>
				{graphPlot.length < efficientFrontier.length + 6 ? (
					<Tooltip
						content={
							<CustomScatterTooltip
								minVol={minVol}
								maxIR={maxIR}
								sameReturnMinVol={sameReturnMinVol}
								portfolioPoint={portfolioPoint}
							/>
						}
					/>
				) : null}
			</ComposedChart>
			<AreaChart
				className="watermark optimizer"
				width={1100}
				height={400}
				margin={{ top: 20, right: 150, bottom: 20, left: 50 }}
				data={EW}>
				<XAxis
					type="number"
					dataKey="vol"
					domain={[minX, maxX]}
					tickFormatter={(v) => `${formatNumber(v * 100, 1)}%`}
					label={{
						value: "Portfolio Weigthings on the Efficient Frontier",
						position: "bottom",
					}}
					// domain={["dataMin", "dataMax"]}
				/>
				<YAxis
					type="number"
					label={{
						value: "Weight",
						position: "left",
						angle: -90,
						offset: 30,
					}}
					name="weight"
					padding={{ left: 50, right: 20 }}
					tickFormatter={(v) => `${formatNumber(v * 100, 1)}%`}
				/>
				{/* <Area dataKey={"EURUSD"} stroke="red" fill="#8884d8" /> */}
				{names.map((n, i) => (
					<Area
						key={i}
						dataKey={n}
						stroke={colorsForName[n]}
						// opacity={(i + 1) / names.length}
						fill={colorsForName[n]}
					/>
				))}
				<Tooltip
					content={<CustomStackedTooltip />}
					colorsForName={colorsForName}
				/>
			</AreaChart>
			<div className="optimizer-space"></div>
		</>
	)
}

const CustomizedAxisTickY = ({ x, y, stroke, payload }) => {
	return (
		<g transform={`translate(${x - 20},${y - 10})`}>
			<text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
				{formatNumber(payload.value * 100)} %
			</text>
		</g>
	)
}

const CustomizedAxisTickX = ({ x, y, stroke, payload }) => {
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
				{formatNumber(payload.value * 100)} %
			</text>
		</g>
	)
}

const CustomStackedTooltip = ({ active, payload, label, colorsForName }) => {
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip">
				<h5>Volatility {formatNumber(label * 100, 1)} %</h5>
				{payload.map((p, i) => (
					<div
						key={i}
						className="tooltip-value"
						style={{ color: colorsForName[p.name] }}>
						<h5 style={{ color: p.color }}>{p.name}:</h5>{" "}
						<span>
							{formatNumber((p.value[1] - p.value[0]) * 100, 1)}%
						</span>
					</div>
				))}
			</div>
		)
	}
	return null
}

const CustomScatterTooltip = ({
	active,
	payload,
	label,
	minVol,
	maxIR,
	sameReturnMinVol,
	portfolioPoint,
}) => {
	if (active && payload && payload.length) {
		const data = payload[0].payload
		const { title, color } =
			label === minVol.volatility
				? { title: "Min Volatility Portfolio", color: MIN_VOL_COLOR }
				: label === maxIR.volatility
				? { title: "Max IR Portfolio", color: MAX_IR_COLOR }
				: label === portfolioPoint.volatility
				? { title: "Current Portfolio", color: CURRENT_PORTFOLIO_COLOR }
				: label === sameReturnMinVol.volatility
				? {
						title: "Same return min risk",
						color: SAME_RETURN_MIN_VOL_COLOR,
				  }
				: { title: "Efficient frontier", color: "black" }

		return (
			<div className="custom-tooltip" style={{ color: color }}>
				<h5>{title}:</h5>
				<div className="tooltip-value">
					<h5>Volatility:</h5>
					<span>{formatNumber(data.volatility * 100, 1)}%</span>
				</div>
				<>
					<div className="tooltip-value">
						<h5>Return:</h5>
						<span>
							{formatNumber(
								data.return ? data.return * 100 : data.return_EF * 100,
								1
							)}
							%
						</span>
					</div>
					<div className="tooltip-value">
						<h5>Sharpe ratio:</h5>
						<span>{formatNumber(data.sharpe_ratio, 1)}</span>
					</div>
				</>
			</div>
		)
	}
	return null
}

export default Optimizer
