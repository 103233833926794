const HeaderDataReducer = (headerData, action) => {
	switch (action.type) {
		case "RESET":
			// console.log("HEADER: ", action.allHeaderData)
			return action.allHeaderData
		case "ADD_PORTFOLIO":
			const newHeader = {
				portfolio_id: action.portfolio_id,
				name: action.name,
				description: action.description,
			}
			return [...headerData, newHeader].sort(
				(h1, h2) => h1.portfolio_id - h2.portfolio_id
			)
		case "DELETE_PORTFOLIO":
			return headerData.filter(
				(header) => header.portfolio_id !== action.portfolio_id
			)
		case "UPDATE_PORTFOLIO":
			return headerData.map((h) => {
				if (h.portfolio_id === action.portfolio_id) {
					return {
						...h,
						...action.headerData,
					}
				}
				return h
			})
		case "CHANGE_NAME":
			return headerData.map((h) => {
				if (h.portfolio_id === action.portfolio_id) {
					return {
						...h,
						name: action.name,
					}
				}
				return h
			})
		default:
			return headerData
	}
}

export default HeaderDataReducer
