import "./../../style/css/Loading.css"
import React, { useRef, useEffect } from "react"
import {
	useSpring,
	useSprings,
	animated,
	useChain,
	useSpringRef,
	config as springConfig,
} from "react-spring"
import { useState } from "react"

const Loading = () => {
	return (
		<div className="loading-container">
			<Logo />
		</div>
	)
}

export default Loading

const config = {
	tension: 680,
	friction: 26,
	duration: 500,
	velocity: 0.2,
}

const START_X = 21.2
const END_X = 35.2
const START_SCALE = 1
const END_SCALE = 2

const RANGE = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.75, 0.8, 0.85, 0.9, 1]
const SCALE_OUTPUT = [
	START_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
	END_SCALE,
	END_SCALE,
	START_SCALE,
	START_SCALE,
	START_SCALE,
]
const X_OUTPUT = [
	START_X,
	START_X,
	END_X,
	END_X,
	END_X,
	END_X,
	START_X,
	START_X,
	START_X,
	START_X,
	START_X,
	START_X,
	START_X,
]

const Logo = () => {
	const [flip, setFlip] = useState(true)
	const [didStart, setStart] = useState(false)

	const { x } = useSpring({
		cancel: () => !didStart,
		// loop: true,
		reverse: false,
		from: { x: 0 },
		x: 1,
		config: { ...config, duration: 3000 },
		// onRest: () => setFlip(!flip),
	})

	useEffect(() => {
		return () => {
			setStart(false)
		}
	}, [])

	return (
		<svg className="logo" viewBox="0 0 39 6.7" overflow="auto">
			<g>
				<AnimatedASSE
					pathA={PathForLetter.a1}
					pathS1={PathForLetter.s1}
					pathS2={PathForLetter.s2}
					pathE={PathForLetter.e}
					x={x.to({
						range: RANGE,
						output: X_OUTPUT,
					})}
				/>
				<AnimatedRACK
					pathR={PathForLetter.r}
					pathA={PathForLetter.a2}
					pathC={PathForLetter.c}
					pathK={PathForLetter.k}
					x={x.to({
						range: RANGE,
						output: X_OUTPUT,
					})}
				/>
				<animated.g
					transform={x
						.to({
							range: RANGE,
							output: SCALE_OUTPUT,
						})
						.to(
							(scale) =>
								`translate(19, 3.35) scale(${scale}) translate(-19, -3.35)`
						)}>
					<AnimatedTT
						pathT1={PathForLetter.t1}
						pathT2={PathForLetter.t2}
						setStart={setStart}
					/>
				</animated.g>
			</g>
		</svg>
	)
}

const AnimatedASSE = ({ pathA, pathS1, pathS2, pathE, x }) => {
	return (
		<animated.g>
			<animated.rect
				x={x.to((x) => 24.5 - x)}
				y={1.75}
				width={14}
				height={3.2}
				fill="white"
				// fill="rgba(255, 102, 102, 0.753)"
			/>
			<animated.path d={pathA} />
			<animated.path d={pathS1} />
			<animated.path d={pathS2} />
			<animated.path d={pathE} />

			<animated.rect
				x={x.to((x) => 24.5 - x)}
				y={1.75}
				width={14}
				height={3.2}
				fill="white"
				// fill="rgba(255, 102, 102, 0.753)"
			/>
		</animated.g>
	)
}

const AnimatedRACK = ({ pathR, pathA, pathC, pathK, w, x }) => {
	return (
		<animated.g>
			<animated.rect
				x={x}
				y={1.75}
				width={14}
				height={3.2}
				fill="white"
				// fill="rgba(255, 102, 102, 0.753)"
			/>
			<animated.path d={pathR} />
			<animated.path d={pathA} />
			<animated.path d={pathC} />
			<animated.path d={pathK} />

			<animated.rect
				x={x}
				y={1.75}
				width={14}
				height={3.2}
				fill="white"
				// fill="rgba(255, 102, 102, 0.753)"
			/>
		</animated.g>
	)
}

const AnimatedTT = ({ pathT1, pathT2, setStart }) => {
	const springRefH = useSpringRef()

	const { h } = useSpring({
		ref: springRefH,
		from: { h: 3 },
		h: 0,
		config,
	})

	const springRefXW = useSpringRef()

	const { x, w } = useSpring({
		ref: springRefXW,
		from: { x: 19.2, w: 2 },
		to: { x: 21.2, w: 0 },
		delay: 500,
		config,
	})

	const springRefScale = useSpringRef()

	const { transform } = useSpring({
		ref: springRefScale,
		reset: false,
		reverse: false,
		from: {
			transform: `translate(19, 3.35) scale(${END_SCALE}) translate(-19, -3.35)`,
		},
		transform: `translate(19, 3.35) scale(${START_SCALE}) translate(-19, -3.35)`,
		config: config,
		delay: 500,
		onRest: () => {
			setStart(true)
		},
	})

	useChain([springRefH, springRefXW, springRefScale])

	return (
		<animated.g transform={transform} delay={1500}>
			<animated.path d={pathT1} />
			<animated.path d={pathT2} />
			<animated.rect x={17.2} y={2.4} width={4} height={h} fill="white" />
			<animated.rect x={17.2} y={1.82} width={w} height={0.6} fill="white" />
			<animated.rect x={x} y={1.82} width={w} height={0.6} fill="white" />
		</animated.g>
	)
}

const PathForLetter = {
	a1: "M3.46,4.81l1-3h.62l1,3H5.51l-.22-.66h-1l-.23.66Zm1-1.14h.69l-.34-1h0Z",
	s1: "M7.08,4.13l.49-.29a.87.87,0,0,0,.8.51c.3,0,.51-.11.51-.34s-.21-.32-.6-.43S7.2,3.3,7.2,2.68s.43-.89,1.07-.89a1.33,1.33,0,0,1,1.18.61L9,2.74a.88.88,0,0,0-.72-.42c-.28,0-.43.12-.43.3s.22.32.61.43,1.07.3,1.07.91-.36.9-1.19.9A1.29,1.29,0,0,1,7.08,4.13Z",
	s2: "M10.6,4.13l.49-.29a.88.88,0,0,0,.8.51c.3,0,.52-.11.52-.34s-.21-.32-.6-.43-1.08-.28-1.08-.9.43-.89,1.07-.89A1.3,1.3,0,0,1,13,2.4l-.45.34a.88.88,0,0,0-.73-.42c-.27,0-.42.12-.42.3s.22.32.6.43,1.08.3,1.08.91-.36.9-1.19.9A1.32,1.32,0,0,1,10.6,4.13Z",
	e: "M14.33,4.81v-3h2v.54H15V3h1v.51H15v.74h1.44v.53Z",
	t1: "M18.33,4.81V2.39h-.88V1.85H19v.54h0V4.81Z",
	t2: "M19.41,4.81V2.39h0V1.85h1.52v.54h-.88V4.81Z",
	r: "M21.76,4.81v-3h1.11c.73,0,1.16.26,1.16.91a.79.79,0,0,1-.61.85l.66,1.2h-.69l-.61-1.12H22.4V4.81Zm.64-1.62h.44c.38,0,.56-.11.56-.41s-.18-.4-.56-.4H22.4Z",
	a2: "M25.11,4.81l1-3h.62l1.05,3h-.66l-.22-.66h-1l-.23.66Zm1-1.14h.7l-.34-1h0Z",
	c: "M28.69,3.32A1.35,1.35,0,0,1,30,1.79a1.11,1.11,0,0,1,1.2.93l-.62.18c-.1-.34-.24-.57-.59-.57s-.69.38-.69,1,.25,1,.7,1,.52-.22.62-.63l.62.14a1.17,1.17,0,0,1-1.24,1C29.26,4.86,28.69,4.37,28.69,3.32Z",
	k: "M32.55,4.81v-3h.65V3.07l1-1.22H35L34,3l1.11,1.81h-.74l-.8-1.32-.32.37v.95Z",
}
