const GraphDataReducer = (data, action) => {
	switch (action.type) {
		case "RESET":
			return action.portfolioIds.map((id) => {
				return {
					portfolioId: id,
					loadingData: false,
					loadingOpti: false,
					riskTable: {},
					price: [],
					performance: {},
					volatility: {},
					drawdown: {},
					correlation: {},
					optimisationPlot: {},
					optimisationWeights: [],
				}
			})
		case "CALCULATE":
			return calculate(data, action)
		case "FETCH_DATA":
			if (!data.find((d) => d.portfolioId === action.portfolioId)) {
				return [
					...data,
					{
						portfolioId: action.portfolioId,
						loadingData: true,
						loadingOpti: false,
						riskTable: {},
						price: [],
						performance: {},
						volatility: {},
						drawdown: {},
						correlation: {},
						optimisationPlot: {},
						optimisationWeights: [],
					},
				]
			}
			return data.map((d) => {
				if (d.portfolioId === action.portfolioId) {
					return {
						...d,
						loadingData: true,
					}
				}
				return d
			})
		case "FETCH_OPTI":
			if (!data.find((d) => d.portfolioId === action.portfolioId)) {
				return [
					...data,
					{
						portfolioId: action.portfolioId,
						loadingData: false,
						loadingOpti: true,
						riskTable: {},
						price: [],
						performance: {},
						volatility: {},
						drawdown: {},
						correlation: {},
						optimisationPlot: {},
						optimisationWeights: [],
					},
				]
			}
			return data.map((d) => {
				if (d.portfolioId === action.portfolioId) {
					return {
						...d,
						loadingOpti: true,
					}
				}
				return d
			})
		case "ERROR":
			if (!data.find((d) => d.portfolioId === action.portfolioId)) {
				return [
					...data,
					{
						portfolioId: action.portfolioId,
						loadingData: false,
						loadingOpti: false,
						riskTable: {},
						price: [],
						performance: {},
						volatility: {},
						drawdown: {},
						correlation: {},
						optimisationPlot: {},
						optimisationWeights: [],
					},
				]
			}
			return data.map((d) => {
				if (d.portfolioId === action.portfolioId) {
					return {
						...d,
						loadingOpti: false,
						loadingData: false,
					}
				}
				return d
			})
		default:
			return data
	}
}

const calculate = (data, { portfolioId, newData }) => {
	if (!data.find((d) => d.portfolioId === portfolioId)) {
		return [
			...data,
			{
				...{
					portfolioId: portfolioId,
					loadingData: false,
					loadingOpti: false,
					riskTable: {},
					price: [],
					performance: {},
					volatility: {},
					drawdown: {},
					correlation: {},
					optimisationPlot: {},
					optimisationWeights: [],
				},
				...newData,
			},
		]
	}
	return data.map((d) => {
		if (d.portfolioId === portfolioId) {
			return {
				...d,
				...newData,
				loadingData: false,
				loadingOpti: false,
			}
		}
		return d
	})
}

export default GraphDataReducer
