import { loadAllHeaderData, loadAllData, loadAssetsData } from "./DataFetcher"

export async function fetchSettings(
	userId,
	currency,
	newSettings,
	dispatchAssetsData,
	dispatchHeaderData
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/settings/${userId}/`,
			{
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(newSettings),
			}
		)
		const { assets, headers } = await loadAllData(userId, currency)
		dispatchHeaderData({ type: "RESET", allHeaderData: headers })
		dispatchAssetsData({ type: "RESET", allAssetsData: assets })
	} catch (err) {
		console.error(err)
	}
}

export function createPortfolio(
	userId,
	name,
	description,
	setPortfolioId,
	dispatchAssetsData,
	dispatchHeaderData,
	dispatchVisibility,
	userCurrency
) {
	fetch(
		`${
			process.env.NODE_ENV === "development" ? "https://assettrack.ch" : ""
		}/api/create/`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				user_id: userId,
				name: name,
				description: description,
			}),
		}
	)
		.then((response) => response.json())
		.then(({ portfolio_id }) => {
			dispatchAssetsData({ type: "ADD_PORTFOLIO", portfolio_id })
			dispatchHeaderData({ type: "ADD_PORTFOLIO", portfolio_id })
			dispatchVisibility({ type: "ADD_PORTFOLIO", portfolio_id, name })
			setPortfolioId(portfolio_id)
			loadAllHeaderData(userId).then((headers) => {
				dispatchHeaderData({ type: "RESET", allHeaderData: headers })
				dispatchVisibility({ type: "RESET", headers, userCurrency })
			})
		})
		.catch(console.error)
}

export function deletePortfolio(
	portfolio_id,
	userId,
	dispatchAssetsData,
	dispatchHeaderData,
	dispatchVisibility,
	userCurrency
) {
	fetch(
		`${
			process.env.NODE_ENV === "development" ? "https://assettrack.ch" : ""
		}/api/delete/portfolio/${portfolio_id}/`,
		{
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
		}
	)
		.then(() => {
			dispatchAssetsData({ type: "DELETE_PORTFOLIO", portfolio_id })
			dispatchHeaderData({ type: "DELETE_PORTFOLIO", portfolio_id })
			dispatchVisibility({ type: "DELETE_PORTFOLIO", portfolio_id })
			loadAllHeaderData(userId).then((headers) => {
				dispatchHeaderData({ type: "RESET", allHeaderData: headers })
				dispatchVisibility({ type: "RESET", headers, userCurrency })
			})
		})
		.catch(console.error)
}

export const addAsset =
	(
		portfolio_id,
		name,
		asset_id,
		size,
		trade_price,
		trade_date,
		dispatchHeaderData,
		userId,
		currency
	) =>
	async (dispatch) => {
		try {
			const asset = {
				asset: asset_id,
				size,
				trade_price,
				trade_date,
			}
			dispatch({
				type: "ADD_ASSET",
				portfolio_id,
				name,
				asset_id,
				size,
				trade_price,
				trade_date,
			})
			const response = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/holding/${portfolio_id}/`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(asset),
				}
			)
			const { assets, headers } = await loadAssetsData(
				[portfolio_id],
				currency
			)

			dispatch({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolio_id,
				portfolio_assets: assets[0],
			})

			dispatchHeaderData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolio_id,
				headerData: headers[0],
			})

			const headerData = await loadAllHeaderData(userId)

			dispatchHeaderData({ type: "RESET", allHeaderData: headerData })
		} catch (err) {
			console.error("error adding asset", name, err)
		}
	}

export const deleteAsset =
	(
		portfolio_id,
		asset_id,
		dispatchAssetsData,
		dispatchHeaderData,
		userId,
		currency
	) =>
	async (dispatch) => {
		try {
			dispatch({
				type: "DELETE_ASSET",
				portfolio_id,
				asset_id,
			})
			const response = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/delete/asset/${portfolio_id}/${asset_id}/`,
				{
					method: "DELETE",
					headers: { "Content-Type": "application/json" },
				}
			)

			const { assets, headers } = await loadAssetsData(
				[portfolio_id],
				currency
			)

			dispatchAssetsData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolio_id,
				portfolio_assets: assets[0],
			})

			dispatchHeaderData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolio_id,
				headerData: headers[0],
			})

			const headerData = await loadAllHeaderData(userId)

			dispatchHeaderData({ type: "RESET", allHeaderData: headerData })
		} catch (err) {
			console.error("error deleting asset", asset_id, err)
		}
	}

export const deleteTrade =
	(
		portfolio_id,
		asset_id,
		trade_date,
		dispatchAssetsData,
		dispatchHeaderData,
		userId,
		currency
	) =>
	async (dispatch) => {
		try {
			dispatch({
				type: "DELETE_TRADE",
				portfolio_id,
				asset_id,
				trade_date,
			})

			const response = await fetch(
				`${
					process.env.NODE_ENV === "development"
						? "https://assettrack.ch"
						: ""
				}/api/delete/trade/${portfolio_id}/${asset_id}/`,
				{
					method: "DELETE",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						date: trade_date,
					}),
				}
			)

			const { assets, headers } = await loadAssetsData(
				[portfolio_id],
				currency
			)

			dispatchAssetsData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolio_id,
				portfolio_assets: assets[0],
			})

			dispatchHeaderData({
				type: "UPDATE_PORTFOLIO",
				portfolio_id: portfolio_id,
				headerData: headers[0],
			})

			const headerData = await loadAllHeaderData(userId)

			dispatchHeaderData({ type: "RESET", allHeaderData: headerData })
		} catch (err) {
			console.error("error deleting asset", asset_id, err)
		}
	}

export async function scalePortfolio(
	portfolioId,
	scale_date,
	target_value,
	userCurrency,
	userId,
	dispatchAssetsData,
	dispatchHeaderData,
	dispatchVisibility
) {
	try {
		const response = await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/scale/${portfolioId}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					scale_date,
					target_value,
				}),
			}
		)

		const { scaled_portfolio_id } = await response.json()

		const { assets } = await loadAssetsData(
			[scaled_portfolio_id],
			userCurrency
		)

		dispatchAssetsData({
			type: "ADD_PORTFOLIO",
			portfolio_id: scaled_portfolio_id,
		})

		dispatchAssetsData({
			type: "UPDATE_PORTFOLIO",
			portfolio_id: scaled_portfolio_id,
			portfolio_assets: assets[0],
		})

		const headers = await loadAllHeaderData(userId)

		dispatchHeaderData({ type: "RESET", allHeaderData: headers })

		dispatchVisibility({ type: "RESET", headers, userCurrency })
		return scaled_portfolio_id
	} catch (err) {
		console.error("error scaling portfolio", portfolioId, err)
		return 0
	}
}

export const changePortfolioName = (portfolio_id, name) => async (dispatch) => {
	try {
		dispatch({
			type: "CHANGE_NAME",
			portfolio_id,
			name,
		})

		await fetch(
			`${
				process.env.NODE_ENV === "development"
					? "https://assettrack.ch"
					: ""
			}/api/rename/portfolio/${portfolio_id}/`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					name,
				}),
			}
		)
	} catch (err) {
		console.error("error renaming portfolio", portfolio_id, err)
	}
}
