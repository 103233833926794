import { colorForKey } from "../utils/Formatter"

const VisibilityReducer = (visibility, action) => {
	switch (action.type) {
		case "RESET":
			// console.log("VISIBILITY: ", resetVisibility(action))
			return resetVisibility(action)
		case "ADD_PORTFOLIO":
			return addPortfolio(visibility, action)
		case "DELETE_PORTFOLIO":
			return deletePortfolio(visibility, action)
		case "ADD_ASSET":
			return addAsset(visibility, action)
		case "DELETE_ASSET":
			return deleteAsset(visibility, action)
		case "TOGGLE_PORTFOLIO_VIS":
			return togglePortfolioVis(visibility, action)
		case "SET_PORTFOLIO_LEFT_AXIS":
			return setPortfolioLeftAxis(visibility, action)
		case "TOGGLE_ASSET_VIS":
			return toggleAssetVis(visibility, action)
		case "SET_ASSET_LEFT_AXIS":
			return setAssetLeftAxis(visibility, action)
		case "TOGGLE_ALL_ASSETS":
			return toggleAllAssets(visibility, action)
		case "SORT_INIT":
			return setColorAndSort(visibility)
		case "SORT_ASSET_TABLE":
			return sortAssetsTable(visibility, action)
		case "SORT_PORTFOLIO_TABLE":
			return sortPortfolioTable(visibility, action)
		case "CHANGE_NAME":
			return setColorAndSort(changeName(visibility, action))
		default:
			return visibility
	}
}

const setColorAndSort = (currentVisibility) => {
	return currentVisibility
		.map(({ portfolio_id, dashboardVisibility, visibility }) => {
			return {
				portfolio_id,
				dashboardVisibility: { ...dashboardVisibility, color: "#2dc9c6" },
				visibility: visibility
					.sort((v1, v2) => {
						return v1.name > v2.name ? 1 : -1
					})
					.map((vis, index) => {
						return {
							...vis,
							color: colorForKey(index),
						}
					}),
			}
		})
		.sort((v1, v2) => v1.portfolio_id - v2.portfolio_id)
}

const resetVisibility = ({ headers, userCurrency }) => {
	let dashboardVis = {
		portfolio_id: 0,
		dashboardVisibility: {
			name: "GLOBAL_PORTFOLIO",
			isVisible: true,
			leftAxis: true,
			color: "#2dc9c6",
		},
		visibility: headers
			.filter((header) => header.portfolio_id !== 0)
			.map((header, i) => {
				return {
					id: header.portfolio_id,
					name: header.name,
					isVisible: true,
					leftAxis: true,
				}
			}),
	}

	let portfolioVisibility = headers
		.filter((h) => h.portfolio_id !== 0)
		.map((header) => {
			let portfolioVis = {
				name: header.name,
				isVisible: true,
				leftAxis: true,
			}
			return {
				portfolio_id: header.portfolio_id,
				dashboardVisibility: portfolioVis,
				visibility: header.asset_properties
					? header.asset_properties.map((ap) => {
							return {
								id: ap.asset_id,
								name:
									ap.name.slice(3, 6) === userCurrency
										? `Cash ${ap.name.slice(0, 3)}`
										: ap.name,
								isVisible: true,
								leftAxis: true,
							}
					  })
					: [],
			}
		})

	return setColorAndSort([dashboardVis, ...portfolioVisibility])
}

const addPortfolio = (currentVisibility, { portfolio_id, name }) => {
	return setColorAndSort([
		...currentVisibility,
		{
			portfolio_id,
			dashboardVisibility: {
				name: name,
				isVisible: true,
				leftAxis: true,
				color: "#2dc9c6",
			},
			visibility: [],
		},
	])
}

const deletePortfolio = (currentVisibility, { portfolio_id }) => {
	return setColorAndSort(
		currentVisibility.filter((vis) => vis.portfolio_id !== portfolio_id)
	)
}

const addAsset = (
	currentVisibility,
	{ portfolio_id, asset_id, asset_name }
) => {
	return setColorAndSort(
		currentVisibility.map((vis) => {
			if (vis.portfolio_id === portfolio_id) {
				return {
					...vis,
					visibility: [
						...vis.visibility.filter((v) => v.id !== asset_id),
						{
							id: asset_id,
							name: asset_name,
							isVisible: true,
							leftAxis: true,
						},
					],
				}
			}
			return vis
		})
	)
}

const deleteAsset = (currentVisibility, { portfolio_id, asset_id }) => {
	return setColorAndSort(
		currentVisibility.map((vis) => {
			if (vis.portfolio_id === portfolio_id) {
				return {
					...vis,
					visibility: vis.visibility.filter((v) => v.id !== asset_id),
				}
			}
			return vis
		})
	)
}

const togglePortfolioVis = (currentVisibility, { portfolio_id, isVisible }) => {
	return currentVisibility.map((vis) => {
		if (vis.portfolio_id === portfolio_id) {
			return {
				...vis,
				dashboardVisibility: {
					...vis.dashboardVisibility,
					isVisible,
				},
			}
		}
		return vis
	})
}

const setPortfolioLeftAxis = (
	currentVisibility,
	{ portfolio_id, leftAxis }
) => {
	return currentVisibility.map((vis) => {
		if (vis.portfolio_id === portfolio_id) {
			return {
				...vis,
				dashboardVisibility: {
					...vis.dashboardVisibility,
					leftAxis,
				},
			}
		}
		return vis
	})
}

const toggleAssetVis = (
	currentVisibility,
	{ portfolio_id, asset_id, isVisible }
) => {
	return currentVisibility.map((vis) => {
		if (vis.portfolio_id === portfolio_id) {
			return {
				...vis,
				visibility: vis.visibility.map((v) => {
					if (v.id === asset_id) {
						return {
							...v,
							isVisible,
						}
					}
					return v
				}),
			}
		}
		return vis
	})
}

const setAssetLeftAxis = (
	currentVisibility,
	{ portfolio_id, asset_id, leftAxis }
) => {
	return currentVisibility.map((vis) => {
		if (vis.portfolio_id === portfolio_id) {
			return {
				...vis,
				visibility: vis.visibility.map((v) => {
					if (v.id === asset_id) {
						return {
							...v,
							leftAxis,
						}
					}
					return v
				}),
			}
		}
		return vis
	})
}

const toggleAllAssets = (currentVisibility, { portfolio_id, isVisible }) => {
	return currentVisibility.map((vis) => {
		if (vis.portfolio_id === portfolio_id) {
			return {
				...vis,
				visibility: vis.visibility.map((v) => {
					return { ...v, isVisible }
				}),
			}
		}
		return vis
	})
}

const sortAssetsTable = (
	currentVisibility,
	{ attribute, order, riskTable, optimisationWeights, portfolioId }
) => {
	if (
		optimisationWeights.length === 0 &&
		(attribute === "opti_weight_var" || attribute === "opti_weight_sharpe")
	) {
		return currentVisibility
	}

	function sortVis() {
		const { visibility, ...otherVis } = currentVisibility.find(
			(v) => v.portfolio_id === portfolioId
		)

		if (order === 0) {
			return {
				...otherVis,
				visibility: visibility.sort((v1, v2) =>
					v1.name.localeCompare(v2.name)
				),
			}
		}

		if (
			attribute === "opti_weight_var" ||
			attribute === "opti_weight_sharpe"
		) {
			return {
				...otherVis,
				visibility: visibility.sort((v1, v2) => {
					const weight1 = optimisationWeights.find(
						(w) => w.name === v1.name
					)
					const weight2 = optimisationWeights.find(
						(w) => w.name === v2.name
					)
					return order * (weight1[attribute] - weight2[attribute])
				}),
			}
		} else {
			return {
				...otherVis,
				visibility: visibility.sort(
					(v1, v2) =>
						order *
						(riskTable[v2.name][attribute] -
							riskTable[v1.name][attribute])
				),
			}
		}
	}

	const sortedVis = sortVis()

	return currentVisibility.map((v) =>
		v.portfolio_id === portfolioId ? sortedVis : v
	)
}

const sortPortfolioTable = (
	currentVisibility,
	{ attribute, order, formattedData }
) => {
	if (order === 0) {
		return setColorAndSort(currentVisibility)
	}
	const singleVisibility = currentVisibility.find((v) => v.portfolio_id === 0)

	const vis = [...singleVisibility.visibility]

	const sortedVis = vis.sort((v1, v2) => {
		if (!formattedData[v1.name]) {
			return 1
		}
		if (!formattedData[v2.name]) {
			return -1
		}
		return (
			order *
			(formattedData[v2.name][attribute] - formattedData[v1.name][attribute])
		)
	})

	const sortedSingleVis = {
		...singleVisibility,
		visibility: sortedVis,
	}

	return currentVisibility.map((v) =>
		v.portfolio_id === 0 ? sortedSingleVis : v
	)
}

const changeName = (currentVisibility, action) => {
	return currentVisibility.map(
		({ portfolio_id, dashboardVisibility, visibility }) => {
			if (portfolio_id === 0) {
				const newVis = visibility.map((v) => {
					if (v.id === action.portfolio_id) {
						return { ...v, name: action.name }
					}
					return v
				})
				return {
					portfolio_id,
					dashboardVisibility,
					visibility: newVis,
				}
			} else if (portfolio_id === action.portfolio_id) {
				return {
					portfolio_id,
					dashboardVisibility: {
						...dashboardVisibility,
						name: action.name,
					},
					visibility,
				}
			}
			return {
				portfolio_id,
				dashboardVisibility,
				visibility,
			}
		}
	)
}

export default VisibilityReducer
