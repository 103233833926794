import React, { useContext, useEffect, useState } from "react"
import DragDropDiv from "./../../ReusableComponents/DragDropDiv"
import AddAsset from "./AddAsset"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import {
	AssetsDataContext,
	VisibilityDispatchContext,
	VisibilityContext,
} from "../../Context/PortfolioDataContext"
import { useEditSizeReducer } from "../../Reducers/ThunkReducer"
import AssetListViewEntry from "./AssetListViewEntry"
import { getAllEditSize } from "../../utils/EditSizeFetcher"
import { useTutoStore } from "../../Tutorial/TutoStore"

const AssetListView = ({ portfolioId }) => {
	const [allAssetsVisible, setAllAssetsVisible] = useState(true)
	const allVisibility = useContext(VisibilityContext)

	const visibility = allVisibility.find((v) => v.portfolio_id === portfolioId)

	const dispatchVisibility = useContext(VisibilityDispatchContext)
	const allAssets = useContext(AssetsDataContext)
	const assets = allAssets.find((a) => a.portfolio_id === portfolioId) || {
		portfolio_id: portfolioId,
		assets_properties: [],
	}

	const [editSizes, dispatchEditSize] = useEditSizeReducer(portfolioId, assets)

	const [tutoState, dispatchTuto] = useTutoStore() ?? [false, () => {}]

	useEffect(() => {
		if (tutoState) {
			dispatchEditSize({
				type: "RESET",
				sizes: assets.assets_properties.map((a) => ({
					asset_id: a.asset_id,
					size: null,
				})),
			})
		} else {
			getAllEditSize(
				portfolioId,
				assets.assets_properties.map((a) => a.asset_id)
			).then((sizes) => {
				dispatchEditSize({ type: "RESET", sizes })
			})
		}
	}, [portfolioId])

	return (
		<DragDropDiv
			controls={
				<AddAsset
					portfolioId={portfolioId}
					dispatchEditSize={dispatchEditSize}
				/>
			}>
			<div className="toggle-all-assets-container">
				{allAssetsVisible ? (
					<VisibilityIcon
						className="asset-entry-visibility-icon"
						onClick={() => {
							setAllAssetsVisible(false)
							dispatchVisibility({
								type: "TOGGLE_ALL_ASSETS",
								portfolio_id: portfolioId,
								isVisible: false,
							})
						}}
					/>
				) : (
					<VisibilityOffIcon
						className="asset-entry-visibility-icon"
						onClick={() => {
							setAllAssetsVisible(true)
							dispatchVisibility({
								type: "TOGGLE_ALL_ASSETS",
								portfolio_id: portfolioId,
								isVisible: true,
							})
						}}
					/>
				)}
			</div>
			{visibility && assets.assets_properties.length > 0 ? (
				assets.assets_properties.map((asset) => {
					return (
						visibility.visibility.find(
							(v) => v.id === asset.asset_id
						) && (
							<AssetListViewEntry
								key={asset.asset_id}
								portfolioId={portfolioId}
								asset={asset}
								editSize={
									editSizes.find(
										(es) => es.asset_id === asset.asset_id
									) || { asset_id: asset.asset_id, size: null }
								}
								dispatchEditSize={dispatchEditSize}
								visibility={visibility.visibility.find(
									(v) => v.id === asset.asset_id
								)}
							/>
						)
					)
				})
			) : (
				<p>No asset yet, add one to get started!</p>
			)}
		</DragDropDiv>
	)
}

export default AssetListView
