import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import TextField from "@mui/material/TextField"
import { useContext, useState } from "react"
import BlackWhitePanel from "../ReusableComponents/BackWhitePanel"
import "./../../style/css/Scale.css"
import "./../../style/css/ScaleShareDatePicker.css"
import {
	findClosestDate,
	formatDateDashedYMD,
	disableDates,
} from "./../utils/Formatter"
import {
	AssetsDataDispatchContext,
	HeaderDataDispatchContext,
	VisibilityDispatchContext,
} from "../Context/PortfolioDataContext"
import { UserSettingsContext } from "../Context/UserSettingsContext"
import { scalePortfolio } from "../utils/PortfolioFetchers"
import StaticDataContext from "../Context/StaticDataContext"

const Scale = ({ closeScale, portfolioId, setPortfolioId }) => {
	const dispatchAssetsData = useContext(AssetsDataDispatchContext)
	const dispatchHeaderData = useContext(HeaderDataDispatchContext)
	const dispatchVisibility = useContext(VisibilityDispatchContext)
	const { userId, userCurrency } = useContext(UserSettingsContext)

	const fetchScale = (scale_date, target_value) => {
		scalePortfolio(
			portfolioId,
			formatDateDashedYMD(scale_date),
			target_value,
			userCurrency,
			userId,
			dispatchAssetsData,
			dispatchHeaderData,
			dispatchVisibility
		)
			.then((portfolioId) => {
				setPortfolioId(portfolioId)
			})
			.catch((e) => console.error(e))
	}

	return (
		<BlackWhitePanel
			leftChild={<LeftChild />}
			rightChild={
				<RightChild fetchScale={fetchScale} closeScale={closeScale} />
			}
			closePanel={closeScale}
		/>
	)
}

const LeftChild = () => {
	return (
		<div className="scale-left-container">
			<h1 className="scale-title">scale portfolio</h1>
			<div className="scale-description-container">
				<p>
					Define the size of the scaled portfolio and define the date used
					as the valuation reference date for this rescale.
				</p>
			</div>
		</div>
	)
}

const RightChild = ({ fetchScale, closeScale }) => {
	const { dates } = useContext(StaticDataContext)

	const lastAvailableDate = findClosestDate(dates, new Date())

	const [scaleFactor, setScaleFactor] = useState(100000)
	const [scaleDate, setScaleDate] = useState(lastAvailableDate)

	return (
		<div className="scale-right-container">
			<div className="scale-input-container">
				<h3 className="scale-input-title">Scale</h3>
				<input
					className="scale-input-number"
					name="scale-number"
					type="number"
					placeholder=""
					required={true}
					value={scaleFactor}
					onChange={(e) => setScaleFactor(e.target.value)}
				/>
			</div>
			<div className="scale-input-container">
				<h3 className="scale-input-title">Date</h3>
				<div className="date-picker-container-scale-share">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							views={["day"]}
							shouldDisableDate={(d) => disableDates(dates, d)}
							value={scaleDate}
							minDate={new Date(dates[0])}
							maxDate={new Date()}
							onChange={(d) => setScaleDate(d)}
							inputFormat="dd/MM/yyyy"
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className="scale-buttons">
				<div
					className="reset-scale-button"
					onClick={() => {
						setScaleFactor(100000)
						setScaleDate(lastAvailableDate)
					}}>
					reset scale
				</div>
				<div
					className="submit-scale-button"
					onClick={() => {
						closeScale()
						fetchScale(scaleDate, scaleFactor)
					}}>
					scale
				</div>
			</div>
		</div>
	)
}

export default Scale
