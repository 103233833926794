import React from "react"
import { useSpring, useSprings, animated, config } from "react-spring"
import { useState } from "react"

const Outline = () => {
	return (
		<g fill="white">
			<path
				d="M269.13,132.42a116.21,116.21,0,0,0-116,113.3h4.44a111.63,111.63,0,0,1,223.2,0h4.44A116.21,116.21,0,0,0,269.13,132.42Z"
				transform="translate(-54.58 -36.8)"
			/>
			<path
				d="M421.9,95.73A214.5,214.5,0,0,0,312.56,36.8v4.58c87.82,18.4,155.8,91.42,166.64,181.76h4.49A214.35,214.35,0,0,0,421.9,95.73Z"
				transform="translate(-54.58 -36.8)"
			/>
			<path
				d="M116.36,95.73A214.34,214.34,0,0,0,54.58,223.14h4.49C69.91,132.8,137.88,59.78,225.7,41.38V36.8A214.5,214.5,0,0,0,116.36,95.73Z"
				transform="translate(-54.58 -36.8)"
			/>
		</g>
	)
}

const colors = ["#007331", "#8cc63e", "#f8803b", "#c30000"]

const paths = [
	"M81,173.58a201,201,0,0,0-14.31,72.14H143.5A124.76,124.76,0,0,1,152,203Z",
	"M124,107.33a201.61,201.61,0,0,0-40.89,61.12l71,29.42a125.13,125.13,0,0,1,24.31-36.1Z",
	"M204.18,99,189.09,62.51A201.41,201.41,0,0,0,128,103.4l54.45,54.45a125.17,125.17,0,0,1,36.23-24Z",
	"M266.35,46.07a201.16,201.16,0,0,0-72.13,14.31l29.56,71.38a124.3,124.3,0,0,1,42.57-8.2Z",
	"M344.05,60.38a201.25,201.25,0,0,0-72.14-14.31v77.51a124.57,124.57,0,0,1,42.38,8.64Z",
	"M410.3,103.4a201.24,201.24,0,0,0-61.12-40.89l-29.77,71.87a125.08,125.08,0,0,1,35.75,24.16Z",
	"M455.12,168.45a201.61,201.61,0,0,0-40.89-61.12l-55.16,55.15a125.15,125.15,0,0,1,23.85,35.88Z",
	"M471.55,245.72a201.24,201.24,0,0,0-14.3-72.14L385,203.49a124.89,124.89,0,0,1,8.32,42.23Z",
]

const ColorBoxes = ({ percentage, rotationCount }) => {
	const [flip, setFlip] = useState(false)

	const springs = useSprings(
		paths.length,
		paths.map((d, index) => ({
			reset: true,
			reverse: flip,
			from: { fill: "black", delay: 0 },
			fill:
				percentage !== undefined &&
				percentage > index / 8 &&
				rotationCount > 1
					? colors[Math.floor(index / 2)]
					: "black",
			delay: 100 * (index + 1) + 600,
			config: {
				...config.molasses,
				duration: (index + 1) * 100,
			},
			onRest: () => {
				if (rotationCount <= 1) {
					setFlip(!flip)
				}
			},
		}))
	)

	return (
		<g stroke="white">
			{springs.map(({ fill }, index) => {
				return (
					<animated.path
						key={index}
						fill={fill}
						delay={50 * (index + 1)}
						d={paths[index]}
						transform="translate(-54.58 -36.8)"
					/>
				)
			})}
		</g>
	)
}

const Arrow = ({ percentage, rotationCount, setCount }) => {
	const deg = percentage * 180 - 150

	const [flip, setFlip] = useState(false)
	const animatedStyle = useSpring({
		reset: true,
		reverse: flip,
		from: { transform: "rotate(-150 214.5 204) translate(-54.58 -36.8)" },
		transform:
			rotationCount <= 1
				? "rotate(30 214.5 204) translate(-54.58 -36.8)"
				: percentage === undefined
				? "rotate(-150 214.5 204) translate(-54.58 -36.8)"
				: `rotate(${deg} 214.5 204) translate(-54.58 -36.8)`,
		delay: rotationCount <= 1 ? 0 : 600,
		config:
			rotationCount <= 1
				? {
						tension: 280,
						friction: 100,
						duration: 500,
				  }
				: config.molasses,
		onRest: () => {
			if (rotationCount <= 1) {
				setFlip(!flip)
				setCount((c) => c + 1)
			}
		},
	})

	return (
		<animated.path
			fill="white"
			d="M255.74,227.46l-6.67,24.91L274,259,415,156.58Zm17.74,20.85a8.69,8.69,0,1,1,3.17-11.87A8.69,8.69,0,0,1,273.48,248.31Z"
			transform={animatedStyle.transform}
		/>
	)
}

const Dial = ({ percentage, bottomText, topText, leftText, rightText }) => {
	const [rotationCount, setCount] = useState(0)

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="250"
			height="190"
			viewBox="0 0 429.11 222.24">
			<Outline />
			<ColorBoxes percentage={percentage} rotationCount={rotationCount} />
			<Arrow
				percentage={percentage}
				rotationCount={rotationCount}
				setCount={setCount}
			/>
			<text
				x="214.5"
				y="270"
				fill="white"
				textAnchor="middle"
				fontSize="30px"
				fontWeight="700">
				{bottomText.toUpperCase()}
			</text>
			<text
				x="214.5"
				y="-10"
				fill="white"
				textAnchor="middle"
				fontSize="24px"
				fontWeight="700">
				{topText.toUpperCase()}
			</text>
			<text
				x="55"
				y="235"
				fill="white"
				textAnchor="middle"
				fontSize="24px"
				fontWeight="700">
				{leftText.toUpperCase()}
			</text>
			<text
				x="374"
				y="235"
				fill="white"
				textAnchor="middle"
				fontSize="24px"
				fontWeight="700">
				{rightText.toUpperCase()}
			</text>
		</svg>
	)
}

export default React.memo(
	Dial,
	(prev, next) => prev.percentage === next.percentage
)
