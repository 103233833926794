import React, { useState, useContext } from "react"
import "./../../style/css/Nav.css"
import assettrack_logo_02_white from "./../../resources/assettrack_logo_02_white.svg"
import Settings from "./Settings"
import { UserSettingsContext } from "./../Context/UserSettingsContext"
import Glossary from "./Glossary"
import Profile from "./Profile"

function notImplemented() {
	alert("Not Implemented")
}

const AssetrackNav = ({ logOut, toggleTutorial }) => {
	const [glossaryPopUp, setGlossaryPopUp] = useState(false)
	const [profilePopUp, setProfilePopUp] = useState(false)
	const [settingsPopUp, setSettingsPopUp] = useState(false)

	const settings = useContext(UserSettingsContext)

	return (
		<>
			<nav>
				<div id="icon-container">
					<img id="logo" src={assettrack_logo_02_white} alt="Logo"></img>
					<div id="second-inner-icon-container">
						<button
							onClick={() => setGlossaryPopUp(true)}
							className="icon"
							id="help_icon"></button>
						<button
							onClick={() => setSettingsPopUp(true)}
							name="settings"
							className="icon"
							id="settings_icon"></button>
						<button
							onClick={notImplemented}
							className="icon"
							id="business_icon"></button>
					</div>
					<div id="first-inner-icon-container">
						<button
							onClick={toggleTutorial}
							className="icon"
							id="home_icon"></button>
						<button
							onClick={notImplemented}
							className="icon"
							id="network_icon"></button>
						<button
							onClick={notImplemented}
							className="icon"
							id="notification_icon"></button>
						<button
							onClick={() => setProfilePopUp(true)}
							name="profile"
							className="userIcon icon">
							{settings !== undefined &&
								settings.firstname.charAt(0).toUpperCase()}
							{settings !== undefined &&
								settings.lastname.charAt(0).toUpperCase()}
						</button>
					</div>
				</div>
			</nav>
			{settingsPopUp && (
				<Settings closeSettings={() => setSettingsPopUp(false)} />
			)}
			{glossaryPopUp && (
				<Glossary closeGlossary={() => setGlossaryPopUp(false)} />
			)}
			{profilePopUp && (
				<Profile
					closeProfile={() => setProfilePopUp(false)}
					logOut={logOut}
				/>
			)}
		</>
	)
}

export default AssetrackNav
