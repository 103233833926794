import React from "react"
import { formatNumber } from "../../utils/Formatter"

const AssetRiskTableEntry = ({
	name,
	data,
	optimizeWeights,
	visibility,
	loadingOpti,
}) => {
	const optimizeValues = optimizeWeights.find((o) => o.name === name)

	return visibility.isVisible && data && Object.keys(data).length > 0 ? (
		<div className="risk-table-entry">
			<div className="risk-entry-name">
				<h4 style={{ color: visibility.color }}>{name}</h4>
			</div>
			<div className="risk-entry-info">
				<span>{data.currency}</span>
				<span>
					{name === "PORTFOLIO"
						? formatNumber(data.weight)
						: formatNumber(data.weight * 100, 1)}
				</span>
				<span>{formatNumber(data.last_value)}</span>
				<div className="pnl-total-container">
					<span className="pnl-total-value">
						{formatNumber(data.total_pnl)}
					</span>
					<span className="pnl-total-percent">
						{data.total_return !== undefined
							? `${formatNumber(Number(data.total_return) * 100, 1)}%`
							: ""}
					</span>
				</div>
				<span>{formatNumber(data.price_pnl)}</span>
				<span>{formatNumber(data.div_pnl)}</span>
				<span>{formatNumber(data.fx_pnl)}</span>
				<span>{formatNumber(data.daily_nb)}</span>
				<span>{formatNumber(data.nb_pnl)}</span>
			</div>
			<div className="risk-entry-optimize-values">
				<span>{formatNumber(data.delta, 1)}</span>
				<span>{formatNumber(data.daily_risk, 0)}</span>
				<span>
					{name === "PORTFOLIO"
						? "-"
						: formatNumber(data.risk_contrib * 100, 1)}
				</span>
				<span>{formatNumber(data.ir)}</span>
				<span>{formatNumber(data.wdd)}</span>
			</div>
			<div className="risk-entry-optimizer">
				{!loadingOpti && (
					<>
						<span className="risk-entry-weight-var">
							{optimizeValues !== undefined
								? formatNumber(
										name === "PORTFOLIO"
											? optimizeValues.opti_weight_var
											: optimizeValues.opti_weight_var * 100,
										1
								  )
								: "-"}
						</span>
						<span className="risk-entry-weight-sharpe">
							{optimizeValues !== undefined
								? formatNumber(
										name === "PORTFOLIO"
											? optimizeValues.opti_weight_sharpe
											: optimizeValues.opti_weight_sharpe * 100,
										1
								  )
								: "-"}
						</span>
					</>
				)}
			</div>
		</div>
	) : null
}

export default AssetRiskTableEntry
