import React, { useContext, useEffect, useState } from "react"
import AssetControlBar from "./AssetChartView/AssetControlBar"
import PortfolioControlBar from "./PortfolioChartView/PortfolioControlBar"
import StaticDataContext from "../Context/StaticDataContext"
import {
	findClosestDate,
	formatDateDashedYMD as formatDate,
} from "../utils/Formatter"
import DragDropDiv from "../ReusableComponents/DragDropDiv"
import {
	HeaderDataContext,
	VisibilityContext,
} from "../Context/PortfolioDataContext"
import { useGraphDataReducer } from "../Reducers/ThunkReducer"
import Chart from "../ReusableComponents/Chart"
import CorrelationMatrix from "../ReusableComponents/CorrelationMatrix"
import { UserSettingsContext } from "../Context/UserSettingsContext"
import { calculateOptimisationData } from "../utils/ChartDataFetcher"
import "./../../style/css/ChartView.css"
import Optimizer from "./AssetChartView/Optimizer"
import Loading from "../ReusableComponents/Loading"
import RiskTable from "../ReusableComponents/RiskTable"

const VIEWING_ASSET = {
	RISKTABLE: "asset table",
	ASSETPRICE: "asset price",
	PERFORMANCE: "performance",
	DRAWDOWN: "drawdown",
	VOLATILITY: "daily risk",
	CORRELATION: "correlation",
	OPTIMIZATION: "optimizer",
}

const VIEWING_PORTFOLIO = {
	RISKTABLE: "portfolio table",
	ASSETPRICE: "portfolio value",
	PERFORMANCE: "performance",
	DRAWDOWN: "drawdown",
	VOLATILITY: "daily risk",
	CORRELATION: "correlation",
}

const INTERVAL = {
	D1: "D1",
	W1: "W1",
	M1: "M1",
	M3: "M3",
	M6: "M6",
	YTD: "YTD",
	Y1: "Y1",
	Y3: "Y3",
	Y5: "Y5",
	Y10: "Y10",
	MAX: "MAX",
}

const ChartView = ({ portfolioId }) => {
	const { dates } = useContext(StaticDataContext)
	const { userCurrency } = useContext(UserSettingsContext)

	const allVisibility = useContext(VisibilityContext)
	const visibility =
		allVisibility.find((v) => v.portfolio_id === portfolioId) || {}
	const headerData = useContext(HeaderDataContext)

	const [graphData, dispatchGraphData] = useGraphDataReducer(
		headerData.map((h) => h.portfolio_id)
	)
	const [currentGraphData, setCurrentGraphData] = useState([])

	const VIEWING = portfolioId === 0 ? VIEWING_PORTFOLIO : VIEWING_ASSET

	const initialDateRange = {
		startDate: findClosestDate(
			dates,
			new Date(findClosestDate(dates, new Date()).getFullYear() - 1, 11, 31)
		),
		endDate: findClosestDate(dates, new Date()),
		range: INTERVAL.YTD,
	}

	const [dateRange, setDateRange] = useState(initialDateRange)
	const [viewing, setViewing] = useState(VIEWING.RISKTABLE)

	function calculateOpti() {
		dispatchGraphData(
			calculateOptimisationData(
				portfolioId,
				dateRange.startDate,
				dateRange.endDate,
				userCurrency
			)
		)
	}

	function setEndDate(endDate) {
		let { startDate } = dateRange
		let tempDateRange = {
			startDate: startDate,
			endDate: endDate,
			range: undefined,
		}
		setDateRange(tempDateRange)
	}

	function setStartDate(startDate) {
		let { endDate } = dateRange
		let tempDateRange = {
			startDate: startDate,
			endDate: endDate,
			range: undefined,
		}
		setDateRange(tempDateRange)
	}

	function setInterval(interval) {
		let endDate = findClosestDate(dates, new Date())
		let startDate = getStartDate(endDate, interval)
		let tempDateRange = {
			startDate: startDate,
			endDate: endDate,
			range: interval,
		}
		setDateRange(tempDateRange)
	}

	function getStartDate(endDate, interval) {
		const tempDate = new Date(endDate)
		switch (interval) {
			case INTERVAL.D1:
				tempDate.setDate(tempDate.getDate() - 1)
				break
			case INTERVAL.W1:
				tempDate.setDate(tempDate.getDate() - 7)
				break
			case INTERVAL.M1:
				tempDate.setMonth(tempDate.getMonth() - 1)
				break
			case INTERVAL.M3:
				tempDate.setMonth(tempDate.getMonth() - 3)
				break
			case INTERVAL.M6:
				tempDate.setMonth(tempDate.getMonth() - 6)
				break
			case INTERVAL.YTD:
				tempDate.setMonth(11)
				tempDate.setFullYear(new Date().getFullYear() - 1)
				tempDate.setDate(31)
				break
			case INTERVAL.Y1:
				tempDate.setFullYear(tempDate.getFullYear() - 1)
				break
			case INTERVAL.Y3:
				tempDate.setFullYear(tempDate.getFullYear() - 3)
				break
			case INTERVAL.Y5:
				tempDate.setFullYear(tempDate.getFullYear() - 5)
				break
			case INTERVAL.Y10:
				tempDate.setFullYear(tempDate.getFullYear() - 10)
				break
			case INTERVAL.MAX:
				tempDate.setFullYear(new Date(dates[0]))
				break
			default:
				console.log("error setting date", interval)
		}
		return findClosestDate(dates, tempDate)
	}

	useEffect(() => {
		setViewing(VIEWING.RISKTABLE)
	}, [portfolioId])

	useEffect(() => {
		let data = graphData.find((gd) => gd.portfolioId === portfolioId) || {}
		switch (viewing) {
			case VIEWING.RISKTABLE:
				if (portfolioId !== 0) {
					setCurrentGraphData({
						riskTable: data.riskTable || {},
						optimisationWeights: data.optimisationWeights || [],
						loadingOpti: data.loadingOpti,
					})
					return
				}
				setCurrentGraphData(data.riskTable || {})
				return
			case VIEWING.ASSETPRICE:
				setCurrentGraphData(data.price || [])
				return
			case VIEWING.PERFORMANCE:
				setCurrentGraphData({
					value: data.performance || [],
					percentage: data.performancePercentage || [],
				})
				return
			case VIEWING.DRAWDOWN:
				setCurrentGraphData({
					value: data.drawdown || [],
					percentage: data.drawdownPercentage || [],
				})
				return
			case VIEWING.VOLATILITY:
				setCurrentGraphData({
					value: data.volatility || [],
					percentage: data.volatilityPercentage || [],
				})
				return
			case VIEWING.CORRELATION:
				setCurrentGraphData(data.correlation || {})
				return
			case VIEWING.OPTIMIZATION:
				setCurrentGraphData(
					{
						plot: data.optimisationPlot || [],
						loadingOpti: data.loadingOpti,
					} || []
				)
				return
			default:
				setCurrentGraphData({})
		}
	}, [viewing, graphData, portfolioId])

	return headerData.length > 0 ? (
		<DragDropDiv
			controls={
				portfolioId === 0 ? (
					<PortfolioControlBar
						setViewing={setViewing}
						VIEWING={VIEWING}
						viewing={viewing}
						setInterval={setInterval}
						INTERVAL={INTERVAL}
						dateRange={dateRange}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						portfolioId={portfolioId}
						dispatchGraphData={dispatchGraphData}
					/>
				) : (
					<AssetControlBar
						setViewing={setViewing}
						VIEWING={VIEWING}
						viewing={viewing}
						setInterval={setInterval}
						INTERVAL={INTERVAL}
						dateRange={dateRange}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						portfolioId={portfolioId}
						dispatchGraphData={dispatchGraphData}
					/>
				)
			}>
			{graphData.find((gd) => gd.portfolioId === portfolioId) &&
			graphData.find((gd) => gd.portfolioId === portfolioId).loadingData ? (
				<div className="loading-container-chartview">
					<Loading />
					<p className="loading-graph-data">Loading {viewing} data...</p>
				</div>
			) : portfolioId !== 0 && viewing === VIEWING.OPTIMIZATION ? (
				<Optimizer
					optimizePlot={currentGraphData}
					visibility={visibility}
				/>
			) : viewing === VIEWING.CORRELATION ? (
				<CorrelationMatrix correlationData={currentGraphData} />
			) : viewing === VIEWING.RISKTABLE ? (
				<RiskTable
					riskData={currentGraphData}
					visibility={visibility}
					portfolioId={portfolioId}
				/>
			) : (
				<Chart graphData={currentGraphData} visibility={visibility} />
			)}
		</DragDropDiv>
	) : null
}

export default ChartView
