import React, { useContext, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import SearchIcon from "@mui/icons-material/Search"
import "./../../../style/css/AssetSelector.css"
import StaticDataContext from "../../Context/StaticDataContext"
import { UserSettingsContext } from "../../Context/UserSettingsContext"

const AssetSelector = ({
	setName,
	setId,
	setAssetSelectorPopup,
	currentAsset,
}) => {
	const { assets } = useContext(StaticDataContext)
	const { userCurrency } = useContext(UserSettingsContext)

	const allAssets = assets.filter(
		(a) =>
			(a.type === "Cash" && a.userCurrency === userCurrency) ||
			a.type !== "Cash"
	)

	const [searchInput, setSearchInput] = useState("")
	const [filteredAssets, setFilteredAssets] = useState(allAssets)
	const [currentType, setCurrentType] = useState(allAssets[0].type)
	const types = new Set(allAssets.map((a) => a.type))

	return (
		<div className="asset-selector">
			<div className="asset-selector-left">
				<div className="search-input-container">
					<input
						className="search-asset-input"
						placeholder="SEARCH ASSETS"
						type="text"
						value={searchInput}
						onChange={(e) => {
							setFilteredAssets(
								allAssets.filter((a) =>
									a.name.includes(e.target.value.toUpperCase())
								)
							)
							setSearchInput(e.target.value)
						}}></input>
					<SearchIcon className="search-icon" />
				</div>
				<div className="search-border-bottom"></div>
				<div className="asset-types">
					{[...types].map((t) => (
						<h5
							key={t}
							className={
								t.toUpperCase() === currentType.toUpperCase()
									? "asset-type-title selected"
									: "asset-type-title"
							}
							onClick={() => setCurrentType(t)}>
							{t}
						</h5>
					))}
				</div>
			</div>
			<div className="asset-selector-right">
				<div className="close-asset-selector-icon-container">
					<DoneIcon
						className="asset-selector-icon done"
						onClick={() => setAssetSelectorPopup(false)}></DoneIcon>
					<CloseIcon
						className="asset-selector-icon close"
						onClick={() => {
							setName()
							setId()
							setAssetSelectorPopup(false)
						}}></CloseIcon>
				</div>

				<div className="asset-selector-list">
					<h5 className="asset-selector-list-title">{currentType}</h5>
					<div className="asset-selector-list-container">
						{filteredAssets
							.filter((a) => a.type === currentType)
							.map((a) => (
								<span
									key={a.id}
									className={
										currentAsset === a.name
											? "asset-selector-list-item selected"
											: "asset-selector-list-item"
									}
									onClick={() => {
										setName(a.name)
										setId(a.id)
									}}>
									{a.name}
								</span>
							))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AssetSelector
