import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
} from "recharts"
import React, { useState } from "react"
import "./../../style/css/Charts.css"
import { formatNumber } from "../utils/Formatter"
import Switch from "@mui/material/Switch"

const Chart = ({ graphData, visibility }) => {
	const vis = [
		{
			...visibility.dashboardVisibility,
			name:
				visibility.dashboardVisibility.name === "GLOBAL_PORTFOLIO"
					? "GLOBAL_PORTFOLIO"
					: "PORTFOLIO",
		},
		...visibility.visibility,
	]
	if (graphData && graphData.length > 0) {
		return <NormalPlot graphData={graphData} vis={vis} />
	} else if (graphData.value || graphData.percentage) {
		if (graphData.value.length > 0 && graphData.percentage.length > 0) {
			return <PercentagePlot graphData={graphData} vis={vis} />
		}
	}
	return null
}

const PercentagePlot = ({ graphData, vis }) => {
	const [isValuePlot, setValuePlot] = useState(true)

	return (
		<>
			<div className="toggle-container">
				<span className="switch-label">%</span>
				<Switch
					checked={isValuePlot}
					onChange={(e) => setValuePlot(e.target.checked)}
					color="default"
					size="medium"
				/>
				<span className="switch-label">$</span>
			</div>
			<ResponsiveContainer className="watermark" width="100%" height={500}>
				<LineChart
					className="line-chart"
					margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
					data={isValuePlot ? graphData.value : graphData.percentage}>
					<CartesianGrid horizontal={false} />
					{vis.map((v, i) =>
						v.isVisible ? (
							<Line
								key={i}
								type="monotone"
								dataKey={v.name}
								stroke={v.color}
								strokeWidth={2}
								margin={{
									top: 5,
									right: 50,
									left: 50,
									bottom: 5,
								}}
								dot={false}
								yAxisId={v.leftAxis ? "left" : "right"}
							/>
						) : null
					)}
					<XAxis
						className="x-axis"
						axisLine={false}
						dataKey="date"
						height={60}
						tick={<CustomizedAxisTick />}
						interval={Math.round(
							Object.keys(
								isValuePlot ? graphData.value : graphData.percentage
							).length / 7
						)}
					/>
					<YAxis
						yAxisId="right"
						orientation="right"
						axisLine={false}
						tickLine={false}
						domain={["dataMin", "dataMax"]}
						width={100}
						tickFormatter={(value) =>
							isValuePlot
								? formatNumber(value, 0)
								: `${formatNumber(value * 100, 1)} %`
						}
					/>
					<YAxis
						yAxisId="left"
						axisLine={false}
						tickLine={false}
						domain={["dataMin", "dataMax"]}
						width={100}
						tickFormatter={(value) =>
							isValuePlot
								? formatNumber(value, 0)
								: `${formatNumber(value * 100, 1)} %`
						}
					/>
					<Tooltip
						content={<CustomTooltip isPercentage={!isValuePlot} />}
					/>
				</LineChart>
			</ResponsiveContainer>
		</>
	)
}

const NormalPlot = ({ graphData, vis }) => {
	return (
		<ResponsiveContainer className="watermark" width="100%" height={500}>
			<LineChart
				className="line-chart"
				margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
				data={graphData}>
				<CartesianGrid horizontal={false} />
				{vis.map((v, i) =>
					v.isVisible ? (
						<Line
							key={i}
							type="monotone"
							dataKey={v.name}
							stroke={v.color}
							strokeWidth={2}
							margin={{
								top: 5,
								right: 50,
								left: 50,
								bottom: 5,
							}}
							dot={false}
							yAxisId={v.leftAxis ? "left" : "right"}
						/>
					) : null
				)}
				<XAxis
					className="x-axis"
					axisLine={false}
					dataKey="date"
					height={60}
					tick={<CustomizedAxisTick />}
					interval={Math.round(Object.keys(graphData).length / 7)}
				/>
				<YAxis
					yAxisId="right"
					orientation="right"
					axisLine={false}
					tickLine={false}
					domain={["dataMin", "dataMax"]}
					width={100}
					tickFormatter={(value) => formatNumber(value, 0)}
				/>
				<YAxis
					yAxisId="left"
					axisLine={false}
					tickLine={false}
					domain={["dataMin", "dataMax"]}
					width={100}
					tickFormatter={(value) => formatNumber(value, 0)}
				/>
				<Tooltip content={<CustomTooltip />} />
			</LineChart>
		</ResponsiveContainer>
	)
}

const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
]

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
	const date = new Date(payload.value)
	const month = months[date.getMonth()]

	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
				{month + " " + date.getFullYear()}
			</text>
		</g>
	)
}

const CustomTooltip = ({ active, payload, label, isPercentage = false }) => {
	if (active && payload && payload.length) {
		const date = new Date(label)
		const sortedData = payload.sort((p1, p2) =>
			p1.dataKey === "PORTFOLIO" || p1.dataKey === "GLOBAL_PORTFOLIO"
				? -1
				: p2.dataKey === "PORTFOLIO" || p2.dataKey === "GLOBAL_PORTFOLIO"
				? 1
				: p2.value - p1.value
		)

		return (
			<div className="custom-tooltip">
				<h5>{`${days[date.getUTCDay()]} ${date.getDate()} ${
					months[date.getMonth()]
				} ${date.getFullYear()}`}</h5>
				{sortedData.map((p, i) => (
					<div key={i} className="tooltip-value">
						<h5 style={{ color: p.color }}>
							{p.dataKey === "GLOBAL_PORTFOLIO"
								? "GLOBAL PORTFOLIO"
								: p.dataKey}
							:
						</h5>{" "}
						<span>{`${
							isPercentage
								? formatNumber(p.value * 100, 2)
								: formatNumber(p.value, 2)
						} ${isPercentage ? " %" : ""}`}</span>
					</div>
				))}
			</div>
		)
	}

	return null
}

export default Chart
