import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import "./../../style/css/CorrelationMatrix.css"
import { formatNumber } from "../utils/Formatter"

const CorrelationMatrix = ({ correlationData }) => {
	if (
		correlationData &&
		!Array.isArray(correlationData) &&
		Object.keys(correlationData).length > 0
	) {
		return (
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Correlation Matrix</TableCell>
							{Object.keys(correlationData)
								.slice(0, 15)
								.map((k) => (
									<TableCell key={k}>
										{k === "GLOBAL_PORTFOLIO"
											? "GLOBAL PORTFOLIO"
											: k.toString().slice(0, 10)}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.keys(correlationData)
							.slice(0, 15)
							.map((row) => (
								<TableRow key={row}>
									<TableCell component="th" scope="row">
										{row === "GLOBAL_PORTFOLIO"
											? "GLOBAL PORTFOLIO"
											: row}
									</TableCell>
									{Object.keys(correlationData).map((column) => (
										<TableCell
											align="center"
											key={column}
											style={{
												backgroundColor: `rgba(255, 255, 255, ${
													1 - correlationData[row][column] * 0.8
												})`,
											}}>
											{formatNumber(correlationData[row][column], 1)}
										</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
	return null
}

export default CorrelationMatrix
