import React, { useContext } from "react"
import AssetTableIcon from "./../../../resources/charting/AssetTableAssetTable.svg"
import DropDownIcon from "./../../../resources/charting/Dropdown_Dropdown.svg"
import PNLIcon from "./../../../resources/charting/PNL_PNL.svg"
import RhoIcon from "./../../../resources/charting/Rho_Rho.svg"
import RiskIcon from "./../../../resources/charting/Risk_Risk.svg"
import VolatilityIcon from "./../../../resources/charting/volatility_Volatility.svg"
import "./../../../style/css/InfoControlBar.css"
import "./../../../style/css/ControlBarDatePicker.css"

import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import TextField from "@mui/material/TextField"
import { getPortfolioGraphData } from "../../utils/ChartDataFetcher"
import { UserSettingsContext } from "../../Context/UserSettingsContext"
import { disableDates } from "../../utils/Formatter"
import StaticDataContext from "../../Context/StaticDataContext"

const PortfolioControlBar = ({
	portfolioId,
	setViewing,
	VIEWING,
	viewing,
	setInterval,
	INTERVAL,
	dateRange,
	setEndDate,
	setStartDate,
	dispatchGraphData,
}) => {
	const { userId } = useContext(UserSettingsContext)
	const { dates } = useContext(StaticDataContext)

	const calculate = () => {
		if (
			!disableDates(dates, dateRange.startDate) &&
			!disableDates(dates, dateRange.endDate) &&
			dateRange.endDate - dateRange.startDate > 0
		) {
			dispatchGraphData(
				getPortfolioGraphData(
					userId,
					dateRange.startDate,
					dateRange.endDate,
					portfolioId
				)
			)
		}
	}

	return (
		<div className="info_bar_controller_container">
			<div className="icon_container">
				<div
					className={
						viewing === VIEWING.RISKTABLE
							? "icon-title-container selected"
							: "icon-title-container"
					}>
					<div
						className="info_controlbar_icon"
						onClick={() => setViewing(VIEWING.RISKTABLE)}>
						<img src={AssetTableIcon} />
					</div>
					{viewing === VIEWING.RISKTABLE && (
						<h4 className="icon_label">{VIEWING.RISKTABLE}</h4>
					)}
				</div>
				<div
					className={
						viewing === VIEWING.ASSETPRICE
							? "icon-title-container selected"
							: "icon-title-container"
					}>
					<div
						className="info_controlbar_icon"
						onClick={() => setViewing(VIEWING.ASSETPRICE)}>
						<img src={RiskIcon} />
					</div>
					{viewing === VIEWING.ASSETPRICE && (
						<h4 className="icon_label">{VIEWING.ASSETPRICE}</h4>
					)}
				</div>
				<div
					className={
						viewing === VIEWING.PERFORMANCE
							? "icon-title-container selected"
							: "icon-title-container"
					}>
					<div
						className="info_controlbar_icon"
						onClick={() => setViewing(VIEWING.PERFORMANCE)}>
						<img src={PNLIcon} />
					</div>
					{viewing === VIEWING.PERFORMANCE && (
						<h4 className="icon_label">{VIEWING.PERFORMANCE}</h4>
					)}
				</div>
				<div
					className={
						viewing === VIEWING.VOLATILITY
							? "icon-title-container selected"
							: "icon-title-container"
					}>
					<div
						className="info_controlbar_icon"
						onClick={() => setViewing(VIEWING.VOLATILITY)}>
						<img src={VolatilityIcon} />
					</div>
					{viewing === VIEWING.VOLATILITY && (
						<h4 className="icon_label">{VIEWING.VOLATILITY}</h4>
					)}
				</div>
				<div
					className={
						viewing === VIEWING.DRAWDOWN
							? "icon-title-container selected"
							: "icon-title-container"
					}>
					<div
						className="info_controlbar_icon"
						onClick={() => setViewing(VIEWING.DRAWDOWN)}>
						<img src={DropDownIcon} />
					</div>
					{viewing === VIEWING.DRAWDOWN && (
						<h4 className="icon_label">{VIEWING.DRAWDOWN}</h4>
					)}
				</div>
				<div
					className={
						viewing === VIEWING.CORRELATION
							? "icon-title-container selected"
							: "icon-title-container"
					}>
					<div
						className="info_controlbar_icon"
						onClick={() => setViewing(VIEWING.CORRELATION)}>
						<img src={RhoIcon} />
					</div>
					{viewing === VIEWING.CORRELATION && (
						<h4 className="icon_label">{VIEWING.CORRELATION}</h4>
					)}
				</div>
			</div>
			<div className="date_range_container">
				<span
					className={
						dateRange.range === INTERVAL.D1 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.D1)}>
					1d
				</span>
				<span
					className={
						dateRange.range === INTERVAL.W1 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.W1)}>
					1w
				</span>
				<span
					className={
						dateRange.range === INTERVAL.M1 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.M1)}>
					1m
				</span>
				<span
					className={
						dateRange.range === INTERVAL.M3 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.M3)}>
					3m
				</span>
				<span
					className={
						dateRange.range === INTERVAL.M6 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.M6)}>
					6m
				</span>
				<span
					className={
						dateRange.range === INTERVAL.YTD
							? "range_selected"
							: undefined
					}
					onClick={() => setInterval(INTERVAL.YTD)}>
					ytd
				</span>
				<span
					className={
						dateRange.range === INTERVAL.Y1 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.Y1)}>
					1y
				</span>
				<span
					className={
						dateRange.range === INTERVAL.Y3 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.Y3)}>
					3y
				</span>
				<span
					className={
						dateRange.range === INTERVAL.Y5 ? "range_selected" : undefined
					}
					onClick={() => setInterval(INTERVAL.Y5)}>
					5y
				</span>
				<span
					className={
						dateRange.range === INTERVAL.Y10
							? "range_selected"
							: undefined
					}
					onClick={() => setInterval(INTERVAL.Y10)}>
					10y
				</span>
				<span
					className={
						dateRange.range === INTERVAL.MAX
							? "range_selected"
							: undefined
					}
					onClick={() => setInterval(INTERVAL.MAX)}>
					max
				</span>
			</div>
			<div className="date-picker-container-control-bar chart">
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						views={["day"]}
						shouldDisableDate={(d) => disableDates(dates, d)}
						value={dateRange.startDate}
						onChange={(e) => setStartDate(e)}
						label="START DATE"
						minDate={new Date(dates[0])}
						maxDate={new Date()}
						inputFormat="dd/MM/yyyy"
						renderInput={(params) => <TextField {...params} />}
					/>
					<DesktopDatePicker
						views={["day"]}
						shouldDisableDate={(d) => disableDates(dates, d)}
						value={dateRange.endDate}
						onChange={(e) => setEndDate(e)}
						label="END DATE"
						minDate={new Date(dates[0])}
						maxDate={new Date()}
						inputFormat="dd/MM/yyyy"
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
			</div>

			<span className="calculate-button" onClick={() => calculate()}>
				calculate
			</span>
		</div>
	)
}

export default PortfolioControlBar
